import {PropsWithTheme, ThemeSchema} from '../types';
import theme from './theme';

const boxShadow = (props: PropsWithTheme): ThemeSchema['boxShadow'] => theme(props).boxShadow;

export const boxShadowNone = (props: PropsWithTheme): string => boxShadow(props).none;
export const boxShadowStandard = (props: PropsWithTheme): string => boxShadow(props).standard;

const borderRadius = (props: PropsWithTheme): ThemeSchema['borderRadius'] => theme(props).borderRadius;

export const borderRadiusNone = (props: PropsWithTheme): string => borderRadius(props).none;
export const borderRadiusSmall = (props: PropsWithTheme): string => borderRadius(props).small;
export const borderRadiusStandard = (props: PropsWithTheme): string => borderRadius(props).standard;
export const borderRadiusFull = (props: PropsWithTheme): string => borderRadius(props).full;

import {PropsWithTheme, ColorsSchema, ColorBrandSchema, ColorShadesSchema, ColorProductSchema, ColorPairSchema} from '../types';
import theme from './theme';

export const colors = (props: PropsWithTheme): ColorsSchema => theme(props).colors;

export const colorAccent = (props: PropsWithTheme): string => colors(props).accent;
export const colorFocus = (props: PropsWithTheme): string => colors(props).focus;

export const colorError = (props: PropsWithTheme): string => colors(props).error;
export const colorSuccess = (props: PropsWithTheme): string => colors(props).success;
export const colorInfo = (props: PropsWithTheme): string => colors(props).info;

const brand = (props: PropsWithTheme): ColorBrandSchema => colors(props).brand;
export const colorBrandBase = (props: PropsWithTheme): string => brand(props).base;
export const colorBrandLight = (props: PropsWithTheme): string => brand(props).light;
export const colorBrandDark = (props: PropsWithTheme): string => brand(props).dark;

const shades = (props: PropsWithTheme): ColorShadesSchema => colors(props).shades;
export const colorBlack = (props: PropsWithTheme): string => shades(props).black;
export const colorDarkest = (props: PropsWithTheme): string => shades(props).darkest;
export const colorDarker = (props: PropsWithTheme): string => shades(props).darker;
export const colorDark = (props: PropsWithTheme): string => shades(props).dark;
export const colorLight = (props: PropsWithTheme): string => shades(props).light;
export const colorLighter = (props: PropsWithTheme): string => shades(props).lighter;
export const colorLightest = (props: PropsWithTheme): string => shades(props).lightest;
export const colorNearWhite = (props: PropsWithTheme): string => shades(props).nearWhite;
export const colorWhite = (props: PropsWithTheme): string => shades(props).white;

const product = (props: PropsWithTheme): ColorProductSchema => colors(props).product;
export const colorHospital = (props: PropsWithTheme): string => product(props).hospital;
export const colorExtras = (props: PropsWithTheme): string => product(props).extras;
export const colorCombined = (props: PropsWithTheme): string => product(props).combined;

const highlight = (props: PropsWithTheme): ColorPairSchema => product(props).highlight;
export const colorHighlightBg = (props: PropsWithTheme): string => highlight(props).backgroundColor;
export const colorHighlightText = (props: PropsWithTheme): string => highlight(props).textColor;

/**
 * Conditional new nib colors
 * - very basic greyscale fallbacks for other brands
 */
export const colorTrueGreen = (props: PropsWithTheme): string => colors(props).trueGreen ?? shades(props).darkest;
export const colorTrueGreen80 = (props: PropsWithTheme): string => colors(props).trueGreen80 ?? shades(props).darker;
export const colorTrueGreen60 = (props: PropsWithTheme): string => colors(props).trueGreen60 ?? shades(props).dark;
export const colorTrueGreen40 = (props: PropsWithTheme): string => colors(props).trueGreen40 ?? shades(props).light;
export const colorTrueGreen20 = (props: PropsWithTheme): string => colors(props).trueGreen20 ?? shades(props).lighter;

export const colorBrightGreen = (props: PropsWithTheme): string => colors(props).brightGreen ?? shades(props).lightest;
export const colorBrightGreen80 = (props: PropsWithTheme): string => colors(props).brightGreen80 ?? shades(props).lightest;
export const colorBrightGreen60 = (props: PropsWithTheme): string => colors(props).brightGreen60 ?? shades(props).lightest;
export const colorBrightGreen40 = (props: PropsWithTheme): string => colors(props).brightGreen40 ?? shades(props).lightest;
export const colorBrightGreen20 = (props: PropsWithTheme): string => colors(props).brightGreen20 ?? shades(props).lightest;

export const colorSunsetPink = (props: PropsWithTheme): string => colors(props).sunsetPink ?? shades(props).lighter;
export const colorSunsetPink80 = (props: PropsWithTheme): string => colors(props).sunsetPink80 ?? shades(props).lighter;
export const colorSunsetPink60 = (props: PropsWithTheme): string => colors(props).sunsetPink60 ?? shades(props).lighter;
export const colorSunsetPink40 = (props: PropsWithTheme): string => colors(props).sunsetPink40 ?? shades(props).lighter;
export const colorSunsetPink20 = (props: PropsWithTheme): string => colors(props).sunsetPink20 ?? shades(props).lighter;

export const colorWarmWhite = (props: PropsWithTheme): string => colors(props).warmWhite ?? shades(props).lightest;
export const colorWarmWhite80 = (props: PropsWithTheme): string => colors(props).warmWhite80 ?? shades(props).lightest;
export const colorWarmWhite60 = (props: PropsWithTheme): string => colors(props).warmWhite60 ?? shades(props).lightest;
export const colorWarmWhite40 = (props: PropsWithTheme): string => colors(props).warmWhite40 ?? shades(props).lightest;
export const colorWarmWhite20 = (props: PropsWithTheme): string => colors(props).warmWhite20 ?? shades(props).lightest;

export const colorSageGreen = (props: PropsWithTheme): string => colors(props).sageGreen ?? shades(props).light;
export const colorSageGreen80 = (props: PropsWithTheme): string => colors(props).sageGreen80 ?? shades(props).light;
export const colorSageGreen60 = (props: PropsWithTheme): string => colors(props).sageGreen60 ?? shades(props).light;
export const colorSageGreen40 = (props: PropsWithTheme): string => colors(props).sageGreen40 ?? shades(props).light;
export const colorSageGreen20 = (props: PropsWithTheme): string => colors(props).sageGreen20 ?? shades(props).light;

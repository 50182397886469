import {css} from 'styled-components';
import {map} from '../../breakpoint';
import {Nullable, ResponsiveOrStaticProp} from './../../utils';
import {colorBorderValues, pickBorderColor} from './colors';
import {DimensionsType} from './dimensions';

export const validBorderStyleValues = ['none', 'hidden', 'dotted', 'dashed', 'solid', 'double', 'groove', 'inset', 'ridge', 'outset'] as const;
export type borderStyleValues = (typeof validBorderStyleValues)[number];
export type BorderStyleType = ResponsiveOrStaticProp<Nullable<borderStyleValues>>;

export const validBorderWidthValues = ['none', 'fine', 'thin', 'medium', 'thick'] as const;
export type borderWidthValues = (typeof validBorderWidthValues)[number];
export type BorderWidthType = ResponsiveOrStaticProp<Nullable<borderWidthValues>>;

export type BorderType = ResponsiveOrStaticProp<string>;

export const border = () => css`
  ${({borderColor}: {borderColor: colorBorderValues}) =>
    map(
      borderColor,
      (color: colorBorderValues) =>
        color &&
        css`
          border-color: ${pickBorderColor(color)};
        `
    )};

  ${(
    {borderWidth}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      borderWidth,
      (val: DimensionsType | BorderWidthType) =>
        val !== undefined &&
        css`
          border-width: ${validBorderWidthValues.includes(val as borderWidthValues) ? pickBorderWidth(val as borderWidthValues) : (val as string)};
        `
    )};

  ${(
    {borderStyle}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      borderStyle,
      (val: borderStyleValues) =>
        val &&
        css`
          border-style: ${val};
        `
    )};
`;

const VALID_TOKEN_BORDER_WIDTH_VALUES = {
  none: `var(--themeDimensionBorderWidthNone)`,
  fine: `var(--themeDimensionBorderWidthFine)`,
  thin: `var(--themeDimensionBorderWidthThin)`,
  medium: `var(--themeDimensionBorderWidthMedium)`,
  thick: `var(--themeDimensionBorderWidthThick)`
};

export const pickBorderWidth = (borderWidth: borderWidthValues) => {
  switch (borderWidth) {
    case 'none':
      return VALID_TOKEN_BORDER_WIDTH_VALUES.none;
    case 'fine':
      return VALID_TOKEN_BORDER_WIDTH_VALUES.fine;
    case 'thin':
      return VALID_TOKEN_BORDER_WIDTH_VALUES.thin;
    case 'medium':
      return VALID_TOKEN_BORDER_WIDTH_VALUES.medium;
    case 'thick':
      return VALID_TOKEN_BORDER_WIDTH_VALUES.thick;
  }
};

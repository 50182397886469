import React from 'react';
import styled, {css} from 'styled-components';
import {p, py, px, pl} from '@nib/layout';
import {copyFontSizeLarge, copyFontFamily, focusOutlineWidth, standardFocusStyleDeclarations} from '@nib-components/theme';
import {IconProps} from '@nib/icons';
import {DEFAULT_STEP_COLOR, SELECTED_STEP_COLOR} from './constant';

const validAligns = ['stacked', 'inline'] as const;
type AlignValues = (typeof validAligns)[number];

const validPositions = ['first', 'middle', 'last'] as const;
type PositionValues = (typeof validPositions)[number];

export interface StepProps {
  title: string;
  icon?: React.FC<IconProps>;
  position: PositionValues;
  hasAccessedPrevious: boolean;
  hasAccessedNext: boolean;
  isDisabled: boolean;
  isCurrent: boolean;
  isAccessed: boolean;
  isComplete: boolean;
  href?: string;
  align: AlignValues;
  width?: string;
  component?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
  onClick?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
  isGated?: boolean;
}

type StepWrapperProps = Pick<StepProps, 'position' | 'hasAccessedPrevious' | 'hasAccessedNext' | 'isCurrent' | 'isDisabled' | 'align' | 'width' | 'isAccessed' | 'isGated'>;

type NodeProps = Pick<StepProps, 'isCurrent' | 'isAccessed' | 'isDisabled' | 'align'>;

type TitleProps = Pick<StepProps, 'isCurrent' | 'isDisabled' | 'align' | 'isGated' | 'isAccessed'>;

type IconWrapperProps = Pick<StepProps, 'isCurrent' | 'align' | 'isDisabled' | 'isGated' | 'isAccessed'>;

export const Title = styled.div<TitleProps>`
  font-family: ${copyFontFamily};
  font-size: ${copyFontSizeLarge};
  line-height: 1.25;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  ${({align}) =>
    css`
      ${align == 'stacked' ? px(3) : py(2)};
    `}

  ${({align}) => css`
    ${align == 'stacked' && 'text-align:start; '}
  `}

  ${({isCurrent, isDisabled, isGated, isAccessed}) =>
    css`
      color: ${!isDisabled && isCurrent ? SELECTED_STEP_COLOR : 'currentColor'};
      font-weight: ${isCurrent && !isDisabled ? 700 : 400};
      ${(isGated || isDisabled) && !isCurrent && !isAccessed && 'opacity:0.3;'}
    `};
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  transition: transform 0.125s ease-out;
  line-height: 0;
  ${({isCurrent, isDisabled, isGated, isAccessed}) => css`
    ${(isGated || isDisabled) && !isCurrent && !isAccessed && 'opacity:0.3;'}
  `}
`;

const getHoverDisabledStepStyles = (align: 'inline' | 'stacked', isDisabled: boolean, isCurrent: boolean, isComplete: boolean, isAccessed: boolean, isGated: boolean) => {
  return css`
    ${isDisabled || (isGated && !isAccessed)
      ? css`
          cursor: not-allowed;
          pointer-events: none;
        `
      : css`
          cursor: pointer;
          &:hover ${Title} {
            color: ${SELECTED_STEP_COLOR};
            font-weight: 700;
          }

          &:hover ${IconWrapper} {
            color: ${SELECTED_STEP_COLOR};
            ${!isCurrent && align == 'inline' && `transform: translateY(-4px)`};
          }
        `}
  `;
};

const BaseStep = ({component: Component = 'a', ...otherProps}) => {
  return <Component {...otherProps}></Component>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StepWrapper = styled(({fill, hasAccessedPrevious, isDisabled, isCurrent, hasAccessedNext, position, align, isAccessed, width, isComplete, isGated, ...otherProps}) => (
  <BaseStep {...otherProps} />
))<StepWrapperProps>`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  color: inherit;
  text-decoration: none;
  background: transparent;
  border: none;
  box-sizing: border-box;
  ${p(4)};

  ${({align, isDisabled, isCurrent, isComplete, isAccessed, isGated}) => {
    return css`
      ${align == 'inline' ? ' flex-direction: column;' : ' flex-direction: row;'};
      ${getHoverDisabledStepStyles(align, isDisabled, isCurrent, isComplete, isAccessed, isGated)}
    `;
  }}

  &:focus {
    ${standardFocusStyleDeclarations};
    outline-offset: -${focusOutlineWidth};
    z-index: 1;
  }

  ${({width, align}) =>
    css`
      ${align == 'stacked' &&
      css`
        width: 100%;
        justify-content: flex-start;
        ${pl(7)};
      `}
      ${align == 'inline' && `width: ${width}`};
    `}
`;

export const Node = styled.div.attrs({'data-mesh-component': 'PROGRESS-STEPPER-NODE'})<NodeProps>`
  height: 16px;
  width: 16px;
  border-radius: 100%;
  position: absolute;
  inset-block-end: -6px;
  background-color: ${DEFAULT_STEP_COLOR};
  z-index: 1;

  ${({align, isCurrent}) =>
    align === 'stacked'
      ? css`
          inset-block-end: 50%;
          transform: translateY(50%);
          inset-inline-start: ${isCurrent ? '12px' : '16px'};
        `
      : css`
          inset-block-end: ${isCurrent ? '-10px' : '-6px'};
        `};

  ${({isAccessed}) =>
    isAccessed &&
    css`
      background-color: ${SELECTED_STEP_COLOR};
    `};

  ${({isCurrent}) =>
    isCurrent &&
    css`
      border: 4px solid ${SELECTED_STEP_COLOR};
      background-color: var(--themeColorBg, white);
    `};
`;

export const Step: React.FC<StepProps> = props => {
  const {title, icon: Icon, position, hasAccessedPrevious, hasAccessedNext, isCurrent, isDisabled, isAccessed, align = 'inline', width, isComplete, href, isGated, ...otherProps} = props;

  return (
    <StepWrapper
      position={position}
      hasAccessedPrevious={hasAccessedPrevious}
      hasAccessedNext={hasAccessedNext}
      isCurrent={isCurrent}
      isDisabled={isDisabled}
      disabled={isDisabled}
      align={align}
      href={isDisabled || (isGated && !isAccessed) ? '' : href}
      tabIndex="0"
      width={width}
      isAccessed={isAccessed}
      isComplete={isComplete}
      isGated={isGated}
      {...otherProps}
    >
      <>
        {Icon && (
          <IconWrapper align={align} isCurrent={isCurrent} isDisabled={isDisabled} isGated={isGated} isAccessed={isAccessed}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore next line */}
            <Icon size="md" fill={!isDisabled && isCurrent ? SELECTED_STEP_COLOR : 'currentColor'} />
          </IconWrapper>
        )}
        <Title isCurrent={isCurrent} isDisabled={isDisabled} align={align} isGated={isGated} isAccessed={isAccessed}>
          {title}
        </Title>
        <Node isCurrent={isCurrent} isAccessed={isAccessed} isDisabled={isDisabled} align={align} />
      </>
    </StepWrapper>
  );
};

Step.displayName = 'Step';

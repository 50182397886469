import React from 'react';
import styled from 'styled-components';
import {nib} from '@nib-components/theme';
import {SizeMap, LogoComponent} from '../../types';

const sizes: SizeMap = {
  small: {
    width: '89.1px',
    height: '19px'
  },
  header: {
    width: '159.44px',
    height: '34px'
  },
  medium: {
    width: '173.51px',
    height: '37px'
  },
  large: {
    width: '229.78px',
    height: '49px'
  }
};

export const GreenPathWhenInverted = styled.path<{invert?: boolean}>`
  fill: ${props => (props.invert ? nib.colors.brand.light : nib.colors.brand.base)};
`;

export const WhitePathWhenInverted = styled.path<{invert?: boolean}>`
  fill: ${props => (props.invert ? nib.colors.shades.white : nib.colors.brand.base)};
`;

const NibThrive: LogoComponent = props => {
  const {invert, size = 'small', ...otherProps} = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" x="0" y="0" width={sizes[size].width} height={sizes[size].height} viewBox="0 0 160 35" aria-label="NIB Thrive" {...otherProps}>
      <GreenPathWhenInverted
        invert={invert}
        d="M20.12 14.156H.232v6.232h9.249l-6.536 6.536 4.408 4.407 6.536-6.535v9.248h6.231V14.156zm-7.74-5.904l-5.039-5.04L2.933 7.62l5.04 5.04h4.407V8.252zm9.237 18.052l5.04 5.04 4.407-4.409-5.04-5.039h-4.407v4.408zm4.408-13.644l5.039-5.04-4.408-4.407-5.04 5.039v4.408h4.409zm.62 7.728h7.12v-6.232h-7.12l-3.11 3.122 3.11 3.11zM20.12 7.62V.5h-6.232v7.12l3.11 3.11 3.122-3.11zm68.737 20.847c.912 0 1.777-.175 2.596-.514a5.9 5.9 0 002.104-1.45 7.026 7.026 0 001.391-2.152 6.825 6.825 0 00.515-2.642 6.88 6.88 0 00-.515-2.619 6.63 6.63 0 00-1.39-2.151 6.562 6.562 0 00-2.106-1.45 6.333 6.333 0 00-2.595-.538c-.947 0-1.836.175-2.666.538a7.15 7.15 0 00-2.175 1.45 6.771 6.771 0 00-1.473 2.151 6.483 6.483 0 00-.538 2.62c0 .946.176 1.823.515 2.641a6.59 6.59 0 001.45 2.152 6.752 6.752 0 002.174 1.45c.819.35 1.73.514 2.713.514zm.585 6.033a14.25 14.25 0 01-4.093-.585c-1.286-.397-2.373-.935-3.262-1.613v1.859h-6.793V.605H82.1v10.43c.877-.69 1.964-1.228 3.262-1.637 1.286-.41 2.654-.608 4.092-.608 1.695 0 3.32.339 4.876 1.005a12.45 12.45 0 014.045 2.748c1.146 1.157 2.058 2.525 2.736 4.115.69 1.579 1.029 3.274 1.029 5.075 0 1.823-.339 3.519-1.029 5.062a13.423 13.423 0 01-2.736 4.045 12.311 12.311 0 01-4.045 2.69c-1.567.643-3.192.97-4.887.97zm-23.7-25.465h6.805v25.126h-6.805V9.035zm0-8.43h6.805v6.267h-6.805V.605zM46.006 11.572c.62-.713 1.555-1.391 2.794-2.034 1.24-.632 2.748-.959 4.502-.959 1.473 0 2.794.246 3.987.737a9.006 9.006 0 013.063 2.057c.854.877 1.497 1.953 1.964 3.204.456 1.263.69 2.642.69 4.139v15.422h-6.77V19.897c0-1.497-.397-2.713-1.204-3.648-.795-.935-1.929-1.391-3.402-1.391-1.66 0-3.005.49-4.01 1.473-1.018.982-1.52 2.35-1.52 4.115v13.715h-6.805V9.035h6.711v2.537z"
      />
      <WhitePathWhenInverted
        invert={invert}
        d="M114.334 34.22h-1.965V22.491h-4.524v-1.859h11.002v1.86h-4.525v11.726h.012zm7.471-13.587v5.472c.596-1.11 1.672-1.859 3.344-1.859 2.092 0 3.472 1.286 3.472 3.555v6.418h-1.906v-5.94c0-1.496-.818-2.326-2.139-2.326-1.497 0-2.771 1.111-2.771 3.122v5.144h-1.906V20.633h1.906zm10.698 3.847v1.59c.467-1.18 1.508-1.73 2.701-1.73.467 0 .947.082 1.403.257l-.316 1.765a3.433 3.433 0 00-1.263-.257c-1.403 0-2.479.936-2.479 2.853v5.261h-1.905V24.48h1.859zm7.506-3.145c0 .736-.561 1.298-1.356 1.298s-1.357-.561-1.357-1.298c0-.76.562-1.345 1.357-1.345.795 0 1.356.585 1.356 1.345zm-2.303 3.145h1.905v9.74h-1.905v-9.74zm12.767 0l-3.975 9.74h-1.684l-3.975-9.74h2.081l2.759 7.202 2.736-7.202h2.058zm1.964 5.448c.234 1.801 1.403 2.713 3.11 2.713 1.287 0 2.21-.561 2.83-1.438l1.064 1.146c-1.006 1.262-2.315 1.976-4.057 1.976-3.028 0-4.852-2.117-4.852-4.934 0-2.795 1.788-5.168 4.875-5.168 2.642 0 4.268 1.707 4.268 4.735v.947h-7.238v.023zm5.332-1.45c0-1.671-.819-2.618-2.444-2.618-1.648 0-2.654.994-2.888 2.619h5.332z"
      />
    </svg>
  );
};

export default NibThrive;

import {GetConfigParams} from './types';

export const getBaseUrl = (config: GetConfigParams, defaultBaseUrl: string): string => (config.withAbsoluteLinks ? (config.absoluteBaseUrl ? config.absoluteBaseUrl : defaultBaseUrl) : '');

export const getLogoUrl = (config: GetConfigParams, baseUrl: string, defaultLogoUrl: string): string => {
  if (config.withAbsoluteLinks) {
    return `${baseUrl}${defaultLogoUrl}`;
  }
  return defaultLogoUrl;
};

import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/edi`;
const FRESH_SANS = 'Fresh Sans';
const ROBOTO = 'ROBOTO';

export const EdiFonts = createGlobalStyle`
  @font-face {
    font-family: ${FRESH_SANS};
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${BRAND_CDN_URL}/FreshSans-Medium_1_350_web.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/FreshSans-Medium_1_350_web.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: ${ROBOTO};
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${BRAND_CDN_URL}/Roboto-Regular.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/Roboto-Regular.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: ${ROBOTO};
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(${BRAND_CDN_URL}/Roboto-Bold.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/Roboto-Bold.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
`;

import {css} from 'styled-components';
import {standardFocusStyleDeclarations, formSelectedToggleColor} from '@nib-components/theme';
import * as config from './variables';

const newStyle = css`
  --rdp-arrow: #aeaeae;
  --rdp-background-color: #fff;
  --rdp-color: #000;
  --rdp-border: 1px solid #aeaeae;
  --rdp-border-radius: 0.3rem;
  --rdp-font-family: ${config.fontFamily};

  /* 40px */
  --rdp-day-size: 2.5rem;
  --rdp-day-selected: ${formSelectedToggleColor};
  --rdp-day-selecting: color(from ${formSelectedToggleColor} srgb r g b / 75%);
  --rdp-day-hover: color(from ${formSelectedToggleColor} srgb r g b / 90%);

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: var(--rdp-arrow);
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: '';
    display: block;
    height: 9px;
    position: absolute;
    inset-block-start: 6px;
    width: 9px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    fill: #f0f0f0;
    color: #f0f0f0;
    stroke: #aeaeae;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
    fill: #fff;
    color: #fff;
    stroke: #aeaeae;
  }

  .react-datepicker {
    font-family: var(--rdp-font-family);
    font-size: 0.8rem;
    background-color: var(--rdp-background-color);
    color: var(--rdp-color);
    display: inline-block;
    position: relative;
    line-height: initial;
  }

  .react-datepicker__time-container {
    float: inline-end;
    border-inline-start: 1px solid #aeaeae;
    width: 85px;
  }

  .react-datepicker--time-only .react-datepicker__time-container {
    border-inline-start: 0;
  }

  .react-datepicker--time-only .react-datepicker__time,
  .react-datepicker--time-only .react-datepicker__time-box {
    border-end-start-radius: 0.3rem;
    border-end-end-radius: 0.3rem;
  }

  .react-datepicker-popper {
    z-index: 1;
    line-height: 0;
  }

  .react-datepicker__header {
    text-align: center;
    line-height: 1.5;
    padding: 8px 0;
    position: relative;
  }

  .react-datepicker__header--time {
    padding-block-end: 8px;
    padding-inline: 5px;
  }

  .react-datepicker__header--time:not(.react-datepicker__header--time--only) {
    border-start-start-radius: 0;
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-start-end-radius: 0.3rem;
  }

  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--scroll,
  .react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 15px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-block-start: 0;
    color: var(--rdp-color);
    font-weight: bold;
    font-size: 0.944rem;
  }

  .react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    inset-block-start: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;

    &:focus {
      ${standardFocusStyleDeclarations};
    }
  }

  .react-datepicker__navigation--previous {
    inset-inline-start: 2px;
  }

  .react-datepicker__navigation--next {
    inset-inline-end: 2px;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    inset-inline-end: 85px;
  }

  .react-datepicker__navigation--years {
    position: relative;
    inset-block-start: 0;
    display: block;
    margin-inline: auto;
  }

  .react-datepicker__navigation--years-previous {
    inset-block-start: 4px;
  }

  .react-datepicker__navigation--years-upcoming {
    inset-block-start: -4px;
  }

  .react-datepicker__navigation:hover *::before {
    border-color: #888;
  }

  .react-datepicker__navigation-icon {
    position: relative;
    inset-block-start: -1px;
    font-size: 20px;
    width: 0;
  }

  .react-datepicker__navigation-icon--next {
    inset-inline-start: -2px;
  }

  .react-datepicker__navigation-icon--next::before {
    transform: rotate(45deg);
    inset-inline-start: -7px;
  }

  .react-datepicker__navigation-icon--previous {
    inset-inline-end: -2px;
  }

  .react-datepicker__navigation-icon--previous::before {
    transform: rotate(225deg);
    inset-inline-end: -7px;
  }

  .react-datepicker__month-container {
    float: inline-start;
  }

  .react-datepicker__year {
    margin: 0.4rem;
    text-align: center;
  }

  .react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-inline-size: 180px;
  }

  .react-datepicker__year .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }

  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
  }

  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }

  .react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: inline-start;
    margin: 5px 0 10px 15px;
    text-align: start;
  }

  .react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block;
  }

  .react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block;
  }

  .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    display: inline-block;
    margin-inline-start: 10px;
  }

  .react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 25px;
  }

  .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    width: auto;
  }

  .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type='time']::-webkit-inner-spin-button,
  .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type='time']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type='time'] {
    appearance: textfield;
  }

  .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
    margin-inline-start: 5px;
    display: inline-block;
  }

  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    inset-inline-end: -87px;
    inset-block-start: 0;
  }

  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
    border-end-end-radius: 0.3rem;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 85px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    border-end-end-radius: 0.3rem;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + 1.7rem / 2);
    overflow-y: scroll;
    padding-inline: 0;
    width: 100%;
    box-sizing: content-box;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: var(--rdp-color);
    font-weight: bold;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #216ba5;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    color: #ccc;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
  }

  .react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }

  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
  }

  .react-datepicker__week-number.react-datepicker__week-number--clickable:not(.react-datepicker__week-number--selected, .react-datepicker__week-number--keyboard-selected):hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
  }

  .react-datepicker__week-number--selected {
    border-radius: 0.3rem;
  }

  .react-datepicker__week-number--keyboard-selected {
    border-radius: 0.3rem;
  }

  .react-datepicker__day-names {
    white-space: nowrap;
    margin-block-end: -8px;
  }

  .react-datepicker__week {
    white-space: nowrap;
  }

  .react-datepicker__day {
    border-radius: 999px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: var(--rdp-day-size);
    line-height: var(--rdp-day-size);
    text-align: center;
    margin: 0;
    margin-block-end: 2px;
  }

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    border-radius: 3rem;
    cursor: pointer;

    &:focus {
      ${standardFocusStyleDeclarations}
      position: relative;
    }
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: #f0f0f0;
    color: #000;
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: bold;
  }

  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted,
  .react-datepicker__quarter-text--highlighted,
  .react-datepicker__year-text--highlighted {
    background-color: #3dcc4a;
    color: #fff;
  }

  .react-datepicker__day--highlighted:hover,
  .react-datepicker__month-text--highlighted:hover,
  .react-datepicker__quarter-text--highlighted:hover,
  .react-datepicker__year-text--highlighted:hover {
    background-color: #32be3f;
  }

  .react-datepicker__day--highlighted-custom-1,
  .react-datepicker__month-text--highlighted-custom-1,
  .react-datepicker__quarter-text--highlighted-custom-1,
  .react-datepicker__year-text--highlighted-custom-1 {
    color: magenta;
  }

  .react-datepicker__day--highlighted-custom-2,
  .react-datepicker__month-text--highlighted-custom-2,
  .react-datepicker__quarter-text--highlighted-custom-2,
  .react-datepicker__year-text--highlighted-custom-2 {
    color: green;
  }

  .react-datepicker__day--holidays,
  .react-datepicker__month-text--holidays,
  .react-datepicker__quarter-text--holidays,
  .react-datepicker__year-text--holidays {
    position: relative;
    background-color: #ff6803;
    color: #fff;
  }

  .react-datepicker__day--holidays .overlay,
  .react-datepicker__month-text--holidays .overlay,
  .react-datepicker__quarter-text--holidays .overlay,
  .react-datepicker__year-text--holidays .overlay {
    position: absolute;
    inset-block-end: 100%;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-in-out;
  }

  .react-datepicker__day--holidays:hover,
  .react-datepicker__month-text--holidays:hover,
  .react-datepicker__quarter-text--holidays:hover,
  .react-datepicker__year-text--holidays:hover {
    background-color: #cf5300;
  }

  .react-datepicker__day--disabled .overlay,
  .react-datepicker__month-text--disabled .overlay,
  .react-datepicker__quarter-text--disabled .overlay,
  .react-datepicker__year-text--disabled .overlay {
    position: absolute;
    inset-block-end: 70%;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-in-out;
  }

  .react-datepicker__day--holidays:hover .overlay,
  .react-datepicker__month-text--holidays:hover .overlay,
  .react-datepicker__quarter-text--holidays:hover .overlay,
  .react-datepicker__year-text--holidays:hover .overlay {
    visibility: visible;
    opacity: 1;
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    border-radius: 0;
  }

  .react-datepicker__day--in-range.react-datepicker__day--range-start:not(.react-datepicker__day--in-selecting-range),
  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
    border-start-start-radius: 999em;
    border-start-end-radius: 0;
    border-end-end-radius: 0;
    border-end-start-radius: 999em;
    background-color: var(--rdp-day-selected);
  }

  .react-datepicker__day--in-range.react-datepicker__day--range-end:not(.react-datepicker__day--in-selecting-range),
  .react-datepicker__day--selecting-range-end,
  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
    border-start-start-radius: 0;
    border-start-end-radius: 999em;
    border-end-end-radius: 999em;
    border-end-start-radius: 0;
  }

  .react-datepicker__day--in-range.react-datepicker__day--range-start.react-datepicker__day--range-end,
  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
    border-radius: 999em;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: var(--rdp-day-selected);
    color: #fff;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: var(--rdp-day-hover);
    color: #fff;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: var(--rdp-day-selecting);
    color: #fff;
  }

  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__month-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__quarter-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__year-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    background-color: var(--rdp-day-selecting);
  }

  .react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__year--selecting-range
    .react-datepicker__day--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__month--selecting-range
    .react-datepicker__month-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__year--selecting-range
    .react-datepicker__month-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__month--selecting-range
    .react-datepicker__quarter-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__year--selecting-range
    .react-datepicker__quarter-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__month--selecting-range
    .react-datepicker__year-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__year--selecting-range
    .react-datepicker__year-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ) {
    background-color: #f0f0f0;
    color: #000;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc;
  }

  .react-datepicker__day--disabled:hover,
  .react-datepicker__month-text--disabled:hover,
  .react-datepicker__quarter-text--disabled:hover,
  .react-datepicker__year-text--disabled:hover {
    background-color: transparent;
  }

  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .react-datepicker__calendar-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    padding: 0.5rem;
    box-sizing: content-box;
  }

  .react-datepicker__year-read-view,
  .react-datepicker__month-read-view,
  .react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
    position: relative;
  }

  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    transform: rotate(135deg);
    inset-inline-end: -16px;
    inset-block-start: 0;
  }

  .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-block-start-color: #b3b3b3;
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    inset-inline-start: 25%;
    inset-block-start: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
  }

  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
  }

  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option,
  .react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-inline: auto;
  }

  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-start-start-radius: 0.3rem;
    border-start-end-radius: 0.3rem;
  }

  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    user-select: none;
    border-end-start-radius: 0.3rem;
    border-end-end-radius: 0.3rem;
  }

  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #ccc;
  }

  .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
  .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
  .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
    border-block-end-color: #b3b3b3;
  }

  .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
  .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
  .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
    border-block-start-color: #b3b3b3;
  }

  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    inset-inline-start: 15px;
  }

  .react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #216ba5;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: '×';
  }

  .react-datepicker__close-icon--disabled {
    cursor: default;
  }

  .react-datepicker__close-icon--disabled::after {
    cursor: default;
    background-color: #ccc;
  }

  .react-datepicker__today-button {
    background: #f0f0f0;
    border-block-start: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: inline-start;
  }

  .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0 / 80%);
    inset-inline-start: 0;
    inset-block-start: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
  }

  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }

  @media (width <= 400px), (height <= 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }

  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
  }

  .react-datepicker__children-container {
    width: 13.8rem;
    margin: 0.4rem;
    padding-inline: 0.2rem;
    height: auto;
  }

  .react-datepicker__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
  }
`;
export default newStyle;

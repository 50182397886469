import React from 'react';
import {SpacingType} from '@nib-components/theme';
import {isValidSpacing, ListPositionType, ResponsiveSpaceProp, ListProps, OrderedListType, UnorderedListType} from './utils';
import styled, {css} from 'styled-components';
import {pl, mt, map} from '@nib/layout';
import Copy from './Copy';

interface OrderedListProps extends ListProps {
  listType?: OrderedListType;
}

interface UnorderedListProps extends Omit<ListProps, 'reversed' | 'start'> {
  listType?: UnorderedListType;
}

export interface ListItemProps {
  children: React.ReactNode;
}

const spacingStyles = (space: ResponsiveSpaceProp) => css`
  ${map(
    space,
    (val: SpacingType) =>
      isValidSpacing(val) &&
      css`
        > li:not(:first-child) {
          ${mt(val)};
        }
      `
  )}
`;

const indentStyles = (indent: ResponsiveSpaceProp) => css`
  ${map(
    indent,
    (val: SpacingType) =>
      isValidSpacing(val) &&
      css`
        ${pl(val)};
      `
  )}
`;

const insetStyles = (inset: ResponsiveSpaceProp, position: ListPositionType | undefined) => css`
  ${map(
    inset,
    (val: SpacingType) =>
      isValidSpacing(val) &&
      css`
        ${position === 'inside'
          ? css`
              > li::before {
                content: '';
                ${pl(val)};
              }
            `
          : css`
              > li {
                ${pl(val)};
              }
            `}
      `
  )}
`;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const BaseListStyles = ({space, inset, listType, position, indent}: any) => css`
  margin: 0;
  padding: 0;
  list-style-type: ${listType};
  list-style-position: ${position};
  ${space && spacingStyles(space)};
  ${inset && insetStyles(inset, position)};
  ${indent && indentStyles(indent)};
`;

const StyledOrderedList = styled.ol<OrderedListProps>`
  ${({space, inset, listType, position, indent}) => css`
    ${BaseListStyles({space, inset, listType, position, indent})};
  `}
`;

const StyledUnorderedList = styled.ul<UnorderedListProps>`
  ${({space, inset, listType, position, indent}) => css`
    ${BaseListStyles({space, inset, listType, position, indent})};
  `}
`;

export const OrderedList: React.FC<OrderedListProps> = props => {
  const {listType = 'decimal', space = 2, indent = {xs: 4, md: 6}, inset = {xs: 4, md: 6}, position = 'outside', start, reversed = false, children, ...otherProps} = props;
  return (
    <StyledOrderedList data-mesh-component="ORDERED-LIST" indent={indent} listType={listType} inset={inset} position={position} space={space} start={start} reversed={reversed} {...otherProps}>
      {children}
    </StyledOrderedList>
  );
};

OrderedList.displayName = 'OrderedList';

export const UnorderedList: React.FC<UnorderedListProps> = props => {
  const {listType = 'disc', space = 2, indent = {xs: 4, md: 6}, inset = {xs: 4, md: 6}, position = 'outside', children, ...otherProps} = props;
  return (
    <StyledUnorderedList data-mesh-component="UNORDERED-LIST" indent={indent} listType={listType} position={position} inset={inset} space={space} {...otherProps}>
      {children}
    </StyledUnorderedList>
  );
};

UnorderedList.displayName = 'UnorderedList';

export const ListItem = styled(Copy).attrs({
  component: 'li'
})<HTMLLIElement>``;

ListItem.displayName = 'ListItem';

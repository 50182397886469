'use client';

import React, {useContext, useEffect, useId, useState} from 'react';
import {createPortal} from 'react-dom';
import styled, {ThemeContext, css, keyframes} from 'styled-components';
import FocusTrap from 'focus-trap-react';
import cssEscape from 'css.escape';
import Overlay from '@nib-components/overlay';
import Heading, {componentValues, sizeValues} from '@nib-components/heading';
import {copyFontFamily, copyColor, colorDarkest, colorWhite, boxShadowStandard, standardFocusStyleDeclarations, BreakpointType, ModeContext, getActiveMode} from '@nib-components/theme';
import {CloseSystemIcon} from '@nib/icons';
import {Columns, Column, Container, breakpoint, p, px, pt, py, pr, pb, m, mb, mt} from '@nib/layout';

type Nullable<T> = T | null;
type ResponsiveProp<T> = Partial<Record<BreakpointType, T>>;
type ResponsiveOrStaticProp<T> = T | ResponsiveProp<T>;

export interface BaseModalProps {
  title?: React.ReactNode;
  id?: string;
  visible?: boolean;
  closeOnEsc?: boolean;
  closeOnClick?: boolean;
  onClose?: () => void;
  variation?: string;
  collapseTitle?: boolean;
  titleComponent?: componentValues;
  titleSize?: ResponsiveOrStaticProp<Nullable<sizeValues>>;
  children?: React.ReactNode;
  [key: string]: unknown;
}

export interface ContentModalProps extends BaseModalProps {
  variation: 'content';
  stickyHeader?: boolean;
}

export type ModalProps = BaseModalProps | ContentModalProps;

export interface ModalWrapperProps {
  variation?: string;
  [key: string]: unknown;
}

export interface ModalHeaderProps {
  id: string;
  variation?: string;
  stickyHeader?: boolean;
  collapseTitle?: boolean;
  [key: string]: unknown;
}

const ModalOverlay = styled(Overlay)<{variation?: ModalProps['variation']}>`
  /* non-IE styling */
  @supports (display: flex) {
    display: flex;
    justify-content: center;
  }

  cursor: pointer;
  z-index: 999;

  ${({variation}) =>
    variation === 'dialog' &&
    css`
      ${breakpoint('md')`
        ${py(7)};
      `};
    `};
`;

const slideUp = keyframes`
  from {
    transform: translateY(10%);
  }

  to {
    transform: translateY(0);
  }
`;

export const ModalWrapper = styled.div<ModalWrapperProps>`
  font-family: ${copyFontFamily || 'sans-serif'};
  box-sizing: border-box;
  cursor: default;
  width: 100%;
  margin: auto;
  margin-bottom: 0;
  box-shadow: ${boxShadowStandard || '0 0 5px rgba(0, 0, 0, 0.2)'};
  animation: 0.2s cubic-bezier(0, 0, 0, 1) ${slideUp};
  ${({variation}) =>
    variation === 'content'
      ? css`
          max-width: 1200px;
          ${breakpoint('md')`
            ${m(6)};
            overflow: auto;
          `};
        `
      : css`
          ${breakpoint('md')`
            max-width: 620px;
            margin-top: 0;
            margin-bottom: auto;
          `};
        `};
  color: var(--themeColorFg, ${copyColor});
  background-color: var(--themeColorBgSurfaceProminent, ${colorWhite});
`;

export const ModalHeader = styled.header<ModalHeaderProps>`
  outline: none;

  ${({stickyHeader}) =>
    stickyHeader
      ? css`
          @supports (position: sticky) {
            position: sticky;
            background-color: var(--themeColorBgSurfaceProminent, ${colorWhite});
            inset-block-start: 0;
            z-index: 999;
            box-shadow: ${boxShadowStandard};
          }

          ${(
            {collapseTitle}: any // eslint-disable-line   @typescript-eslint/no-explicit-any
          ) =>
            collapseTitle
              ? css`
                  ${px({xs: 4, sm: 6})};
                  ${py(2)};

                  ${breakpoint('md')`
                    ${py(4)};
                  `};
                `
              : css`
                  ${px({xs: 4, sm: 6})};
                  ${py(4)};
                `};
        `
      : css`
          ${(
            {collapseTitle}: any // eslint-disable-line   @typescript-eslint/no-explicit-any
          ) =>
            collapseTitle
              ? css`
                  ${p({xs: 4, sm: 6})};
                  ${pb(2)};
                `
              : css`
                  ${p({xs: 4, sm: 6})};
                `};
        `};
`;

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
export const ModalTitleAbove = styled(({collapseTitle, ...rest}) => <Heading {...rest} />)`
  ${pr(2)};

  ${({collapseTitle}) =>
    collapseTitle &&
    css`
      display: none;

      ${breakpoint('md')`
        display: block;
      `};
    `};
`;

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
export const ModalTitleInside = styled(({stickyHeader, ...rest}) => <Heading {...rest} />)`
  ${mb(6)};

  ${breakpoint('md')`
    ${mb(0)};
    display: none;
  `};

  ${({stickyHeader}) =>
    stickyHeader &&
    css`
      ${mt(6)};
    `};
`;

export const ModalSection = styled.section<{
  collapseTitle?: boolean;
  stickyHeader?: boolean | unknown;
}>`
  ${p({xs: 4, sm: 6})};

  ${({collapseTitle}) =>
    collapseTitle &&
    css`
      ${pt(0)};
    `};

  ${({stickyHeader}) =>
    !stickyHeader &&
    css`
      ${pt(0)};
    `};

  ${breakpoint('md')`
    ${({stickyHeader}: {stickyHeader?: boolean}) =>
      !stickyHeader &&
      css`
        ${pt(0)};
      `};
  `};
`;

export const ModalClose = styled.button<ModalProps>`
  appearance: none;
  background: none;
  border: none;
  ${p(1)};
  cursor: pointer;
  color: var(--themeColorFg, ${colorDarkest});

  &:focus {
    ${standardFocusStyleDeclarations};
  }
`;

const Modal: React.FC<ModalProps> = props => {
  const {title, visible = false, variation = 'dialog', onClose, children, collapseTitle = true, stickyHeader = false, titleComponent, titleSize = 3, id, ...otherProps} = props;

  const generatedId = useId();
  const modalId = id || `modal-${generatedId}`;

  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    // useEffect only runs in the browser
    setIsBrowser(true);
  }, []);

  /*
    Since the Modal is rendered in a portal, it does not render inside the ModeProvider and therefore has no access to tokens.

    Therefore we need to add a nested ModeProvider, but also use ModeContext to grab the current value of mode.

    Using a <ModeProvider> here would result in a duplicate set of tokens being added via a GlobalStyle. 
    Instead, we use a <div> and manually set the `data-mode` attribute so that the scoped tokens apply.

    Since `mode` can be an object, which a regular div cannot parse, we use the utility function to grab the correct mode for the current brand as a string.
  */
  const mode = useContext(ModeContext);
  const theme = useContext(ThemeContext);
  const currentlyActiveMode = mode && getActiveMode(theme.id, mode);

  return isBrowser ? (
    <>
      {createPortal(
        <div data-mode={currentlyActiveMode}>
          <ModalOverlay visible={visible} onClose={onClose} variation={variation} {...otherProps} data-mesh-component="MODAL">
            <ModalWrapper variation={variation} role="dialog" aria-modal="true" aria-label={title} aria-describedby={`${modalId}-body`}>
              {/*
                FocusTrap handles both:
                - trapping focus to within the open modal, and
                - returning focus to the element that triggered the Modal opening, after closing
              */}
              <FocusTrap
                focusTrapOptions={{
                  clickOutsideDeactivates: true,
                  initialFocus: `#${cssEscape(modalId)}`
                }}
              >
                <div>
                  <ModalHeader tabIndex="-1" id={modalId} variation={variation} stickyHeader={variation === 'content' && stickyHeader} collapseTitle={collapseTitle}>
                    <Columns>
                      <Column>
                        <ModalTitleAbove component={titleComponent} size={titleSize} collapseTitle={collapseTitle}>
                          {title}
                        </ModalTitleAbove>
                      </Column>
                      <Column width="content" style={{fontSize: 0}}>
                        <ModalClose onClick={onClose} aria-label="Close this dialog window">
                          <CloseSystemIcon />
                        </ModalClose>
                      </Column>
                    </Columns>
                  </ModalHeader>

                  <ModalSection collapseTitle={collapseTitle} stickyHeader={variation === 'content' && stickyHeader}>
                    {collapseTitle && (
                      <ModalTitleInside size={titleSize} component={titleComponent} stickyHeader={variation === 'content' && stickyHeader}>
                        {title}
                      </ModalTitleInside>
                    )}
                    <div id={`${modalId}-body`}>{variation === 'content' ? <Container>{children}</Container> : <>{children}</>}</div>
                  </ModalSection>
                </div>
              </FocusTrap>
            </ModalWrapper>
          </ModalOverlay>
        </div>,
        document.body
      )}
    </>
  ) : null;
};

Modal.displayName = 'Modal';

export default Modal;

import arhi from './arhi';
import shareholders from './shareholders';
import nz from './nz';
import foundation from './foundation';
import thrive from './thrive';
import providers from './providers';
import international from './international';
import internationalWorkers from './international-workers';
import internationalStudents from './international-students';
import gu from './gu';
export default {
  arhi,
  foundation,
  gu,
  international,
  internationalStudents,
  internationalWorkers,
  nz,
  shareholders,
  thrive,
  providers
};

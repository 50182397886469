export const validSizeValues = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;
export type SizesType = (typeof validSizeValues)[number];

export default {
  xs: 16,
  sm: 24,
  md: 32,
  lg: 48,
  xl: 64,
  xxl: 128
};

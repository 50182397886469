import {getBaseUrl, getLogoUrl} from './utils';
import {HeaderConfigType, GetConfigParams} from './types';

const LOGO_URL = '/';
const ABSOLUTE_BASEURL = 'https://www.guhealth.com.au';

const getGuConfig = (config: GetConfigParams): HeaderConfigType => {
  const baseUrl = getBaseUrl(config, ABSOLUTE_BASEURL);
  const logoUrl = getLogoUrl(config, baseUrl, LOGO_URL);
  return {
    id: 'header-gu',
    ribbon: [
      {
        id: 'ga-secnav-gu-health-insurance',
        title: 'About GU Health',
        url: `${baseUrl}/about-us`
      },
      {
        id: 'ga-secnav-gu-how-to-claim',
        title: 'How to claim',
        url: `${baseUrl}/how-to-claim`
      },
      {
        id: 'ga-nav-gu-health-contact-us',
        title: 'Contact Us',
        url: `${baseUrl}/contact-us`
      }
    ],
    logoUrl: logoUrl,

    callToAction: {
      id: 'id',
      title: 'Member login',
      url: `https://members.guhealth.com.au/Member/Account/Login`
    },
    navLinks: [
      {
        id: 'ga-nav-my-membersip',
        title: 'My membership',
        children: [
          {
            id: 'ga-nav-gu-health-info-gu-health-members',
            title: 'Information for GU Health members',
            url: `${baseUrl}/information-for-gu-health-members`
          },
          {
            id: 'ga-nav-gu-health-covid-for-members',
            title: 'COVID-19 for members',
            url: `${baseUrl}/covid-19-for-members`
          },
          {
            id: 'ga-nav-gu-health-factsheets-and-forms',
            title: 'Fact sheets and forms',
            url: `${baseUrl}/factsheets-and-forms`
          },
          {
            id: 'ga-nav-gu-health-managing-your-familys-cover',
            title: `Managing your family's cover`,
            url: `${baseUrl}/managing-your-familys-cover`
          },
          {
            id: 'ga-nav-gu-health-rewards',
            title: `GU Health Rewards`,
            url: `${baseUrl}/rewards`
          },
          {
            id: 'ga-nav-gu-health-information-for-overseas-visitors',
            title: 'Information for Overseas Visitors',
            url: `${baseUrl}/information-for-overseas-visitors`
          },
          {
            id: 'ga-nav-gu-health-private-health-insurance-explained',
            title: 'Private Health Insurance Explained',
            url: `${baseUrl}/private-health-insurance-explained`
          },
          {
            id: 'ga-nav-gu-health-phi-reforms',
            title: 'Private Health Insurance Reforms',
            url: `${baseUrl}/phi-reforms`
          }
        ]
      },
      {
        id: 'ga-nav-gu-health-member-tools',
        title: 'Member tools',
        children: [
          {
            id: 'ga-nav-gu-health-find-a-provider',
            title: 'Find a provider',
            url: `${baseUrl}/find-a-provider/`
          },
          {
            id: 'ga-nav-gu-health-mobile-apps',
            title: 'GU Health App',
            url: `${baseUrl}/mobile-apps`
          },
          {
            id: 'ga-nav-gu-health-digital-card',
            title: 'GU Health Digital Card',
            url: `${baseUrl}/mobile-apps/digital-card/`
          },
          {
            id: 'ga-nav-gu-health-your-hospital-journey',
            title: 'Going to Hospital',
            url: `${baseUrl}/your-hospital-journey`
          }
        ]
      },
      {
        id: 'ga-nav-gu-health-understanding-your-cover',
        title: 'Understanding your cover',
        children: [
          {
            id: 'ga-nav-gu-health-understanding-medicare',
            title: 'Understanding Medicare',
            url: `${baseUrl}/understanding-medicare`
          },
          {
            id: 'ga-nav-gu-health-tax-time',
            title: 'Tax time',
            url: `${baseUrl}/tax-time`
          },
          {
            id: 'ga-nav-gu-health-what-to-do-in-an-emergency',
            title: 'What to do in an emergency',
            url: `${baseUrl}/what-to-do-in-an-emergency`
          },
          {
            id: 'ga-nav-gu-health-hospital-bills-and-claiming',
            title: 'Hospital bills and claiming',
            url: `${baseUrl}/hospital-bills-and-claiming`
          },
          {
            id: 'ga-nav-gu-health-medical-gap-network',
            title: 'Medical Gap Network',
            url: `${baseUrl}/medical-gap-network`
          },
          {
            id: 'ga-nav-gu-health-first-choice-dental',
            title: 'First Choice Dental',
            url: `${baseUrl}/dental-extras-cover`
          },
          {
            id: 'ga-nav-gu-health-first-choice-optical',
            title: 'First Choice Optical',
            url: `${baseUrl}/gu-first-choice-optical`
          },
          {
            id: 'ga-nav-gu-health-first-choice-physio',
            title: 'First Choice Physio',
            url: `${baseUrl}/gu-first-choice-physio`
          },
          {
            id: 'ga-nav-gu-health-health-care-home',
            title: 'Health Care @ Home',
            url: `${baseUrl}/gu-health-care-at-home`
          }
        ]
      },
      {
        id: 'ga-nav-gu-health-for-employers',
        title: 'For employers',
        children: [
          {
            id: 'ga-nav-gu-health-partner-with-gu-health ',
            title: 'Partner with GU Health',
            url: `${baseUrl}/partner-with-gu-health`
          },
          {
            id: 'ga-nav-gu-health-corporate-wellness',
            title: 'Corporate wellness',
            url: `${baseUrl}/corporate-wellness`
          },
          {
            id: 'ga-nav-gu-health-covid-19-corporate-update',
            title: 'COVID-19 for employers',
            url: `${baseUrl}/covid-19-corporate-update`
          },
          {
            id: 'ga-nav-gu-health-hr-admin-login',
            title: 'HR Administrator login',
            url: `https://members.guhealth.com.au/Hr/Account/HrLogin`
          }
        ]
      },

      {
        id: 'ga-nav-gu-health-hub',
        title: 'Health Hub',
        url: `${baseUrl}/health-hub`
      }
    ],
    smallHeaderConfig: {
      id: 'small-header-gu-health',
      logoUrl: logoUrl,
      navLinks: []
    },
    hideQuoteButton: true
  };
};

export default getGuConfig;

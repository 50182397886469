import {css} from 'styled-components';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp, Nullable} from './../../utils';

const validPositionValues = ['static', 'relative', 'absolute', 'fixed', 'sticky'] as const;
export type positionValues = (typeof validPositionValues)[number];
export type ElementPositionType = ResponsiveOrStaticProp<string>;

export type PositionType = ResponsiveOrStaticProp<Nullable<positionValues>>;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const position = () => css`
  ${({position}: {position: PositionType}) =>
    map(
      position,
      (val: positionValues) =>
        val !== undefined &&
        css`
          position: ${val};
        `
    )};

  ${(
    {top}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      top,
      (val: string) =>
        val !== undefined &&
        css`
          inset-block-start: ${val};
        `
    )};

  ${(
    {right}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      right,
      (val: string) =>
        val !== undefined &&
        css`
          inset-inline-end: ${val};
        `
    )};

  ${(
    {bottom}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      bottom,
      (val: string) =>
        val !== undefined &&
        css`
          inset-block-end: ${val};
        `
    )};

  ${(
    {left}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      left,
      (val: string) =>
        val !== undefined &&
        css`
          inset-inline-start: ${val};
        `
    )};

  ${(
    {inset}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    map(
      inset,
      (val: string) =>
        val !== undefined &&
        css`
          inset: ${val};
        `
    )};
`;

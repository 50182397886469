import React, {Children} from 'react';

import flattenChildren from 'react-keyed-flatten-children';
import styled, {css} from 'styled-components';
import {breakpoint, map} from '../breakpoint';
import {m, mt, ml, p} from '../spacing';
import {isList, isValidSpacing, getSpacingValue, mapFlexAlignment, ResponsiveSpaceProp, FlexAlignType, FlexVerticalAlignType} from '../utils';
import {BreakpointType, SpacingType} from '@nib-components/theme';

export interface InlineProps {
  space?: ResponsiveSpaceProp;
  collapseBelow?: BreakpointType;
  align?: FlexAlignType;
  verticalAlign?: FlexVerticalAlignType;
  spaceVertically?: boolean;
  as?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
  nowrap?: boolean;
  children: React.ReactNode;
  [key: string]: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InlineWrapper = styled(({as, space, collapseBelow, align, verticalAlign, spaceVertically, nowrap, ...rest}) => <div {...rest} />).attrs({
  'data-mesh-component': 'INLINE'
})`
  /* If stack is set to be a ul or ol we need to reset the default styling */
  ${({as}) =>
    as &&
    isList(as) &&
    css`
      ${m(0)};
      ${p(0)};
      list-style-type: none;
    `}

  display: flex;

  ${({nowrap}) => (nowrap ? 'flex-wrap: nowrap;' : 'flex-wrap: wrap;')}

  ${({align}) =>
    align &&
    css`
      justify-content: ${mapFlexAlignment(align)};
    `}

  ${({verticalAlign}) =>
    verticalAlign &&
    css`
      align-items: ${mapFlexAlignment(verticalAlign)};
    `}

  ${({collapseBelow}) =>
    collapseBelow
      ? css`
          /* When we are below the collapseBelow breakpoint: */
          ${breakpoint('xs', collapseBelow)`

        > * {
          width: 100%;

          &:not(:first-child) {
          ${({space, spaceVertically}: {space: ResponsiveSpaceProp; spaceVertically: boolean}) =>
            spaceVertically &&
            map(
              space,
              (val: SpacingType) =>
                isValidSpacing(val) &&
                css`
                  ${mt(val)};
                `
            )};
          }
        }
      `};
          /* When we are above the collapseBelow breakpoint: */
          ${breakpoint(collapseBelow)`
        /* Apply negative margin based on space prop */
        ${({space}: {space: ResponsiveSpaceProp}) =>
          map(
            space,
            (val: SpacingType) =>
              isValidSpacing(val) &&
              css`
                ${({spaceVertically}: {spaceVertically: boolean}) =>
                  spaceVertically &&
                  css`
                    margin-block-start: calc(0px - ${getSpacingValue(val)});
                  `}
                margin-inline-start: calc(0px - ${getSpacingValue(val)});
              `
          )};

        // Apply margin based on space prop to children
        > * {
          ${({space}: {space: ResponsiveSpaceProp}) =>
            map(
              space,
              (val: SpacingType) =>
                isValidSpacing(val) &&
                css`
                  ${({spaceVertically}: {spaceVertically: boolean}) =>
                    spaceVertically &&
                    css`
                      ${mt(val)};
                    `}
                  ${ml(val)};
                `
            )};
        }
      `};
        `
      : css`
          /* Apply negative margin based on space prop */
          ${({space}: {space: ResponsiveSpaceProp}) =>
            map(
              space,
              (val: SpacingType) =>
                isValidSpacing(val) &&
                css`
                  ${({spaceVertically}: {spaceVertically: boolean}) =>
                    spaceVertically &&
                    css`
                      margin-block-start: calc(0px - ${getSpacingValue(val)});
                    `}
                  margin-inline-start: calc(0px - ${getSpacingValue(val)});
                `
            )};

          /* Apply margin based on space prop to children */

          > * {
            ${({space}: {space: ResponsiveSpaceProp}) =>
              map(
                space,
                (val: SpacingType) =>
                  isValidSpacing(val) &&
                  css`
                    ${({spaceVertically}: {spaceVertically: boolean}) =>
                      spaceVertically &&
                      css`
                        ${mt(val)};
                      `}
                    ${ml(val)};
                  `
              )};
          }
        `}
`;

export const Inline: React.FC<InlineProps> = props => {
  const {children, space = 0, align = 'left', verticalAlign = 'top', spaceVertically = true, ...otherProps} = props;
  const ChildComponent = isList(props.as) ? 'li' : 'div';
  return (
    <InlineWrapper space={space} align={align} verticalAlign={verticalAlign} spaceVertically={spaceVertically} {...otherProps}>
      {Children.map(flattenChildren(children), child => {
        if (React.isValidElement(child)) {
          return <ChildComponent>{child}</ChildComponent>;
        }
        return null;
      })}
    </InlineWrapper>
  );
};

Inline.displayName = 'Inline';

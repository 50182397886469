import {FullStoryLevel} from './FullStoryMask';

export default {
  className: '',
  disabled: false,
  error: '',
  help: '',
  id: '',
  name: '',
  valid: false,
  validated: false,
  isEmptyAndOptional: false,
  isCompact: false,
  fullstory: 'mask' as FullStoryLevel
};

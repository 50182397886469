import React from 'react';

import BaseIcon from '../../utils/BaseIcon';

const HealthManagementGraphicIcon = props => {
  const {size = 48, ...otherProps} = props;
  const iconFill = 'currentColor';

  return (
    <BaseIcon size={size} {...otherProps} data-mesh-component= 'HEALTH-MANAGEMENT-GRAPHIC-ICON'>
      <g fill={iconFill} fillRule="nonzero"><path d="M17.366.001a.5.5 0 01.492.41l.008.09v2.566h4.133a2 2 0 011.994 1.851l.006.15v16.931a2 2 0 01-2 2H2.001a2 2 0 01-2-2V5.067a2 2 0 012-2h4.132V.501a.5.5 0 01.993-.09l.008.09-.001 2.566h9.733V.501a.5.5 0 01.5-.5zM22.998 10.2H1.001v11.799a1 1 0 00.883.993l.117.007h19.998a1 1 0 001-1L22.998 10.2zM6.133 4.067H2.001a1 1 0 00-1 1V9.2h21.997l.001-4.133a1 1 0 00-.883-.993l-.117-.007h-4.133v1.8a.5.5 0 01-.992.09l-.008-.09v-1.8H7.133v1.8a.5.5 0 01-.991.09l-.008-.09-.001-1.8z"/><path d="M11.084 12.037l-.111.006a.954.954 0 00-.843.948l-.001 1.771h-1.77a.954.954 0 00-.955.955v1.817l.007.111a.954.954 0 00.947.843l1.771-.001v1.772c0 .527.428.955.955.955h1.817l.112-.007a.954.954 0 00.843-.948l-.001-1.772h1.772a.954.954 0 00.954-.953v-1.817l-.006-.112a.954.954 0 00-.948-.843h-1.772v-1.771a.954.954 0 00-.954-.954h-1.817zm1.771.999v2.226l.009.09a.5.5 0 00.492.41h2.225v1.725h-2.225l-.09.009a.5.5 0 00-.41.492l-.001 2.225h-1.726v-2.225l-.007-.09a.5.5 0 00-.492-.41l-2.226-.001v-1.725h2.226l.09-.008a.5.5 0 00.41-.492l-.001-2.226h1.726z"/></g>
    </BaseIcon>
  );
};

HealthManagementGraphicIcon.displayName = 'HealthManagementGraphicIcon';

HealthManagementGraphicIcon.displayName = 'HealthManagementGraphicIcon';
HealthManagementGraphicIcon.iconName = 'HealthManagement';

export default HealthManagementGraphicIcon;

import {DefaultTheme, GlobalStyleComponent} from 'styled-components';
import {
  AamiFonts,
  ApiaFonts,
  FoundationFonts,
  GuFonts,
  ImanFonts,
  IngFonts,
  NibFonts,
  NsiFonts,
  PricelineFonts,
  QantasFonts,
  SuncorpFonts,
  WorldNomadsFonts,
  CotaFonts,
  AustralianSeniorsFonts,
  RealFonts,
  AanzFonts,
  EdiFonts,
  TidFonts
} from '@nib/fonts';
import {ThemeVariants} from '../types';

export const brandFonts: Record<ThemeVariants, GlobalStyleComponent<object, DefaultTheme> | null> = {
  nib: NibFonts,
  aami: AamiFonts,
  apia: ApiaFonts,
  ing: IngFonts,
  suncorp: SuncorpFonts,
  foundation: FoundationFonts,
  qantas: QantasFonts,
  gu: GuFonts,
  iman: ImanFonts,
  priceline: PricelineFonts,
  worldNomads: WorldNomadsFonts,
  nsi: NsiFonts,
  cota: CotaFonts,
  australianSeniors: AustralianSeniorsFonts,
  real: RealFonts,
  aanz: AanzFonts,
  edi: EdiFonts,
  tid: TidFonts
};

import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/qantas`;
const FONT_FAMILY = 'Ciutadella';

export const QantasFonts = createGlobalStyle`
  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/Ciutadella-Regular.woff2) format('woff2'), 
        url(${BRAND_CDN_URL}/Ciutadella-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/Ciutadella-Medium.woff2) format('woff2'), 
        url(${BRAND_CDN_URL}/Ciutadella-Medium.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
`;

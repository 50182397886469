import {css} from 'styled-components';
import Colr from 'colr';
import common, {spacing} from '../../common';
import {ThemeSchema} from '../../types';

export const hoverColor = (color: string): string => Colr.fromHex(color).darken(10).toHex();

export const activeColor = (color: string): string => Colr.fromHex(color).darken(20).toHex();

export const disabledColor = (color: string, percent?: number): string => {
  const opacity = percent || 0.3;
  const rgb = Colr.fromHex(color).toRgbArray().toString();
  return `rgba(${rgb}, ${opacity})`;
};

const typeScale = {
  1: '3.375rem',
  2: '2.25rem',
  3: '1.5rem',
  4: '1.125rem',
  5: '1rem',
  6: '.875rem'
};

const lineHeight = {
  tighter: 1,
  tight: 1.2,
  normal: 1.5
};

const tracking = {
  normal: 'normal',
  brand: '-0.02em'
};

const fonts = {
  serif: '"Grenette Pro", "Georgia", serif',
  sansSerif: '"Buenos Aires", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif'
};

const rebrandColors = {
  trueGreen: {
    100: '#144a38',
    80: '#436e60',
    60: '#729288',
    40: '#a1b7af',
    20: '#d0dbd7'
  },
  brightGreen: {
    100: '#82e578',
    80: '#9bea93',
    60: '#b4efae',
    40: '#cdf5c9',
    20: '#e6fae4'
  },
  sageGreen: {
    100: '#c2d6b5',
    80: '#cedec4',
    60: '#dae6d3',
    40: '#e7efe1',
    20: '#f3f7f0'
  },
  sunsetPink: {
    100: '#fdd6bc',
    80: '#fddec9',
    60: '#fee6d7',
    40: '#feefe4',
    20: '#fff7f2'
  },
  warmWhite: {
    100: '#f5f0d6',
    80: '#f7f3de',
    60: '#f9f6e6',
    40: '#fbf9ef',
    20: '#fdfcf7'
  },
  errorRed: '#c81414',
  successGreen: '#007800',
  infoBlue: '#005bb9'
};

const colors = {
  brand: {
    base: '#144a38',
    light: '#82e578',
    dark: '#144a38'
  },
  accent: '#525199',
  shades: {
    black: '#141414',
    darkest: '#434343',
    darker: '#636363',
    dark: '#8a8a8a',
    light: '#c7c7c7',
    lighter: '#e4e4e4',
    lightest: '#f1f1f1',
    nearWhite: '#fafafa',
    white: `#ffffff`
  },
  focus: '#525199',
  error: rebrandColors.errorRed,
  success: rebrandColors.successGreen,
  info: rebrandColors.infoBlue,
  product: {
    hospital: '#18659f',
    extras: '#7e3e97',
    combined: '#144a38',
    highlight: {
      backgroundColor: '#144a38',
      textColor: '#82e578'
    }
  },
  //NIB-SPECIFIC COLOURS
  trueGreen: rebrandColors.trueGreen[100],
  trueGreen80: rebrandColors.trueGreen[80],
  trueGreen60: rebrandColors.trueGreen[60],
  trueGreen40: rebrandColors.trueGreen[40],
  trueGreen20: rebrandColors.trueGreen[20],

  brightGreen: rebrandColors.brightGreen[100],
  brightGreen80: rebrandColors.brightGreen[80],
  brightGreen60: rebrandColors.brightGreen[60],
  brightGreen40: rebrandColors.brightGreen[40],
  brightGreen20: rebrandColors.brightGreen[20],

  sunsetPink: rebrandColors.sunsetPink[100],
  sunsetPink80: rebrandColors.sunsetPink[80],
  sunsetPink60: rebrandColors.sunsetPink[60],
  sunsetPink40: rebrandColors.sunsetPink[40],
  sunsetPink20: rebrandColors.sunsetPink[20],

  sageGreen: rebrandColors.sageGreen[100],
  sageGreen80: rebrandColors.sageGreen[80],
  sageGreen60: rebrandColors.sageGreen[60],
  sageGreen40: rebrandColors.sageGreen[40],
  sageGreen20: rebrandColors.sageGreen[20],

  warmWhite: rebrandColors.warmWhite[100],
  warmWhite80: rebrandColors.warmWhite[80],
  warmWhite60: rebrandColors.warmWhite[60],
  warmWhite40: rebrandColors.warmWhite[40],
  warmWhite20: rebrandColors.warmWhite[20]
};

const focus = {
  outlineColor: colors.focus,
  outlineStyle: 'solid',
  outlineWidth: '3px',
  outlineOffset: '0.125em'
};

const baseButtonStyles = css`
  box-sizing: border-box;
  cursor: pointer;
  font-family: ${fonts.sansSerif};
  font-weight: 700;
  letter-spacing: ${tracking.brand};
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  transition-property: background;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  border: none;
  display: inline-block;
  text-align: center;
  text-wrap: balance;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 100px;

  &:disabled {
    cursor: default;
    pointer-events: auto;
  }

  &:focus {
    outline: ${focus.outlineColor} ${focus.outlineStyle} ${focus.outlineWidth};
    outline-offset: ${focus.outlineOffset};
  }
`;

const buttonStyles = {
  primary: {
    normal: css`
      ${baseButtonStyles}
      background-color: var(--buttonColorBgPrimary, ${colors.brand.light});
      color: var(--buttonColorFgPrimary, ${colors.brand.base});

      &:hover:not([disabled]) {
        background-color: var(--buttonColorBgPrimaryHover, ${hoverColor(colors.brand.light)});
      }

      &:active:not([disabled]) {
        background-color: var(--buttonColorBgPrimaryActive, ${activeColor(colors.brand.light)});
      }
    `,
    selected: css`
      background-color: var(--buttonColorBgPrimaryActive, ${activeColor(colors.brand.light)});
    `,
    disabled: css`
      color: var(--buttonColorFgPrimaryDisabled, ${disabledColor(colors.brand.base, 0.6)});
      background-color: var(--buttonColorBgPrimaryDisabled, ${disabledColor(colors.brand.light, 0.6)});
      cursor: not-allowed;
    `
  },
  secondary: {
    normal: css`
      ${baseButtonStyles}
      background-color: var(--buttonColorBgSecondary, transparent);
      color: var(--buttonColorFgSecondary, ${colors.brand.base});
      box-shadow: 0 0 0 0.125em inset currentColor;

      &:hover:not([disabled]) {
        color: var(--buttonColorFgSecondaryHover, ${hoverColor(colors.brand.base)});
      }

      &:active:not([disabled]) {
        color: var(--buttonColorFgSecondaryActive, ${activeColor(colors.brand.base)});
      }
    `,
    selected: css`
      color: var(--buttonColorFgSecondaryActive, ${activeColor(colors.brand.base)});
    `,
    disabled: css`
      color: var(--buttonColorFgSecondaryDisabled, ${disabledColor(colors.brand.base, 0.4)});
      cursor: not-allowed;
    `
  },
  tertiary: {
    normal: css`
      ${baseButtonStyles}
      background-color: var(--buttonColorBgTertiary, transparent);
      color: var(--buttonColorFgTertiary, ${colors.brand.base});

      &:hover:not([disabled]) {
        color: var(--buttonColorFgTertiaryHover, ${hoverColor(colors.brand.base)});
      }

      &:active:not([disabled]) {
        color: var(--buttonColorFgTertiaryActive, ${activeColor(colors.brand.base)});
      }
    `,
    selected: css`
      color: var(--buttonColorFgTertiaryActive, ${activeColor(colors.brand.base)});
    `,
    disabled: css`
      color: var(--buttonColorFgTertiaryDisabled, ${disabledColor(colors.brand.base, 0.4)});
      cursor: not-allowed;
    `
  },
  destructive: {
    normal: css`
      ${baseButtonStyles}
      background-color: transparent;
      color: var(--buttonColorFgDestructive, ${colors.error});
      box-shadow: 0 0 0 0.125em inset currentColor;

      &:hover:not([disabled]) {
        color: var(--buttonColorFgDestructiveHover, ${hoverColor(colors.error)});
      }

      &:active:not([disabled]) {
        color: var(--buttonColorFgDestructiveActive, ${activeColor(colors.error)});
      }
    `,
    selected: css`
      color: var(--buttonColorFgDestructiveActive, ${activeColor(colors.error)});
    `,
    disabled: css`
      color: var(--buttonColorFgDestructiveDisabled, ${disabledColor(colors.error, 0.4)});
      cursor: not-allowed;
    `
  },
  small: css`
    padding: ${spacing[3]};
    font-size: ${typeScale[5]};
    line-height: ${lineHeight.tighter};
  `,
  medium: css`
    padding: ${spacing[4]} ${spacing[6]};
    font-size: ${typeScale[5]};
    line-height: 20px;
  `,
  large: css`
    padding: ${spacing[4]} ${spacing[6]};
    font-size: ${typeScale[4]};
    line-height: 24px;
  `
};

const baseFormValues = {
  borderColor: colors.shades.dark,
  borderColorValid: colors.success,
  borderColorInvalid: colors.error,
  selectedToggleColor: colors.brand.base,
  helpTextColor: colors.shades.darker,
  helpTextSize: typeScale[6],
  labelColor: colors.brand.base,
  labelSize: typeScale[5],
  errorMessageTextColor: colors.error,
  infoMessageTextColor: colors.info
};

const nib: ThemeSchema = {
  ...common,
  borderRadius: {
    ...common.borderRadius,
    small: 'var(--themeDimensionBorderRadiusSmall, 4px)',
    standard: 'var(--themeDimensionBorderRadiusStandard, 8px)'
  },
  typeScale,
  fonts,
  colors,
  focus,

  id: 'nib',
  mode: {
    form: 'light' //default
  },

  headings: {
    fontFamily: fonts.serif,
    color: 'currentColor',
    lineHeight: lineHeight.tighter,

    h1: {
      fontFamily: fonts.serif,
      fontSize: typeScale[1],
      fontWeight: 600,
      lineHeight: lineHeight.tighter,
      tracking: tracking.brand,
      uppercase: false
    },
    h2: {
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[2],
      lineHeight: lineHeight.tighter,
      fontWeight: 600,
      tracking: tracking.brand,
      uppercase: false
    },
    h3: {
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[3],
      lineHeight: lineHeight.tighter,
      fontWeight: 600,
      tracking: tracking.brand,
      uppercase: false
    },
    h4: {
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[4],
      lineHeight: lineHeight.tight,
      fontWeight: 700,
      tracking: tracking.brand,
      uppercase: false
    },
    h5: {
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[5],
      lineHeight: lineHeight.tight,
      fontWeight: 700,
      tracking: tracking.brand,
      uppercase: false
    },
    h6: {
      fontFamily: fonts.sansSerif,
      fontSize: typeScale[5],
      lineHeight: lineHeight.tight,
      fontWeight: 700,
      tracking: tracking.brand,
      uppercase: false
    }
  },

  copy: {
    color: 'currentColor',
    fontFamily: fonts.sansSerif,
    fontSize: typeScale[5],
    fontWeight: 400,
    lineHeight: lineHeight.normal,
    smallFontSize: typeScale[6],
    largeFontSize: typeScale[4]
  },

  link: {
    loudColor: colors.brand.base,
    darkColor: colors.shades.darkest,
    lightColor: colors.shades.white,
    destructiveColor: colors.error
  },

  button: {
    small: buttonStyles.small,
    medium: buttonStyles.medium,
    large: buttonStyles.large,
    primary: buttonStyles.primary,
    secondary: buttonStyles.secondary,
    tertiary: buttonStyles.tertiary,
    destructive: buttonStyles.destructive
  },

  forms: {
    light: {
      ...baseFormValues,
      backgroundColor: rebrandColors.sunsetPink[20],
      inputBackgroundColor: colors.shades.white,
      boxShadow: `0 4px 8px 0 ${colors.shades.lighter}`
    },
    white: {
      ...baseFormValues,
      backgroundColor: colors.shades.white,
      inputBackgroundColor: colors.shades.lightest,
      boxShadow: 'none'
    }
  },

  smallFooter: {
    backgroundColor: colors.brand.base,
    textColor: colors.shades.white,
    linkColor: colors.shades.white
  },

  panel: {
    loud: {
      backgroundColor: colors.brand.base,
      textColor: colors.shades.white
    },
    muted: {
      backgroundColor: colors.shades.lighter,
      textColor: colors.shades.black
    },
    light: {
      backgroundColor: colors.shades.lightest,
      textColor: colors.shades.black
    },
    dark: {
      backgroundColor: colors.shades.darkest,
      textColor: colors.shades.white
    },
    white: {
      backgroundColor: colors.shades.white,
      textColor: colors.shades.black
    }
  }
};

export default nib;

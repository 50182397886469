import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/aanz`;
const FONT_FAMILY = 'FS Lola';

export const AanzFonts = createGlobalStyle`
  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/fs_lola_web-extrabold.woff2) format('woff2'),
    url(${BRAND_CDN_URL}/fs_lola_web-extrabold.woff) format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/fs_lola_web-bold.woff2) format('woff2'),
    url(${BRAND_CDN_URL}/fs_lola_web-bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
`;

import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/australian-seniors`;
const PT_SANS = 'PT Sans';

export const AustralianSeniorsFonts = createGlobalStyle`
@font-face {
  font-family: ${PT_SANS};
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${BRAND_CDN_URL}/pt-sans-regular.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url(${BRAND_CDN_URL}/pt-sans-regular.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: ${PT_SANS};
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${BRAND_CDN_URL}/pt-sans-bold.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url(${BRAND_CDN_URL}/pt-sans-bold.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

`;

import socialMedia from './_socialMedia';
import {getBaseUrl} from './utils';
import {FooterConfig, GetConfigParams} from '../shared';

const PHONE_NUMBER = '1800 775 204';
const OVERSEAS_PHONE_NUMBER = '+61 2 9692 4488';
const CORPORATE_PHONE_NUMBER = null;
const ABSOLUTE_BASEURL = 'https://www.nib.com.au';

const getInternationalStudentConfig = (config: GetConfigParams): FooterConfig => {
  const baseUrl = getBaseUrl(config, ABSOLUTE_BASEURL);
  return {
    id: 'footer-students',
    columns: [
      {
        id: 'ga-footer-students-health-insurance',
        title: 'Health insurance',
        children: [
          {
            id: 'ga-footer-students-overseas-students-home',
            title: 'Overseas Student Health Cover (OSHC)',
            url: `${baseUrl}/overseas-students`
          }
        ]
      },
      {
        id: 'ga-footer-students-find-your-local',
        title: 'Find your local',
        children: [
          {
            id: 'ga-footer-students-doctor',
            title: 'Doctor (GP)',
            url: `${baseUrl}/find-a-provider/?selectedcategory=international`
          },
          {
            id: 'ga-footer-students-radiologist',
            title: 'Radiologist',
            url: `${baseUrl}/find-a-provider/?selectedcategory=international`
          },
          {
            id: 'ga-footer-students-pathologist',
            title: 'Pathologist',
            url: `${baseUrl}/find-a-provider/?selectedcategory=international`
          },
          {
            id: 'ga-footer-students-eye-care',
            title: 'Eye care centre',
            url: `${baseUrl}/health-information/extras-cover/no-gap-eye-care`
          },
          {
            id: 'ga-footer-students-dental-care',
            title: 'Dental care centre',
            url: `${baseUrl}/health-information/extras-cover/no-gap-dental`
          }
        ]
      },
      {
        id: 'ga-footer-students-popular-links',
        title: 'Popular links',
        children: [
          {
            id: 'ga-footer-students-forms-brochures',
            title: 'Forms & brochures',
            url: `${baseUrl}/health-information/member-services/forms-brochures#4towZcIwITm09nqou3CP5i`
          },
          {
            id: 'ga-inlink-students-the-checkup',
            title: 'The Check Up',
            url: `${baseUrl}/the-checkup`
          }
        ]
      }
    ],
    contact: {
      phoneNumber: PHONE_NUMBER,
      overseasPhoneNumber: OVERSEAS_PHONE_NUMBER,
      corporatePhoneNumber: CORPORATE_PHONE_NUMBER,
      socialLinks: socialMedia
    },
    smallFooterConfig: {
      id: 'small-footer-students',
      copyrightText: `Copyright © ${new Date().getFullYear()} nib health funds limited ABN 83 000 124 381`,
      phoneNumber: PHONE_NUMBER,
      overseasPhoneNumber: OVERSEAS_PHONE_NUMBER,
      corporatePhoneNumber: CORPORATE_PHONE_NUMBER,
      links: [
        {
          id: 'ga-footer-students-fund-rules',
          title: 'Fund Rules',
          url: `${baseUrl}/docs/oshc-fund-rules`
        },
        {
          id: 'ga-footer-students-terms',
          title: 'Terms & Conditions',
          url: `${baseUrl}/docs/online-terms`
        },
        {
          id: 'ga-footer-students-privacy',
          title: 'Privacy Policy',
          url: `${baseUrl}/legal/privacy-policy`
        },
        {
          id: 'ga-footer-students-code-conduct',
          title: 'Code of Conduct',
          url: `${baseUrl}/legal#codeofconduct`
        }
      ]
    }
  };
};

export default getInternationalStudentConfig;

import {map} from '../breakpoint';
import type {ThemeSchema, SpacingType} from '@nib-components/theme';
import {ResponsiveSpaceProp} from './../utils';

export type Values = ResponsiveSpaceProp;
export type Theme = ThemeSchema;

export function getSpacingFromTheme(key: SpacingType, theme: ThemeSchema): string {
  const value = theme && theme.spacing && theme.spacing[key];
  if (typeof value === 'undefined') {
    console.error(`A spacing named "${key}" does not exist.`); // eslint-disable-line no-console
    return '0';
  }
  return value;
}

export function _m(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        margin: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _mx(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        margin-inline: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _my(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        margin-block: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _mt(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        margin-block-start: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _mr(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        margin-inline-end: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _mb(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        margin-block-end: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _ml(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
      margin-inline-start: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _mbl(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        margin-block: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _mi(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
      margin-inline: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _p(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        padding: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _px(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        padding-inline: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _py(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        padding-block: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _pt(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        padding-block-start: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _pr(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        padding-inline-end: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _pb(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        padding-block-end: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _pl(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        padding-inline-start: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _pbl(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        padding-block: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export function _pi(values: Values, theme: Theme) {
  return map(values, (key: SpacingType) => {
    if (typeof key === 'string' || typeof key === 'number') {
      return `
        padding-inline: ${getSpacingFromTheme(key, theme)};
      `;
    }
    return '';
  });
}

export {_mt as _mbs, _mr as _mie, _mb as _mbe, _ml as _mis, _pt as _pbs, _pr as _pie, _pb as _pbe, _pl as _pis};

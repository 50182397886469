import React from 'react';
import styled from 'styled-components';
import {colorLightest, colorDarkest, smallFooterBgColor, smallFooterTextColor, smallFooterLinkColor, copyFontFamily, copyFontSizeSmall} from '@nib-components/theme';
import {Box, Inline, Columns, Column, Stack, Container, breakpoint, mb, mr} from '@nib/layout';
import Link from '@nib-components/link';
import {PhoneSystemIcon, EmailSystemIcon} from '@nib/icons';
//
import {AcknowledgementOfCountry} from '../AcknowledgementOfCountry';
import localConfig from '../config';
import {internationalVariations, SmallFooterConfig, Variation} from '../shared';

const validScreenSizes = ['desktop', 'mobile'] as const;
type screenSizeValues = (typeof validScreenSizes)[number];

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const SmallFooterWrapper = styled(({isFooterAttachment, nzShielded, ...rest}) => (isFooterAttachment ? <div {...rest} /> : <footer {...rest} />))`
  /* Double ups on background-color and color are to prevent breaking changes of theme version 5.7.0 */
  color: ${colorLightest};
  color: var(--smallFooterColorFg, ${smallFooterTextColor});
  background-color: ${colorDarkest};
  background-color: var(--smallFooterColorBg, ${smallFooterBgColor});
  font-family: ${copyFontFamily};
  font-size: ${copyFontSizeSmall};
`;

const FooterColumns = styled(Columns)`
  ${breakpoint('xs', 'xl')`
    display: flex;
    flex-direction: column;
  `};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FooterColumn = styled(({wrapInternationalLinks, ...rest}) => <Column {...rest} />)`
  display: flex;
  flex-flow: column wrap;

  ${breakpoint('xl')`
    ${({wrapInternationalLinks}: {wrapInternationalLinks?: boolean}) =>
      !wrapInternationalLinks &&
      `
      align-items: center;
      flex-direction: row;
    `};
    justify-content: space-between;
    gap: 0.5rem;
  `};
`;

const ContactColumn = styled(FooterColumn).attrs({
  'data-testid': 'contact-column'
})`
  display: none;

  ${breakpoint('xl')`
    display: flex;
    align-items: center;
  `};
`;

const StackedContactColumn = styled(ContactColumn).attrs({
  'data-testid': 'stacked-contact-column'
})`
  ${breakpoint('xs', 'xl')`
    display: flex;
    ${mb(4)};
  `};

  ${breakpoint('xl')`
    &&& {
      display: none;
    }
  `};
`;

const Copyright = styled.div`
  ${breakpoint('xs', 'xl')`
    ${mb(4)};
  `};
`;

const LineBreakSpan = styled.span`
  white-space: pre-wrap;
`;

const FooterLink = styled(Link)`
  /* Fallback to footer text color to prevent breaking changes of theme version 5.7.0 */

  &&& {
    color: inherit;
    color: var(--smallFooterColorFgLink, ${smallFooterLinkColor});
    white-space: normal;
  }

  &&&:hover,
  &&&:focus {
    color: var(--smallFooterColorFgLinkHover);
  }
`;

export const InternationalPhoneHeading = styled.div.attrs({'data-testid': 'international-phone-heading'})`
  font-size: ${copyFontSizeSmall};
`;

const BoldLinkEl = styled(FooterLink).attrs({underline: false})`
  font-weight: bold;
`;

const PhoneIcon = styled(PhoneSystemIcon)`
  vertical-align: sub;
  ${mr(2)};
`;

const EmailIcon = styled(EmailSystemIcon)`
  vertical-align: sub;
  ${mr(2)};
`;

const PhoneNumberSpan = styled.span`
  unicode-bidi: plaintext;
`;

interface LinkProps {
  screenSize?: screenSizeValues;
  ariaLabel?: string;
}

interface PhoneLinkProps extends LinkProps {
  phoneNumber: string;
  overseas?: boolean;
}

interface EmailLinkProps extends LinkProps {
  email: string;
}

const PhoneLink: React.FC<PhoneLinkProps> = ({phoneNumber, screenSize, overseas, ariaLabel}) => (
  <BoldLinkEl id={`ga-footer-call-${overseas ? 'overseas-' : ''}${screenSize}`} href={`tel:${phoneNumber.replace(/\s+/g, '')}`} aria-label={ariaLabel}>
    <PhoneIcon size="xs" fill="currentColor" />
    <PhoneNumberSpan>{phoneNumber}</PhoneNumberSpan>
  </BoldLinkEl>
);

const EmailLink: React.FC<EmailLinkProps> = ({email, screenSize, ariaLabel}) => (
  <BoldLinkEl id={`ga-footer-email-${screenSize}`} href={`mailto:${email.replace(/\s+/g, '')}`} aria-label={ariaLabel}>
    <EmailIcon size="xs" fill="currentColor" />
    {email}
  </BoldLinkEl>
);

PhoneLink.displayName = 'PhoneLink';

export interface SmallFooterProps {
  variation?: Variation;
  config?: SmallFooterConfig;
  isFooterAttachment?: boolean;
  forceHideAcknowledgementOfCountry?: boolean;
  isCorporate?: boolean;
  withAbsoluteLinks?: boolean;
  hidePhoneNumber?: boolean;
  absoluteBaseUrl?: string;
  [key: string]: unknown;
}

export const SmallFooter: React.FC<SmallFooterProps> & {
  SmallFooterWrapper: typeof SmallFooterWrapper;
} & {StackedContactColumn: typeof StackedContactColumn} & {
  ContactColumn: typeof ContactColumn;
} = props => {
  const {
    variation = 'arhi',
    config,
    isFooterAttachment = false,
    isCorporate = false,
    withAbsoluteLinks = false,
    hidePhoneNumber = false,
    absoluteBaseUrl,
    forceHideAcknowledgementOfCountry = false,
    ...otherProps
  } = props;
  const data: SmallFooterConfig = config ? config : localConfig[variation]({withAbsoluteLinks, absoluteBaseUrl}).smallFooterConfig;
  const {id, hideAcknowledgementOfCountry, phoneNumber, corporatePhoneNumber, overseasPhoneNumber, copyrightText, links, email} = data;
  const defaultOrCorporatePhoneNumber = isCorporate && corporatePhoneNumber ? corporatePhoneNumber : phoneNumber;
  const isInternational = internationalVariations.includes(variation);
  return (
    <SmallFooterWrapper data-mesh-component="SMALL-FOOTER" id={data ? id : 'small-footer'} isFooterAttachment={isFooterAttachment} {...otherProps}>
      {!hideAcknowledgementOfCountry && !forceHideAcknowledgementOfCountry && <AcknowledgementOfCountry />}
      <Box padding={5}>
        <Container>
          <FooterColumns space={{xs: 4, lg: 6}} align="left">
            {data && email && !isFooterAttachment && (
              <StackedContactColumn width="content">
                <EmailLink email={email} screenSize="mobile" ariaLabel="Email" />
              </StackedContactColumn>
            )}
            {data && defaultOrCorporatePhoneNumber && !isFooterAttachment && !hidePhoneNumber && (
              <StackedContactColumn width="content">
                <Inline space={4}>
                  <Stack space={2}>
                    {isInternational && <InternationalPhoneHeading>From Australia:</InternationalPhoneHeading>}
                    {defaultOrCorporatePhoneNumber && (
                      <PhoneLink phoneNumber={defaultOrCorporatePhoneNumber} screenSize="mobile" ariaLabel={variation === 'nz' ? 'Call from New Zealand' : 'Call from Australia'} />
                    )}
                  </Stack>
                  <Stack space={2}>
                    {isInternational && <InternationalPhoneHeading>From overseas:</InternationalPhoneHeading>}
                    {overseasPhoneNumber && <PhoneLink phoneNumber={overseasPhoneNumber} screenSize="mobile" overseas ariaLabel="Call from overseas" />}
                  </Stack>
                </Inline>
              </StackedContactColumn>
            )}
            <FooterColumn wrapInternationalLinks={isInternational && !isFooterAttachment}>
              <Copyright>{data && copyrightText && <LineBreakSpan>{copyrightText}</LineBreakSpan>}</Copyright>
              <nav>
                {data && links && links.length > 0 && (
                  <Inline space={{xs: 2, md: 4}} align="left" collapseBelow="md">
                    {links.map((link, i) => (
                      <FooterLink id={`${link.id}`} href={link.url} target={link.targetBlank ? '_blank' : ''} key={`footer-link-${i}-${link.id}`}>
                        {link.title}
                      </FooterLink>
                    ))}
                  </Inline>
                )}
              </nav>
            </FooterColumn>
            {data && email && !isFooterAttachment && (
              <ContactColumn width="content">
                <EmailLink email={email} screenSize="desktop" ariaLabel="Email" />
              </ContactColumn>
            )}
            {data && defaultOrCorporatePhoneNumber && !isFooterAttachment && !hidePhoneNumber && (
              <ContactColumn width="content">
                <Inline space={4}>
                  <Stack space={2}>
                    {isInternational && <InternationalPhoneHeading>From Australia:</InternationalPhoneHeading>}
                    {defaultOrCorporatePhoneNumber && (
                      <PhoneLink phoneNumber={defaultOrCorporatePhoneNumber} screenSize="desktop" ariaLabel={variation === 'nz' ? 'Call from New Zealand' : 'Call from Australia'} />
                    )}
                  </Stack>
                  <Stack space={2}>
                    {isInternational && <InternationalPhoneHeading>From overseas:</InternationalPhoneHeading>}
                    {overseasPhoneNumber && <PhoneLink phoneNumber={overseasPhoneNumber} screenSize="desktop" overseas ariaLabel="Call from overseas" />}
                  </Stack>
                </Inline>
              </ContactColumn>
            )}
          </FooterColumns>
        </Container>
      </Box>
    </SmallFooterWrapper>
  );
};

SmallFooter.displayName = 'SmallFooter';
SmallFooter.SmallFooterWrapper = SmallFooterWrapper;
SmallFooter.StackedContactColumn = StackedContactColumn;
SmallFooter.ContactColumn = ContactColumn;

import React from 'react';
import styled from 'styled-components';
import {px, py} from '@nib/layout';
import Link from '@nib-components/link';
import {colorLightest} from '@nib-components/theme';

export interface SkipLinkProps {
  link: string;
  text: string;
  [key: string]: unknown; // other props
}

const PositionedLink = styled(Link)`
  display: block;
  inset-inline-start: -9999em;
  outline: none;
  inset-block-start: -9999em;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;

  &:focus {
    clip: auto;
    height: auto;
    inset-inline-start: 10px;
    inset-block-start: 10px;
    width: auto;
    z-index: 100000;
  }

  ${py(2)};
  ${px(4)};
  background-color: var(--themeColorBgSurfaceProminent, ${colorLightest});

  /* Link style overrides */
  box-shadow: none !important;
  text-decoration: underline !important;
`;

PositionedLink.displayName = 'PositionedLink';

const SkipLink: React.FC<SkipLinkProps> = ({link, text, ...otherProps}) => (
  <PositionedLink href={link} color="loud" {...otherProps} data-mesh-component="SKIP-LINK">
    {text}
  </PositionedLink>
);

export default SkipLink;

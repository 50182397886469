import {css} from 'styled-components';
import {boxShadowNone, boxShadowStandard, BoxShadowType, validBoxShadowValues} from '@nib-components/theme';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp, Nullable} from './../../utils';

export type boxShadowValues = (typeof validBoxShadowValues)[number];

const mapStringToSelector = (string: boxShadowValues) => {
  switch (string) {
    case 'none':
      return boxShadowNone;
    case 'standard':
      return boxShadowStandard || 'hsla(0, 0%, 0%, .13) 0px 4px 8px 0px';
  }
};

export const boxShadow = () => css`
  ${({boxShadow}: {boxShadow: ResponsiveOrStaticProp<Nullable<BoxShadowType>>}) =>
    map(
      boxShadow,
      (val: boxShadowValues) =>
        val !== undefined &&
        css`
          box-shadow: ${mapStringToSelector(val)};
        `
    )};
`;

import {css} from 'styled-components';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp, Nullable} from './../../utils';

const validDisplayValues = ['none', 'inline', 'block', 'inline-block', 'flex', 'inline-flex'] as const;
export type displayValues = (typeof validDisplayValues)[number];

export type DisplayType = ResponsiveOrStaticProp<Nullable<displayValues>>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
export const display = () => css`
  ${({display}: {display: DisplayType}) =>
    map(
      display,
      (val: displayValues) =>
        val &&
        css`
          display: ${val};
        `
    )};
`;

import {getBaseUrl, getLogoUrl} from './utils';
import {HeaderConfigType, GetConfigParams} from './types';

const LOGO_URL = '/foundation';
const ABSOLUTE_BASEURL = 'https://www.nib.com.au';

const getFoundationConfig = (config: GetConfigParams): HeaderConfigType => {
  const baseUrl = getBaseUrl(config, ABSOLUTE_BASEURL);
  const logoUrl = getLogoUrl(config, baseUrl, LOGO_URL);
  return {
    id: 'header-foundation',
    logoUrl: logoUrl,
    ribbon: [
      {
        id: 'ga-secnav-foundation-nib-retail',
        title: 'nib health funds',
        url: `${baseUrl}/`
      }
    ],
    navLinks: [
      {
        id: 'ga-nav-foundation-about-us',
        title: 'About Us',
        url: `${baseUrl}/foundation/about-us`,
        children: [
          {
            id: 'ga-nav-foundation-about-us-leadership-team',
            title: 'Leadership Team',
            url: `${baseUrl}/foundation/about-us/leadership-team`
          },
          {
            id: 'ga-nav-foundation-about-us-community-reports',
            title: 'Community Report',
            url: `${baseUrl}/foundation/about-us/community-report`
          }
        ]
      },
      {
        id: 'ga-nav-foundation-partners',
        title: 'Our Partners',
        url: `${baseUrl}/foundation/our-partners`,
        children: [
          {
            id: 'ga-nav-foundation-partners-partnerships',
            title: 'Partnerships',
            url: `${baseUrl}/foundation/our-partners/partnerships`
          },
          {
            id: 'ga-nav-foundation-partners-health-support',
            title: 'Health Support',
            url: `${baseUrl}/foundation/our-partners/health-support`
          }
        ]
      },
      {
        id: 'ga-nav-foundation-funding',
        title: 'Funding',
        url: `${baseUrl}/foundation/funding`,
        children: [
          {
            id: 'ga-nav-foundation-prevention-partnerships',
            title: 'Prevention Partnerships program',
            url: `${baseUrl}/foundation/funding/prevention-partnerships-program`
          },

          {
            id: 'ga-nav-foundation-aboriginal-health-partnerships',
            title: 'Aboriginal Health Partnerships funding',
            url: `${baseUrl}/foundation/funding/aboriginal-health-partnerships-funding`
          },
          {
            id: 'ga-nav-foundation-community-support',
            title: 'Community Support',
            url: `${baseUrl}/foundation/funding/community-support`,
            children: [
              {
                id: 'ga-nav-foundation-nib-give',
                title: 'nibGive',
                url: `${baseUrl}/foundation/funding/community-support/nibgive`
              },
              {
                id: 'ga-nav-foundation-good-cause-grants',
                title: 'Good Cause Grants',
                url: `${baseUrl}/foundation/funding/community-support/good-cause-grants`
              }
            ]
          }
        ]
      },
      {
        id: 'ga-nav-foundation-stories',
        title: 'Stories',
        url: `${baseUrl}/foundation/stories`
      },
      {
        id: 'ga-nav-foundation-contact-us',
        title: 'Contact Us',
        url: `${baseUrl}/foundation/contact-us`
      }
    ],
    hideQuoteButton: true,
    smallHeaderConfig: {
      id: 'small-header-foundation',
      logoUrl: logoUrl,
      navLinks: []
    }
  };
};

export default getFoundationConfig;

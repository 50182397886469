/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {HeaderConfigType, GetConfigParams} from './types';
import getArhiConfig from './arhi';
import {ProfileSystemIcon} from '@nib/icons';

const PHONE_NUMBER = '1800 775 204';
const CORPORATE_PHONE_NUMBER = null;

const getInternationalConfig = (config: GetConfigParams): HeaderConfigType => {
  const arhiConfig = getArhiConfig(config);

  return {
    ...arhiConfig,
    id: 'header-international',
    phoneNumber: PHONE_NUMBER,
    corporatePhoneNumber: CORPORATE_PHONE_NUMBER,
    smallHeaderConfig: {
      id: 'small-header-international',
      logoUrl: arhiConfig.logoUrl,
      phoneNumber: PHONE_NUMBER,
      corporatePhoneNumber: CORPORATE_PHONE_NUMBER,
      navLinks: [
        {
          id: 'ga-nav-international-my-account',
          label: arhiConfig.callToAction!.title,
          title: arhiConfig.callToAction!.title,
          icon: ProfileSystemIcon,
          url: arhiConfig.callToAction!.url
        }
      ]
    }
  };
};

export default getInternationalConfig;

import {css} from 'styled-components';
import Colr from 'colr';
import common, {spacing} from '../../common';
import {ThemeSchema} from '../../types';

export const hoverColor = (color: string): string => Colr.fromHex(color).darken(10).toHex();
export const activeColor = (color: string): string => Colr.fromHex(color).darken(20).toHex();

export const disabledColor = (color: string, percent?: number): string => {
  const opacity = percent || 0.3;
  const rgb = Colr.fromHex(color).toRgbArray().toString();
  return `rgba(${rgb}, ${opacity})`;
};

const typeScale = {
  1: '2rem',
  2: '1.5rem',
  3: '1.125rem',
  4: '1rem',
  5: '.875rem',
  6: '.75rem'
};

const lineHeights = [1, 1.1667, 1.375, 1.5];

const tracking = {
  normal: 'normal'
};

const fonts = {
  buenosAires: '"Buenos Aires", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
  copy: '"Buenos Aires", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif'
};

const colors = {
  brand: {
    base: '#3F94AC',
    light: '#00C6D7',
    dark: '#3D6C89'
  },
  accent: '#00B49D',
  shades: {
    black: '#000000',
    darkest: '#444444',
    darker: '#626366',
    dark: '#999999',
    light: '#ebebeb',
    lighter: '#DCE5EC',
    lightest: '#e7eef7',
    nearWhite: '#fafafa',
    white: '#ffffff'
  },
  focus: '#00828d',
  error: '#dd5942',
  success: '#00b1c0',
  info: '#005bb9',
  product: {
    hospital: '#3D6C89',
    extras: '#3D6C89',
    combined: '#3D6C89',
    highlight: {
      backgroundColor: '#3D6C89',
      textColor: '#ffffff'
    }
  }
};

const focus = {
  outlineColor: colors.focus,
  outlineStyle: 'dotted',
  outlineWidth: '2px',
  outlineOffset: '0.125em'
};

const baseButtonStyles = css`
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  font-family: ${fonts.buenosAires};
  font-weight: 600;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  transition-property: background;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  border: none;
  display: inline-block;
  text-align: center;
  text-wrap: balance;
  text-decoration: none;
  vertical-align: middle;

  &:disabled {
    background-color: #e9eaec;
    color: #bcbdc1;
    cursor: default;
    pointer-events: none;
  }

  &:focus {
    outline: ${focus.outlineColor} ${focus.outlineStyle} ${focus.outlineWidth};
    outline-offset: ${focus.outlineOffset};
  }
`;

const buttonStyles = {
  primary: {
    normal: css`
      ${baseButtonStyles};
      background-color: #00b1c0;
      background-image: linear-gradient(195deg, ${colors.brand.light}, #3d6c89);
      color: ${colors.shades.white};

      &:hover {
        background-color: ${colors.brand.dark};
        background-image: linear-gradient(195deg, ${colors.brand.base}, #3d6c89);
      }

      &:active {
        background-color: ${colors.brand.dark};
        background-image: linear-gradient(195deg, #009daa, #3d6c89);
      }
    `,
    selected: css`
      background-color: ${colors.brand.dark};
      background-image: linear-gradient(195deg, #009daa, #3d6c89);
    `,
    disabled: css`
      background: #e9eaec;
    `
  },
  secondary: {
    normal: css`
      ${baseButtonStyles};
      background-color: ${colors.shades.white};
      color: ${colors.brand.dark};
      box-shadow: inset 0 0 0 1px currentColor;

      &:hover {
        color: ${hoverColor(colors.brand.dark)};
      }

      &:active {
        color: ${activeColor(colors.brand.dark)};
      }
    `,
    selected: css`
      color: ${activeColor(colors.brand.dark)};
    `,
    disabled: css`
      background-color: #e9eaec;
    `
  },
  tertiary: {
    normal: css`
      ${baseButtonStyles};
      background-color: ${colors.shades.white};
      color: ${colors.brand.dark};

      &:hover {
        color: ${hoverColor(colors.brand.dark)};
      }

      &:active {
        color: ${activeColor(colors.brand.dark)};
      }
    `,
    selected: css`
      color: ${activeColor(colors.brand.dark)};
    `,
    disabled: css`
      background-color: #e9eaec;
    `
  },
  destructive: {
    normal: css`
      ${baseButtonStyles};
      background-color: ${colors.shades.white};
      color: ${colors.error};
      box-shadow: inset 0 0 0 1px currentColor;

      &:hover {
        color: ${hoverColor(colors.error)};
      }

      &:active {
        color: ${activeColor(colors.error)};
      }
    `,
    selected: css`
      color: ${activeColor(colors.error)};
    `,
    disabled: css`
      color: ${disabledColor(colors.error, 0.6)};
      background-color: #e9eaec;
    `
  },
  small: css`
    padding: ${spacing[3]};
    font-size: ${typeScale[5]};
    line-height: 1;
  `,
  medium: css`
    padding: ${spacing[3]} ${spacing[5]};
    font-size: ${typeScale[4]};
    line-height: 20px;
  `,
  large: css`
    padding: ${spacing[3]} ${spacing[5]};
    font-size: ${typeScale[3]};
    line-height: 24px;
  `
};

const baseFormValues = {
  borderColor: colors.shades.dark,
  borderColorValid: colors.success,
  borderColorInvalid: colors.error,
  helpTextColor: colors.shades.darker,
  helpTextSize: typeScale[5],
  labelColor: colors.shades.darkest,
  labelSize: typeScale[4],
  selectedToggleColor: colors.brand.base,
  errorMessageTextColor: colors.error,
  infoMessageTextColor: colors.info
};

const gu: ThemeSchema = {
  ...common,
  typeScale,
  fonts,
  colors,
  focus,

  id: 'gu',
  mode: {
    form: 'light' //default
  },

  headings: {
    color: 'currentColor',
    h1: {
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[1],
      lineHeight: lineHeights[1],
      fontWeight: 600,
      tracking: tracking.normal,
      uppercase: false
    },
    h2: {
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[2],
      lineHeight: lineHeights[2],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: false
    },
    h3: {
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[3],
      lineHeight: lineHeights[2],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: false
    },
    h4: {
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[4],
      lineHeight: lineHeights[2],
      fontWeight: 600,
      tracking: tracking.normal,
      uppercase: false
    },
    h5: {
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[6],
      lineHeight: lineHeights[3],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: true
    },
    h6: {
      fontFamily: fonts.buenosAires,
      fontSize: typeScale[6],
      lineHeight: lineHeights[2],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: true
    }
  },

  copy: {
    color: colors.shades.darker,
    fontFamily: fonts.buenosAires,
    fontSize: typeScale[4],
    fontWeight: 400,
    lineHeight: lineHeights[3],
    smallFontSize: typeScale[5],
    largeFontSize: typeScale[3]
  },

  link: {
    loudColor: colors.brand.dark,
    darkColor: colors.shades.darker,
    lightColor: colors.shades.white,
    destructiveColor: colors.error
  },

  button: {
    small: buttonStyles.small,
    medium: buttonStyles.medium,
    large: buttonStyles.large,
    primary: buttonStyles.primary,
    secondary: buttonStyles.secondary,
    tertiary: buttonStyles.tertiary,
    destructive: buttonStyles.destructive
  },

  forms: {
    light: {
      ...baseFormValues,
      backgroundColor: colors.shades.lightest,
      inputBackgroundColor: colors.shades.white,
      boxShadow: `0 4px 8px 0 ${colors.shades.lighter}`
    },
    white: {
      ...baseFormValues,
      backgroundColor: colors.shades.white,
      inputBackgroundColor: colors.shades.lightest,
      boxShadow: 'none'
    }
  },

  smallFooter: {
    backgroundColor: '#0e4160',
    textColor: colors.shades.lightest,
    linkColor: colors.shades.lightest
  },

  panel: {
    loud: {
      backgroundColor: colors.brand.base,
      textColor: colors.shades.white
    },
    muted: {
      backgroundColor: colors.shades.lighter,
      textColor: colors.shades.darkest
    },
    light: {
      backgroundColor: colors.shades.lightest,
      textColor: colors.shades.darkest
    },
    dark: {
      backgroundColor: colors.shades.darkest,
      textColor: colors.shades.white
    },
    white: {
      backgroundColor: colors.shades.white,
      textColor: colors.shades.darkest
    }
  }
};

export default gu;

import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {Box, Columns, Column, Container, breakpoint, p} from '@nib/layout';
import Copy from '@nib-components/copy';
import Link from '@nib-components/link';
import {type ThemeVariants, smallFooterLinkColor} from '@nib-components/theme';

const CDN_BASEURL = 'https://static.nib.com.au/images/nib/mds/footer';

const IMAGE_PATHS = {
  BACKGROUND_SMALL: `${CDN_BASEURL}/RAP-bg-small.png`,
  BACKGROUND_LARGE: `${CDN_BASEURL}/RAP-bg-large.png`,
  ARTWORK_SMALL: `${CDN_BASEURL}/RAP-artwork.png`,
  ARTWORK_LARGE: `${CDN_BASEURL}/RAP-artwork@2x.png`
};

const FooterLink = styled(Link)`
  /* Fallback to footer text color to prevent breaking changes of theme version 5.7.0 */
  &&& {
    color: inherit;
    color: var(--footerColorFgLink, ${smallFooterLinkColor});
    white-space: normal;
  }
`;

export const BasicAcknowledgementOfCountry = ({statement}: {statement: AocStatementConfig | null}) => {
  if (!statement) {
    return null;
  }
  return (
    <Box padding={5} style={{background: 'hsla(0, 0%, 0%, 0.1)'}}>
      <Container>
        <Copy size="small" measure={false}>
          {statement?.wording}{' '}
          {statement?.link && (
            <FooterLink id={statement.link.id} href={statement.link.url}>
              {statement.link.label}
            </FooterLink>
          )}
        </Copy>
      </Container>
    </Box>
  );
};

BasicAcknowledgementOfCountry.displayName = 'BasicAcknowledgementOfCountry';

const COLLAPSE_BREAKPOINT = 'sm';

const Background = styled.div`
  position: relative;

  /* We do not have a darker trueGreen shade currently */
  background-color: hsl(0deg 0% 0% / 42%);

  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    opacity: 0.1;
    background-image: url(${IMAGE_PATHS.BACKGROUND_SMALL});
    background-size: 200%;
    background-position: center;
    background-repeat: no-repeat;

    ${breakpoint(COLLAPSE_BREAKPOINT)`
      background-size: 100%;
    `}

    ${breakpoint('lg')`
      background-image: url(${IMAGE_PATHS.BACKGROUND_LARGE});
    `}

    ${breakpoint('xxxl')`
      background-size: 1600px auto;
    `}
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
  ${p(5)};
`;

export const RAPImg = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 12rem;

  ${breakpoint(COLLAPSE_BREAKPOINT)`
    width: auto;
    max-height: 8rem;
  `}
`;

interface AocStatementConfig {
  wording: string;
  link?: {
    label: string;
    id: string;
    url: string;
  };
}

export const BRAND_AGNOSTIC_AOC_STATEMENT: AocStatementConfig = {
  wording: 'We acknowledge Aboriginal and Torres Strait Islander peoples as the Traditional Custodians of the lands where we live, learn and work.'
};

const brandSpecificAoc = (themeId: ThemeVariants): AocStatementConfig | null => {
  switch (themeId) {
    case 'nib':
    case 'foundation':
    case 'gu':
      return {
        ...BRAND_AGNOSTIC_AOC_STATEMENT,
        link: {
          label: 'View our Reconciliation Action Plan',
          id: 'ga-footer-reconciliation-action-plan',
          url: 'https://www.nib.com.au/docs/reconciliation-action-plan'
        }
      };

    case 'aami':
    case 'apia':
    case 'suncorp':
      return {
        ...BRAND_AGNOSTIC_AOC_STATEMENT,
        link: {
          label: 'View our Reconciliation Action Plan',
          id: 'ga-footer-reconciliation-action-plan',
          url: 'https://www.suncorpgroup.com.au/corporate-responsibility/resilient-people-and-communities/reconciliation'
        }
      };

    case 'qantas':
      return {
        wording: 'We acknowledge the Aboriginal and Torres Strait Islander Traditional Custodians of the land on which we work, live and fly. We pay respect to Elders past, present and emerging.'
      };

    case 'edi':
      return {
        wording: 'We acknowledge the Traditional Owners and Custodians of Country throughout Australia. We pay our respects to all First Nations peoples and acknowledge Elders past and present.',
        link: {
          label: 'Read more about our commitment to reconciliation',
          id: 'ga-footer-commitment-to-reconciliation',
          url: 'https://www.woolworthsgroup.com.au/au/en/reconciliation/reconciliation-at-woolworths-group.html'
        }
      };

    case 'iman':
    case 'nsi':
    case 'tid':
      return BRAND_AGNOSTIC_AOC_STATEMENT;

    case 'aanz':
    case 'australianSeniors':
    case 'cota':
    case 'ing':
    case 'priceline':
    case 'real':
    case 'worldNomads':
    default:
      return null;
  }
};

export const AcknowledgementOfCountry = () => {
  const themeContext = useContext(ThemeContext);
  const nibBrands = ['nib', 'foundation'];
  const omitAOCListBrands = ['aanz', 'australianSeniors', 'cota', 'ing', 'priceline', 'real', 'worldNomads'];

  const statement = brandSpecificAoc(themeContext.id);

  if (omitAOCListBrands.includes(themeContext.id)) {
    return null;
  }

  if (!nibBrands.includes(themeContext.id)) {
    return <BasicAcknowledgementOfCountry statement={statement} />;
  }

  return (
    <Background data-mesh-component="ACKNOWLEDGEMENT-OF-COUNTRY">
      <Wrapper>
        <Container>
          <Columns space={5} collapseBelow={COLLAPSE_BREAKPOINT} align="center" verticalAlign="center">
            <Column width="content">
              <Box textAlign="center">
                <RAPImg
                  srcSet={`${IMAGE_PATHS.ARTWORK_SMALL}, ${IMAGE_PATHS.ARTWORK_LARGE} 2x`}
                  src={IMAGE_PATHS.ARTWORK_SMALL}
                  alt="nib's 'the beginning' artwork by Michelle Kerrin"
                  height="128"
                  width="95"
                />
              </Box>
            </Column>
            <Column>
              <Box padding={4} background="trueGreen" borderRadius="standard">
                <Copy size="small" measure={false}>
                  {statement?.wording}{' '}
                  {statement?.link && (
                    <FooterLink id={statement.link.id} href={statement.link.url}>
                      {statement.link.label}
                    </FooterLink>
                  )}
                </Copy>
              </Box>
            </Column>
          </Columns>
        </Container>
      </Wrapper>
    </Background>
  );
};

AcknowledgementOfCountry.displayName = 'AcknowledgementOfCountry';

import {PropsWithTheme, FormVariantSchema} from './../types';
import theme from './theme';

const formMode = (props: PropsWithTheme): string => props.formMode || theme(props).mode.form;

const formLight = (props: PropsWithTheme): FormVariantSchema => theme(props).forms['light'];

const formWhite = (props: PropsWithTheme): FormVariantSchema => theme(props).forms['white'];

// these 4 selectors have a hard-coded "mode"
export const formLightBackgroundColor = (props: PropsWithTheme): string => formLight(props).backgroundColor;
export const formLightInputBackgroundColor = (props: PropsWithTheme): string => formLight(props).inputBackgroundColor;

export const formWhiteBackgroundColor = (props: PropsWithTheme): string => formWhite(props).backgroundColor;
export const formWhiteInputBackgroundColor = (props: PropsWithTheme): string => formWhite(props).inputBackgroundColor;

// The rest of the selectors after this line have a dynamic "mode"
const forms = (props: PropsWithTheme): FormVariantSchema => theme(props).forms[formMode(props)];

// these 3 selectors actually change based on the "mode"
export const formBackgroundColor = (props: PropsWithTheme): string => forms(props).backgroundColor;
export const formInputBackgroundColor = (props: PropsWithTheme): string => forms(props).inputBackgroundColor;
export const formBoxShadow = (props: PropsWithTheme): string => forms(props).boxShadow;

// The rest do source from different parts of the theme based on the mode, but are both the same
export const formBorderColor = (props: PropsWithTheme): string => forms(props).borderColor;
export const formBorderColorValid = (props: PropsWithTheme): string => forms(props).borderColorValid;
export const formBorderColorInvalid = (props: PropsWithTheme): string => forms(props).borderColorInvalid;

export const formSelectedToggleColor = (props: PropsWithTheme): string => forms(props).selectedToggleColor;

export const formHelpTextColor = (props: PropsWithTheme): string => forms(props).helpTextColor;
export const formHelpTextSize = (props: PropsWithTheme): string => forms(props).helpTextSize;

export const formLabelColor = (props: PropsWithTheme): string => forms(props).labelColor;
export const formLabelSize = (props: PropsWithTheme): string => forms(props).labelSize;

export const formErrorMessageColor = (props: PropsWithTheme): string => forms(props).errorMessageTextColor;
export const formInfoMessageColor = (props: PropsWithTheme): string => forms(props).infoMessageTextColor;

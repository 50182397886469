import React from 'react';
import slugify from 'slugify';
import styled from 'styled-components';
import Expander, {ExpanderProps} from '@nib-components/expander';
import Heading, {validComponentValues as validTitleComponentValues} from '@nib-components/heading';
import {copyFontSizeSmall, SpacingType, copyFontFamily, standardFocusStyleDeclarations} from '@nib-components/theme';
import {ChevronDownSystemIcon, IconProps} from '@nib/icons';
import {Columns, Column, Stack, p, m} from '@nib/layout';
import {BORDER_VALUE} from './utils';
export type AccordionItemTypes = Pick<ExpanderProps, 'onExpand' | 'onCollapse' | 'onBeforeExpand' | 'onBeforeCollapse'> &
  React.HTMLAttributes<HTMLDivElement> & {
    borderBottom?: boolean;
    hideItemBorder?: boolean;
    children: React.ReactNode;
    title: string;
    titleComponent?: (typeof validTitleComponentValues)[number];
    subtitle?: string;
    icon?: React.FC<IconProps>;
    expanded?: boolean;
    // onToggleExpanded is passed down automatically from <ExpanderGroup/>, do not overwrite
    readonly onToggleExpanded?: (event: React.MouseEvent | React.KeyboardEvent) => void;
    triggerId?: string;
    contentPadding?: SpacingType;
  };

interface ItemProps {
  // the bottom border for the last child
  readonly borderBottom?: boolean;
  // the bottom border for the container
  readonly hideItemBorder?: boolean;
}

interface TriggerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (event: React.MouseEvent) => void;
}

const Item = styled.div<ItemProps>`
  ${({hideItemBorder}) =>
    !hideItemBorder &&
    `
    border-bottom: ${BORDER_VALUE};
  `};

  ${({borderBottom}) =>
    !borderBottom &&
    `
    &:last-child {
      border-bottom: none;
    }
  `};
`;
Item.displayName = 'Item';

const WrappingHeading = styled.h4`
  margin: 0;
  padding: 0;
`;

const Trigger = styled.button<TriggerProps>`
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  -webkit-tap-highlight-color: var(--commonColorTransparent, transparent);
  appearance: none;
  border: none;
  background: var(--commonColorTransparent, transparent);
  color: inherit;
  display: block;
  width: 100%;
  text-align: inherit;
  ${p({xs: 2, mini: 4})};
  cursor: pointer;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:focus {
    ${standardFocusStyleDeclarations};
  }
`;
Trigger.displayName = 'Trigger';

/**
 * The default heading 4 size varies wildly across brands.
 * There is debate around adopting a standard typescale for all brands,
 * so in the meantime we are standardising just this accordion title size.
 */
const Title = styled(Heading)`
  font-size: 1.25rem;
`;
Title.displayName = 'Title';

const Subtitle = styled.div`
  font-family: ${copyFontFamily};
  font-size: ${copyFontSizeSmall};
  ${m(0)};
`;
Subtitle.displayName = 'Subtitle';

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const Chevron = styled(({expanded, ...otherProps}) => <ChevronDownSystemIcon {...otherProps} />)`
  ${({expanded}) => expanded && `transform: rotate(180deg);`};
  transition: transform 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
`;

Chevron.displayName = 'Chevron';

type ContentProps = Pick<AccordionItemTypes, 'contentPadding'>;

const Content = styled.div<ContentProps>`
  ${props => p(typeof props.contentPadding === 'number' ? props.contentPadding : {xs: 2, mini: 4})};
  font-family: ${copyFontFamily};

  /* MAGIC: Animations don't account for the full height of an element with a margin-bottom causing a jerk at the end of the animation */
  /* This override fixes that. */

  & > :last-child {
    margin-block-end: 0 !important;
  }

  & > :first-child {
    margin-block-start: 0 !important;
  }
`;

Content.displayName = 'Content';

const AccordionItem: React.FC<AccordionItemTypes> = props => {
  const {
    title,
    titleComponent = 'h4',
    subtitle,
    icon: Icon,
    children,
    borderBottom,
    hideItemBorder,
    expanded = false,
    onToggleExpanded,
    onExpand,
    onCollapse,
    onBeforeExpand,
    onBeforeCollapse,
    triggerId,
    contentPadding,
    ...otherProps
  } = props;

  return (
    <Item data-mesh-component="ACCORDION-ITEM" {...otherProps} borderBottom={borderBottom} hideItemBorder={hideItemBorder}>
      <WrappingHeading as={titleComponent}>
        <Trigger onClick={onToggleExpanded} aria-expanded={expanded} id={triggerId || slugify(`ga-inlink-${title}`, {lower: true})} type="button">
          <Columns verticalAlign="center" space={3} collapseBelow="mini">
            {Icon && (
              <Column width="content">
                <Icon size="sm" data-testid="accordion-custom-icon" />
              </Column>
            )}

            <Column>
              <Stack space={1}>
                {/* setting component as div here as we are wrapping the entire Trigger in a hx element */}
                <Title size={4} component="div">
                  {title}
                </Title>
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
              </Stack>
            </Column>

            <Column width="content">
              <Chevron size="xs" expanded={expanded} />
            </Column>
          </Columns>
        </Trigger>
      </WrappingHeading>

      <Expander expanded={expanded} onExpand={onExpand} onCollapse={onCollapse} onBeforeExpand={onBeforeExpand} onBeforeCollapse={onBeforeCollapse}>
        <Content contentPadding={contentPadding} role="region">
          {children}
        </Content>
      </Expander>
    </Item>
  );
};

AccordionItem.displayName = 'Accordion.Item';

export default AccordionItem;

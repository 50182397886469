import styled, {css} from 'styled-components';
import {buttonPrimaryStyles} from '@nib-components/theme';
import BaseButton, {BaseButtonProps, buttonDefaultProps, sizeStyling, fullWidthStyling} from '../BaseButton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PrimaryButton = styled(BaseButton).attrs(({theme, ...otherProps}) => ({
  'data-mesh-component': 'BUTTON-PRIMARY',
  ...buttonDefaultProps,
  ...otherProps
}))<BaseButtonProps>`
  ${props => buttonPrimaryStyles(props).normal}

  ${props =>
    props.selected &&
    css`
      ${buttonPrimaryStyles(props).selected};
    `}

  ${props =>
    props.disabled &&
    css`
      ${buttonPrimaryStyles(props).disabled};
    `}

  ${props =>
    props.disabled &&
    css`
      ${buttonPrimaryStyles(props).disabled};
    `}

  ${props =>
    props &&
    css`
      &:disabled {
        ${buttonPrimaryStyles(props).disabled};
      }
    `}
  
  ${sizeStyling}
  ${fullWidthStyling}
`;

PrimaryButton.displayName = 'PrimaryButton';

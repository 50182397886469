import {css} from 'styled-components';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp} from './../../utils';

export type ZIndexType = ResponsiveOrStaticProp<number>;

export const zIndex = () => css`
  ${({zIndex}: {zIndex: ZIndexType}) =>
    map(
      zIndex,
      (
        val: any // eslint-disable-line  @typescript-eslint/no-explicit-any
      ) =>
        val !== undefined &&
        css`
          z-index: ${val};
        `
    )};
`;

import React from 'react';
import styled, {css} from 'styled-components';
import Heading from '@nib-components/heading';
import {copyColor, BreakpointType} from '@nib-components/theme';
import {IconProps} from '@nib/icons';
import {colorFgValues, pickFgColor, Margin, map, mb} from '@nib/layout';

export const validTitleValues = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'label', 'span', 'header'] as const;
export type TitleType = (typeof validTitleValues)[number];

export type Nullable<T> = T | null;

type PartialRecord<K extends keyof never, T> = {
  [P in K]?: T;
};

type ResponsiveProp<T> = PartialRecord<BreakpointType, T>;
export type ResponsiveOrStaticProp<T> = T | ResponsiveProp<T>;

export type DisplayIconsType = ResponsiveOrStaticProp<Nullable<boolean>>;

export interface FeatureProps {
  icon: React.FC<IconProps>;
  displayIcons?: ResponsiveOrStaticProp<Nullable<boolean>>;
  title?: string;
  titleComponent?: TitleType;
  className?: string;
  children: React.ReactNode;
  iconFill?: colorFgValues;
  iconAccentFill?: colorFgValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const FeatureWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  text-align: center;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IconWrapper = styled(({display, iconFill, ...rest}) => <div {...rest} />)`
  ${mb(5)};

  ${({iconFill}) =>
    iconFill &&
    css`
      color: ${pickFgColor(iconFill)};
    `}

  ${({display}) => map(display, (val: boolean) => (val ? 'display: block;' : 'display: none;'))};
`;

export const Content = styled.div`
  display: inline-block;
  max-width: 30em;
  margin: auto;
  font-family: ${({theme}) => (theme.fonts && theme.fonts.sansSerif) || theme.fonts.copy || 'sans-serif'};
  color: var(--themeColorFg, ${copyColor});
  word-break: break-word;
  hyphens: auto;
`;

const Feature: React.FC<FeatureProps> = props => {
  const {icon: Icon, iconFill, displayIcons, title, titleComponent = 'h3', children, ...otherProps} = props;

  const featureTitle = title && (
    <Margin bottom={5}>
      <Heading size={4} component={titleComponent}>
        {title}
      </Heading>
    </Margin>
  );

  return (
    <FeatureWrapper {...otherProps} data-mesh-component="FEATURE">
      <IconWrapper display={displayIcons} iconFill={iconFill}>
        <Icon size="xl" />
      </IconWrapper>
      {featureTitle}
      <Content>{children}</Content>
    </FeatureWrapper>
  );
};

Feature.displayName = 'FeaturePanel.Feature';

export default Feature;

import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/nsi`;
const UNTITLED_SANS = 'Untitled Sans';
const UNTITLED_SERIF = 'Untitled Serif';

export const NsiFonts = createGlobalStyle`
  @font-face {
    font-family: ${UNTITLED_SANS};
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${BRAND_CDN_URL}/UntitledSansWeb-Regular.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/UntitledSansWeb-Regular.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: ${UNTITLED_SANS};
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${BRAND_CDN_URL}/UntitledSansWeb-Medium.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/UntitledSansWeb-Medium.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: ${UNTITLED_SERIF};
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${BRAND_CDN_URL}/UntitledSerifWeb-Regular.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/UntitledSerifWeb-Regular.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: ${UNTITLED_SERIF};
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${BRAND_CDN_URL}/UntitledSerifWeb-Medium.woff2) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${BRAND_CDN_URL}/UntitledSerifWeb-Medium.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
`;

import {createGlobalStyle} from 'styled-components';
import {FONTS_CDN_URL} from '../constants';

const BRAND_CDN_URL = `${FONTS_CDN_URL}/tid`;
const FONT_FAMILY = 'Averta';

export const TidFonts = createGlobalStyle`
  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/averta-regular.woff) format('woff'), 
        url(${BRAND_CDN_URL}/averta-regular.woff2) format('woff2');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: ${FONT_FAMILY};
    src: url(${BRAND_CDN_URL}/averta-extrabold.woff) format('woff'), 
        url(${BRAND_CDN_URL}/averta-extrabold.woff2) format('woff2');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }
`;

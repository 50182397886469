import styled, {css} from 'styled-components';
import {buttonSecondaryStyles} from '@nib-components/theme';
import BaseButton, {BaseButtonProps, buttonDefaultProps, sizeStyling, fullWidthStyling} from '../BaseButton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SecondaryButton = styled(BaseButton).attrs(({theme, ...props}) => ({
  'data-mesh-component': 'BUTTON-SECONDARY',
  ...buttonDefaultProps,
  ...props
}))<BaseButtonProps>`
  ${props => buttonSecondaryStyles(props).normal}

  ${props =>
    props.selected &&
    css`
      ${buttonSecondaryStyles(props).selected};
    `}

  ${props =>
    props.disabled &&
    css`
      ${buttonSecondaryStyles(props).disabled};
    `}

  ${props =>
    props &&
    css`
      &:disabled {
        ${buttonSecondaryStyles(props).disabled};
      }
    `}

  ${sizeStyling}
  ${fullWidthStyling}
`;

SecondaryButton.displayName = 'SecondaryButton';

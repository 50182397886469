import {SocialMediaLink} from '../shared';

export const facebook: SocialMediaLink = {
  key: 'Facebook',
  id: 'ga-footer-facebook',
  title: 'Like nib on Facebook',
  url: 'https://www.facebook.com/nibhealth'
};

export const twitter: SocialMediaLink = {
  key: 'Twitter',
  id: 'ga-footer-twitter',
  title: 'Follow nib on Twitter',
  url: 'https://twitter.com/nibhealth'
};

export const youtube: SocialMediaLink = {
  key: 'Youtube',
  id: 'ga-footer-youtube',
  title: 'Follow nib on YouTube',
  url: 'https://www.youtube.com/c/nibhealth'
};

export const instagram: SocialMediaLink = {
  key: 'Instagram',
  id: 'ga-footer-instagram',
  title: 'Follow nib on Instagram',
  url: 'https://www.instagram.com/nib.health'
};

export const linkedIn: SocialMediaLink = {
  key: 'LinkedIn',
  id: 'ga-footer-linked-in',
  title: 'Follow nib on Linked In',
  url: 'https://www.linkedin.com/company/nib-health'
};

export default [facebook, twitter, youtube, instagram, linkedIn];

import {PropsWithTheme, HeadingSchema, HeadingsSchema, CopySchema, LinkSchema} from '../types';
import theme from './theme';

const fonts = (props: PropsWithTheme): Record<string, string> => theme(props).fonts;

export const serifFont = (props: PropsWithTheme): string => fonts(props).serif;
export const sansSerifFont = (props: PropsWithTheme): string => fonts(props).sansSerif;

const headings = (props: PropsWithTheme): HeadingsSchema => theme(props).headings;

export const headingColor = (props: PropsWithTheme): string => headings(props).color;

const h1 = (props: PropsWithTheme): HeadingSchema => headings(props).h1;

export const h1FontFamily = (props: PropsWithTheme): string => h1(props).fontFamily;
export const h1FontSize = (props: PropsWithTheme): string => h1(props).fontSize;
export const h1FontWeight = (props: PropsWithTheme): number => h1(props).fontWeight;
export const h1Tracking = (props: PropsWithTheme): string => h1(props).tracking;
export const h1LineHeight = (props: PropsWithTheme): number => h1(props).lineHeight;
export const h1IsUpperCase = (props: PropsWithTheme): boolean => h1(props).uppercase;

const h2 = (props: PropsWithTheme): HeadingSchema => headings(props).h2;

export const h2FontFamily = (props: PropsWithTheme): string => h2(props).fontFamily;
export const h2FontSize = (props: PropsWithTheme): string => h2(props).fontSize;
export const h2FontWeight = (props: PropsWithTheme): number => h2(props).fontWeight;
export const h2Tracking = (props: PropsWithTheme): string => h2(props).tracking;
export const h2LineHeight = (props: PropsWithTheme): number => h2(props).lineHeight;
export const h2IsUpperCase = (props: PropsWithTheme): boolean => h2(props).uppercase;

const h3 = (props: PropsWithTheme): HeadingSchema => headings(props).h3;

export const h3FontFamily = (props: PropsWithTheme): string => h3(props).fontFamily;
export const h3FontSize = (props: PropsWithTheme): string => h3(props).fontSize;
export const h3FontWeight = (props: PropsWithTheme): number => h3(props).fontWeight;
export const h3Tracking = (props: PropsWithTheme): string => h3(props).tracking;
export const h3LineHeight = (props: PropsWithTheme): number => h3(props).lineHeight;
export const h3IsUpperCase = (props: PropsWithTheme): boolean => h3(props).uppercase;

const h4 = (props: PropsWithTheme): HeadingSchema => headings(props).h4;

export const h4FontFamily = (props: PropsWithTheme): string => h4(props).fontFamily;
export const h4FontSize = (props: PropsWithTheme): string => h4(props).fontSize;
export const h4FontWeight = (props: PropsWithTheme): number => h4(props).fontWeight;
export const h4Tracking = (props: PropsWithTheme): string => h4(props).tracking;
export const h4LineHeight = (props: PropsWithTheme): number => h4(props).lineHeight;
export const h4IsUpperCase = (props: PropsWithTheme): boolean => h4(props).uppercase;

const h5 = (props: PropsWithTheme): HeadingSchema => headings(props).h5;

export const h5FontFamily = (props: PropsWithTheme): string => h5(props).fontFamily;
export const h5FontSize = (props: PropsWithTheme): string => h5(props).fontSize;
export const h5FontWeight = (props: PropsWithTheme): number => h5(props).fontWeight;
export const h5Tracking = (props: PropsWithTheme): string => h5(props).tracking;
export const h5LineHeight = (props: PropsWithTheme): number => h5(props).lineHeight;
export const h5IsUpperCase = (props: PropsWithTheme): boolean => h5(props).uppercase;

const h6 = (props: PropsWithTheme): HeadingSchema => headings(props).h6;

export const h6FontFamily = (props: PropsWithTheme): string => h6(props).fontFamily;
export const h6FontSize = (props: PropsWithTheme): string => h6(props).fontSize;
export const h6FontWeight = (props: PropsWithTheme): number => h6(props).fontWeight;
export const h6Tracking = (props: PropsWithTheme): string => h6(props).tracking;
export const h6LineHeight = (props: PropsWithTheme): number => h6(props).lineHeight;
export const h6IsUpperCase = (props: PropsWithTheme): boolean => h6(props).uppercase;

const copy = (props: PropsWithTheme): CopySchema => theme(props).copy;

export const copyColor = (props: PropsWithTheme): string => copy(props).color;
export const copyFontFamily = (props: PropsWithTheme): string => copy(props).fontFamily;
export const copyFontSize = (props: PropsWithTheme): string => copy(props).fontSize;
export const copyFontWeight = (props: PropsWithTheme): number => copy(props).fontWeight;
export const copyLineHeight = (props: PropsWithTheme): number => copy(props).lineHeight;
export const copyFontSizeSmall = (props: PropsWithTheme): string => copy(props).smallFontSize;
export const copyFontSizeLarge = (props: PropsWithTheme): string => copy(props).largeFontSize;

const link = (props: PropsWithTheme): LinkSchema => theme(props).link;

export const linkLoud = (props: PropsWithTheme): string => link(props).loudColor;
export const linkDark = (props: PropsWithTheme): string => link(props).darkColor;
export const linkLight = (props: PropsWithTheme): string => link(props).lightColor;
export const linkDestructive = (props: PropsWithTheme): string => link(props).destructiveColor;

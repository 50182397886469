import React from 'react';
import styled from 'styled-components';
import {pb, pt} from '@nib/layout';
import {InclusionType} from '../Inclusion';
import Accordion from '@nib-components/accordion';
import InclusionButton from '../InclusionButton';

import {ServiceComponentGroupMapping, InclusionListUl} from '../InclusionList';
import {InclusionListNamedGroup} from '../InclusionListNamedGroups';

export interface InclusionListNamedGroupsAccordionProps {
  inclusions: InclusionType[];
  onInclusionClick: (inclusion: InclusionType) => void;
  serviceComponentGroupStructure: ServiceComponentGroupMapping;
  expandedServCompGroups: string[];
  expandedGroupIndexes: number[];
  onServCompGroupAccordionToggle: (serviceComponentGroup: string, expanded: boolean) => void;
}

// AccordionItem forces use of h4 and overrides font-size internally
const AccordionItemStyled = styled(Accordion.Item)`
  & h4 {
    font-size: 1rem;
  }
`;

interface InclusionListContainer {
  noTopPadding?: boolean;
}

const InclusionListContainer = styled.div<InclusionListContainer>`
  ${pb(6)};

  ${({noTopPadding}) =>
    !noTopPadding &&
    `
    ${pt(4)};
  `};
`;

const HIGHLIGHTED_GROUP = 'Commonly Used Services';

const InclusionListNamedGroupsAccordion = (props: InclusionListNamedGroupsAccordionProps) => {
  const {serviceComponentGroupStructure, onInclusionClick, onServCompGroupAccordionToggle, inclusions, expandedGroupIndexes, ...otherProps} = props;
  return (
    <React.Fragment>
      <InclusionListContainer noTopPadding>
        <InclusionListNamedGroup groupName={HIGHLIGHTED_GROUP} inclusions={inclusions} onInclusionClick={onInclusionClick} serviceComponentGroupStructure={serviceComponentGroupStructure} />
      </InclusionListContainer>
      <Accordion expandedIndexes={expandedGroupIndexes} multiExpanded contentPadding={0} borderBottom={false} borderTop={false}>
        {Object.keys(serviceComponentGroupStructure)
          .filter(key => key != HIGHLIGHTED_GROUP)
          .map(groupName => (
            <AccordionItemStyled
              title={groupName}
              onBeforeExpand={() => onServCompGroupAccordionToggle(groupName, true)}
              onBeforeCollapse={() => onServCompGroupAccordionToggle(groupName, false)}
              contentPadding={0}
              borderBottom={false}
              hideItemBorder={true}
            >
              <InclusionListContainer>
                <InclusionListUl {...otherProps}>
                  {(serviceComponentGroupStructure as ServiceComponentGroupMapping)[groupName].map((inclusion, index) => (
                    <InclusionButton key={`li-${inclusion.id}-${index}`} inclusion={inclusion} index={index} onInclusionClick={onInclusionClick} hideBottomBorder={false} />
                  ))}
                </InclusionListUl>
              </InclusionListContainer>
            </AccordionItemStyled>
          ))}
      </Accordion>
    </React.Fragment>
  );
};

export default InclusionListNamedGroupsAccordion;

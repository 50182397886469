import React from 'react';
import styled, {css} from 'styled-components';
import {breakpoint, map, px, py, pb, pt, p, mx, mt} from '@nib/layout';
import {colorWhite, colorLightest, BreakpointType, colorDarkest, borderRadiusStandard} from '@nib-components/theme';
import {sizeValues, componentValues} from '@nib-components/heading';

export const validVariationValues = ['full', 'condensed', 'text'] as const;
export type VariationValue = (typeof validVariationValues)[number];

export const validImageSizes = ['mobile', 'mobile2x', 'tablet', 'tablet2x', 'desktop', 'desktop2x'] as const;
export type ImageSize = (typeof validImageSizes)[number];

type Nullable<T> = T | null;

type ResponsiveProp<T> = Partial<Record<BreakpointType, T>>;
type ResponsiveOrStaticProp<T> = T | ResponsiveProp<T>;

export type ImageRecord = Partial<Record<ImageSize, Nullable<string>>>;

export interface HeroPanelProps {
  title?: string;
  subTitle?: string;
  images?: ImageRecord;
  imageDescription?: string;
  nextSectionBackgroundColor?: string;
  titleComponent?: componentValues;
  titleSize?: ResponsiveOrStaticProp<Nullable<sizeValues>>;
  children?: React.ReactNode;
  variation?: ResponsiveOrStaticProp<Nullable<VariationValue>>;
  backgroundPosition?: string;
  backgroundSize?: string;
  imageCaption?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const heroPanelMinHeight = 580;
const heroPanelMaxHeight = 960;
const heroPanelMaxWidth = 2000;
const contentMaxWidth = 600;
const desktopBottomOffset = 48;
const tabletBottomOffset = -64;
const mobileBottomOffset = -48;
const imageMinHeightTablet = 320;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DivWithoutPanelProps = ({variation, images, nextSectionBackgroundColor, ...props}: HeroPanelProps) => <div {...props} />;

export const HeroPanelWrapper = styled(DivWithoutPanelProps)`
  background-color: ${props => props.nextSectionBackgroundColor};

  ${({variation}) =>
    variation &&
    map(variation, (val: VariationValue) => {
      if (val === 'text') {
        return css`
          ${breakpoint('lg')`
            ${pb(0)};
          `};
        `;
      } else if (val === 'condensed') {
        return css`
          ${breakpoint('lg')`
            ${pb(0)};
          `};
        `;
      } else {
        return css`
          ${breakpoint('lg')`
            padding-block-end: ${desktopBottomOffset}px;
          `};
        `;
      }
    })}
`;

export const HeroPanelMain = styled(DivWithoutPanelProps)`
  max-width: ${heroPanelMaxWidth}px;
  margin-inline: auto;

  ${({variation}) =>
    variation &&
    map(variation, (val: VariationValue) => {
      if (val === 'text') {
        return css`
          &::before {
            display: none;
            content: '';
            width: 100%;
            ${pt(0)};
            background-color: var(--themeColorBgSurfaceProminent, ${colorWhite});
            background-image: none;

            @media (resolution >= 192dpi) {
              background-image: none;
            }
          }

          ${breakpoint('sm')`
            &::before {
              ${pt(0)};
              background-image: none;
        
              @media (resolution >= 192dpi) {
                background-image: none;
              }
            }
          `}

          ${breakpoint('lg')`
            &::before {
              content: none;
              background-image: none;
            }
          `}
        
          ${breakpoint('lg')`
            ${px(0)};
            background-color: var(--themeColorBg,${colorWhite});
            background-image: none;
        
            @media (resolution >= 192dpi) {
              background-image: none;
            }
          `}
        `;
      } else if (val === 'condensed') {
        return css`
          &::before {
            display: block;
            content: '';
            width: 100%;
            padding-block-start: 37.5%; /* Maintain aspect ratio of 400*150 image */
            background-color: var(--themeColorBgSurfaceProminent, ${colorLightest});
            background-image: url(${(
              {images}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
            ) => images && images.mobile});
            background-position: ${(
              {backgroundPosition}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
            ) => backgroundPosition ?? 'center center'};
            background-repeat: no-repeat;
            background-size: ${(
              {backgroundSize}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
            ) => backgroundSize ?? 'cover'};

            @media (resolution >= 192dpi) {
              background-image: url(${(
                {images}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
              ) => images && images.mobile2x});
            }
          }

          ${breakpoint('sm')`    
            &::before {
              padding-block-start: 36.25%; /* Maintain aspect ratio of 800*290 image */
              background-image: url(${({images}: {images: ImageRecord}) => images && images.tablet});
        
              @media (resolution >= 192dpi) {
                background-image: url(${({images}: {images: ImageRecord}) => images && images.tablet2x});
              }
            }
          `}

          ${breakpoint('lg')`
            ${px(4)};
            &::before {
              content: none;
              background-image: none;
            }
          `}
        
          ${breakpoint('lg')`
            background-color: var(--themeColorBgSurfaceProminent,${colorLightest});
            background-position: ${(
              {backgroundPosition}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
            ) => backgroundPosition ?? 'center center'};
            background-repeat: no-repeat;
            background-size: ${(
              {backgroundSize}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
            ) => backgroundSize ?? 'cover'};
            background-image: url(${({images}: {images: ImageRecord}) => images && images.desktop});
        
            @media (resolution >= 192dpi) {
              background-image: url(${({images}: {images: ImageRecord}) => images && images.desktop2x});
            }
          `}
        `;
      } else {
        return css`
          &::before {
            display: block;
            content: '';
            width: 100%;
            padding-block-start: 75%; /* Maintain aspect ratio of 400*300 image */
            background-color: var(--themeColorBgSurfaceProminent, ${colorLightest});
            background-image: url(${(
              {images}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
            ) => images && images.mobile});
            background-position: ${(
              {backgroundPosition}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
            ) => backgroundPosition ?? 'center center'};
            background-repeat: no-repeat;
            background-size: ${(
              {backgroundSize}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
            ) => backgroundSize ?? 'cover'};

            @media (resolution >= 192dpi) {
              background-image: url(${(
                {images}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
              ) => images && images.mobile2x});
            }
          }

          ${breakpoint('sm')`    
            &::before {
              padding-block-start: 60%; /* Maintain aspect ratio of 800*480 image */
              background-image: url(${({images}: {images: ImageRecord}) => images && images.tablet});
        
              @media (resolution >= 192dpi) {
                background-image: url(${({images}: {images: ImageRecord}) => images && images.tablet2x});
              }
            }
          `}

          ${breakpoint('lg')`
            ${px(4)};
            &::before {
              content: none;
              background-image: none;
            }
          `}
        
          ${breakpoint('lg')`
            background-color: var(--themeColorBgSurfaceProminent, ${colorLightest});
            background-position: ${(
              {backgroundPosition}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
            ) => backgroundPosition ?? 'center center'};
            background-repeat: no-repeat;
            background-size: ${(
              {backgroundSize}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
            ) => backgroundSize ?? 'cover'};
            background-image: url(${({images}: {images: ImageRecord}) => images && images.desktop});
        
            @media (resolution >= 192dpi) {
              background-image: url(${({images}: {images: ImageRecord}) => images && images.desktop2x});
            }
          `}
        `;
      }
    })}
`;

export const Flex = styled(DivWithoutPanelProps)`
  display: flex;

  ${({variation}) =>
    variation &&
    map(variation, (val: VariationValue) => {
      if (val === 'text') {
        return css`
          ${px(0)};

          ${breakpoint('lg')`
            height: auto;
            ${py(0)};
          `};
        `;
      } else if (val === 'condensed') {
        return css`
          ${px({xs: 4, sm: 5})};
          align-items: center;

          ${breakpoint('lg')`
            height: auto;
            ${px(0)};
            ${py(8)};
            min-height: ${imageMinHeightTablet}px;
          `};
        `;
      } else {
        return css`
          ${px({xs: 4, sm: 5})};
          align-items: flex-end;

          ${breakpoint('lg')`
            ${p(0)};
            min-height: 65vh; /* fallback if clamp isn't support */

            @supports (min-height: clamp(${heroPanelMinHeight + desktopBottomOffset}px, 65vh, ${heroPanelMaxHeight + desktopBottomOffset}px)) {
              min-height: clamp(${heroPanelMinHeight + desktopBottomOffset}px, 65vh, ${heroPanelMaxHeight + desktopBottomOffset}px);
            }
          `};
        `;
      }
    })}
`;

export const ContentBox = styled(DivWithoutPanelProps)`
  width: 100%;
  background-color: var(--themeColorBgSurfaceProminent, ${colorWhite});
  color: var(--themeColorFg, ${colorDarkest});
  margin: 0 auto;
  ${px({xs: 4, sm: 6, lg: 7})}

  ${breakpoint('lg')`
    ${mx(0)};
  `};

  ${({variation}) =>
    variation &&
    map(variation, (val: VariationValue) => {
      if (val === 'text') {
        return css`
          box-shadow: none;
          border-radius: 0;
          text-align: center;
          ${mt(0)};
          ${py(7)};
          ${py({xs: 7, sm: 8, lg: 9})}

          ${breakpoint('sm')`
            ${mt(0)};
            max-width: 100%;
          `};

          ${breakpoint('lg')`
            ${mt(0)};
            transform: none;
            max-width: 100%;
          `};
        `;
      } else if (val === 'condensed') {
        return css`
          ${py({xs: 5, sm: 6, lg: 7})}
          text-align: start;
          border-radius: ${borderRadiusStandard};
          border: 1px solid #d6d6d6;
          margin-block-start: ${mobileBottomOffset}px;

          ${breakpoint('sm')`
            margin-block-start: ${tabletBottomOffset}px;
          `};

          ${breakpoint('lg')`
            ${mt(0)};
            transform: none;
            max-width: ${contentMaxWidth}px;
          `};
        `;
      } else {
        return css`
          ${py({xs: 5, sm: 6, lg: 7})}
          text-align: start;
          border-radius: ${borderRadiusStandard};
          border: 1px solid #d6d6d6;
          margin-block-start: ${mobileBottomOffset}px;

          ${breakpoint('sm')`
            max-width: ${contentMaxWidth}px;
            margin-block-start: ${tabletBottomOffset}px;
          `};

          ${breakpoint('lg')`
            transform: translateY(${desktopBottomOffset}px);
            ${mt(0)};
          `};
        `;
      }
    })}
`;

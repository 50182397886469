import {css} from 'styled-components';
import Colr from 'colr';
import common, {spacing} from '../../common';
import {ThemeSchema} from '../../types';

export const hoverColor = (color: string): string => Colr.fromHex(color).darken(10).toHex();
export const activeColor = (color: string): string => Colr.fromHex(color).darken(20).toHex();

export const disabledColor = (color: string, percent?: number): string => {
  const opacity = percent || 0.3;
  const rgb = Colr.fromHex(color).toRgbArray().toString();
  return `rgba(${rgb}, ${opacity})`;
};

const typeScale = {
  1: '3.375rem',
  2: '2.25rem',
  3: '1.5rem',
  4: '1.125rem',
  5: '1rem',
  6: '.875rem'
};

const lineHeights = [1, 1.111, 1.333, 1.43];

const tracking = {
  normal: 'normal',
  tight: '-1px',
  tighter: '-2px'
};

const fonts = {
  heading: '"Roboto", "Helvetica Neue", "Helvetica", sans-serif',
  copy: '"Open Sans", "Helvetica Neue", "Helvetica", sans-serif'
};

const colors = {
  brand: {
    base: '#353269',
    light: '#47428c',
    dark: '#1E1743'
  },
  accent: '#FEBF00',
  shades: {
    black: '#222222',
    darkest: '#444444',
    darker: '#666666',
    dark: '#888888',
    light: '#cccccc',
    lighter: '#dddddd',
    lightest: '#eeeeee',
    nearWhite: '#fafafa',
    white: '#ffffff'
  },
  focus: '#89C531',
  success: '#47428c',
  error: '#d63e3e',
  info: '#47428c',
  product: {
    hospital: '#0088CC',
    extras: '#45428D',
    combined: '#45428D',
    highlight: {
      backgroundColor: '#008a20',
      textColor: '#ffffff'
    }
  }
};

const focus = {
  outlineColor: colors.focus,
  outlineStyle: 'dotted',
  outlineWidth: '1px',
  outlineOffset: '0.125em'
};

const baseButtonStyles = css`
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  font-family: ${fonts.copy};
  font-weight: 700;
  letter-spacing: ${tracking.normal};
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  transition-property: background;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  border: none;
  display: inline-block;
  text-align: center;
  text-wrap: balance;
  text-decoration: none;
  vertical-align: middle;

  &:disabled {
    cursor: default;
    pointer-events: auto;
  }

  &:focus {
    outline: ${focus.outlineColor} ${focus.outlineStyle} ${focus.outlineWidth};
    outline-offset: ${focus.outlineOffset};
  }
`;

const buttonStyles = {
  primary: {
    normal: css`
      ${baseButtonStyles};
      background-color: var(--buttonColorBgPrimary, ${colors.brand.base});
      color: var(--buttonColorFgPrimary, ${colors.shades.white});

      &:disabled {
        color: var(--buttonColorFgPrimaryDisabled, ${disabledColor(colors.shades.white)});
        background-color: var(--buttonColorBgPrimaryDisabled, ${disabledColor(colors.brand.dark, 0.6)});
      }

      &:hover:not([disabled]) {
        background-color: var(--buttonColorBgPrimaryHover, ${colors.brand.dark});
      }

      &:active:not([disabled]) {
        background-color: var(--buttonColorBgPrimaryActive, ${colors.brand.dark});
      }
    `,
    selected: css`
      background-color: var(--buttonColorBgPrimaryActive, ${colors.brand.dark});
    `,
    disabled: css`
      color: var(--buttonColorFgPrimaryDisabled, ${disabledColor(colors.shades.white)});
      background-color: var(--buttonColorBgPrimaryDisabled, ${disabledColor(colors.brand.dark, 0.6)});
      cursor: not-allowed;
    `
  },
  secondary: {
    normal: css`
      ${baseButtonStyles}
      color: ${colors.brand.base};
      background-color: ${colors.shades.white};
      box-shadow: inset 0 0 0 1px ${colors.brand.base};

      &:hover:not([disabled]),
      &:active:not([disabled]) {
        color: ${colors.brand.dark};
        box-shadow: inset 0 0 0 1px ${colors.brand.dark};
      }
    `,
    selected: css`
      color: ${colors.brand.dark};
      box-shadow: inset 0 0 0 1px ${colors.brand.dark};
    `,
    disabled: css`
      color: ${disabledColor(colors.brand.base, 0.6)};
      box-shadow: inset 0 0 0 1px ${disabledColor(colors.brand.base, 0.6)};
      background-color: ${disabledColor(colors.shades.white, 0.4)};
      cursor: not-allowed;
    `
  },
  tertiary: {
    normal: css`
      ${baseButtonStyles}
      color: ${colors.brand.base};
      background-color: ${colors.shades.white};

      &:hover:not([disabled]),
      &:active:not([disabled]) {
        color: ${colors.brand.dark};
      }
    `,
    selected: css`
      color: ${colors.brand.dark};
    `,
    disabled: css`
      color: ${disabledColor(colors.brand.base, 0.6)};
      background-color: ${disabledColor(colors.shades.white, 0.4)};
      cursor: not-allowed;
    `
  },
  destructive: {
    normal: css`
      ${baseButtonStyles}
      color: ${colors.error};
      background-color: ${colors.shades.white};
      box-shadow: inset 0 0 0 1px ${colors.error};

      &:hover:not([disabled]),
      &:active:not([disabled]) {
        color: ${hoverColor(colors.error)};
        box-shadow: inset 0 0 0 1px ${hoverColor(colors.error)};
      }
    `,
    selected: css`
      color: ${activeColor(colors.error)};
      box-shadow: inset 0 0 0 1px ${activeColor(colors.error)};
    `,
    disabled: css`
      color: ${disabledColor(colors.error, 0.6)};
      box-shadow: inset 0 0 0 1px ${disabledColor(colors.error, 0.6)};
      background-color: ${disabledColor(colors.shades.white, 0.4)};
      cursor: not-allowed;
    `
  },
  small: css`
    padding: ${spacing[3]};

    /* TODO: This needs fixing... was [7] */
    font-size: ${typeScale[6]};
    line-height: 1;
  `,
  medium: css`
    padding: ${spacing[4]} ${spacing[6]};
    font-size: ${typeScale[5]};
    line-height: 20px;
  `,
  large: css`
    padding: ${spacing[4]} ${spacing[6]};
    font-size: ${typeScale[4]};
    line-height: 24px;
  `
};

const baseFormValues = {
  borderColor: colors.shades.dark,
  borderColorValid: colors.success,
  borderColorInvalid: colors.error,
  selectedToggleColor: colors.brand.light,
  helpTextColor: colors.shades.darker,
  helpTextSize: typeScale[6],
  labelColor: colors.shades.darkest,
  labelSize: typeScale[5],
  errorMessageTextColor: colors.error,
  infoMessageTextColor: colors.info
};

const iman: ThemeSchema = {
  ...common,
  typeScale,
  fonts,
  colors,
  focus,

  id: 'iman',
  mode: {
    form: 'light' //default
  },

  headings: {
    fontFamily: fonts.heading,
    color: 'currentColor',
    lineHeight: 1.333,

    h1: {
      fontFamily: fonts.heading,
      fontSize: typeScale[1],
      fontWeight: 300,
      lineHeight: lineHeights[1],
      tracking: tracking.tighter,
      uppercase: false
    },
    h2: {
      fontFamily: fonts.heading,
      fontSize: typeScale[2],
      lineHeight: lineHeights[2],
      fontWeight: 300,
      tracking: tracking.tight,
      uppercase: false
    },
    h3: {
      fontFamily: fonts.heading,
      fontSize: typeScale[3],
      lineHeight: lineHeights[2],
      fontWeight: 300,
      tracking: tracking.tight,
      uppercase: false
    },
    h4: {
      fontFamily: fonts.heading,
      fontSize: typeScale[4],
      lineHeight: lineHeights[2],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: false
    },
    h5: {
      fontFamily: fonts.heading,
      fontSize: typeScale[5],
      lineHeight: lineHeights[2],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: false
    },
    h6: {
      fontFamily: fonts.heading,
      fontSize: typeScale[5],
      lineHeight: lineHeights[2],
      fontWeight: 400,
      tracking: tracking.normal,
      uppercase: false
    }
  },

  copy: {
    color: colors.shades.darkest,
    fontFamily: fonts.copy,
    fontSize: typeScale[5],
    fontWeight: 400,
    lineHeight: lineHeights[3],
    smallFontSize: typeScale[6],
    largeFontSize: typeScale[4]
  },

  link: {
    loudColor: colors.brand.base,
    darkColor: colors.shades.darkest,
    lightColor: colors.shades.white,
    destructiveColor: colors.error
  },

  button: {
    small: buttonStyles.small,
    medium: buttonStyles.medium,
    large: buttonStyles.large,
    primary: buttonStyles.primary,
    secondary: buttonStyles.secondary,
    tertiary: buttonStyles.tertiary,
    destructive: buttonStyles.destructive
  },

  forms: {
    light: {
      ...baseFormValues,
      backgroundColor: colors.shades.lightest,
      inputBackgroundColor: colors.shades.white,
      boxShadow: `0 4px 8px 0 ${colors.shades.lighter}`
    },
    white: {
      ...baseFormValues,
      backgroundColor: colors.shades.white,
      inputBackgroundColor: colors.shades.lightest,
      boxShadow: 'none'
    }
  },

  smallFooter: {
    backgroundColor: colors.shades.darkest,
    textColor: colors.shades.lightest,
    linkColor: colors.shades.lightest
  },

  panel: {
    loud: {
      backgroundColor: colors.brand.base,
      textColor: colors.shades.white
    },
    muted: {
      backgroundColor: colors.shades.lighter,
      textColor: colors.shades.darkest
    },
    light: {
      backgroundColor: colors.shades.lightest,
      textColor: colors.shades.darkest
    },
    dark: {
      backgroundColor: colors.shades.darkest,
      textColor: colors.shades.white
    },
    white: {
      backgroundColor: colors.shades.white,
      textColor: colors.shades.darkest
    }
  }
};

export default iman;

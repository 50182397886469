import React, {useState, useContext} from 'react';
import styled, {css, ThemeContext} from 'styled-components';
import Media from 'react-media';

import {SecondaryButton, UnstyledButton} from '@nib-components/button';
import Copy, {Bold} from '@nib-components/copy';
import Heading, {componentValues} from '@nib-components/heading';
import Link from '@nib-components/link';
import Modal from '@nib-components/modal';
import ProductBadge from '@nib-components/product-badge';
import {colorLighter, colorLightest, colorDark, colorDarkest, colorBlack} from '@nib-components/theme';
import {InfoSystemIcon} from '@nib/icons';
import Expander from '@nib-components/expander';
import {Columns, Column, Stack, Margin, breakpoint, m, mb, p, py} from '@nib/layout';

import {HOSPITAL_TIERS} from '../constants';

const tiers = {
  basic: HOSPITAL_TIERS.BASIC,
  'basic-plus': HOSPITAL_TIERS.BASIC_PLUS,
  bronze: HOSPITAL_TIERS.BRONZE,
  'bronze-plus': HOSPITAL_TIERS.BRONZE_PLUS,
  silver: HOSPITAL_TIERS.SILVER,
  'silver-plus': HOSPITAL_TIERS.SILVER_PLUS,
  gold: HOSPITAL_TIERS.GOLD
};

export interface TierListItemProps {
  tier: keyof typeof tiers;
  activeTier: keyof typeof tiers;
  explanation: React.ReactNode;
}

export interface TierListLiProps {
  active?: boolean;
}

export interface ExplanationProps {
  mbpUrl?: string;
  titleComponent?: componentValues;
}

export interface TiersButtonProps {
  onClick: () => void;
  as?: any; // eslint-disable-line   @typescript-eslint/no-explicit-any
  className?: string;
  children: React.ReactNode;
}

export interface TiersModalProps {
  visible: boolean;
  onClose: () => void;
  tier: keyof typeof tiers;
  mbpUrl?: string;
  titleComponent?: componentValues;
}

export interface HospitalTiersBadgeModalProps {
  tier: keyof typeof tiers;
  as?: string;
}

export const ProductTierButton = styled(UnstyledButton)`
  width: 100%;

  /* maybe remove this */
  ${py(2)};
  display: flex;
  align-items: center;
  color: var(--themeColorFgProminent, ${colorBlack});
`;

const InfoIcon = styled(InfoSystemIcon).attrs({size: 'xs'})`
  color: var(--themeColorFgMuted, ${colorDark});
  ${ProductTierButton}:hover &&,
  ${ProductTierButton}:focus && {
    color: var(--themeColorFg, ${colorDarkest});
  }
`;

const TierList = styled.ul`
  ${m(0)};
  ${mb(6)};
  ${p(0)};
  list-style: none;
`;

const TierListLi = styled.li<TierListLiProps>`
  ${p(4)};
  ${props =>
    props.active &&
    css`
      background-color: var(--themeColorBg, ${colorLightest});
    `};
  ${breakpoint('xs', 'sm')`
    margin: 0 -16px;
  `};
  &:not(:last-child) {
    border-block-end: 1px solid var(--themeColorBorder, ${colorLighter});
  }
`;

const TierListItem: React.FC<TierListItemProps> = ({tier, explanation, activeTier}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <TierListLi active={tier === activeTier}>
      <Columns collapseBelow="sm">
        <Column width="content">
          <Margin right={{xs: 2, sm: 4}} top={1}>
            <Media query={`(max-width: ${themeContext.breakpoints.sm}px)`}>{matches => (matches ? <ProductBadge tier={tier} size="md" /> : <ProductBadge tier={tier} size="lg" />)}</Media>
          </Margin>
        </Column>
        <Column>{explanation}</Column>
      </Columns>
    </TierListLi>
  );
};

export const Tier = TierListItem;

const BasicExplanation: React.FC<ExplanationProps> = ({titleComponent}) => {
  return (
    <React.Fragment>
      <Stack space={2}>
        <Heading size={4} component={titleComponent}>
          {HOSPITAL_TIERS.BASIC}
        </Heading>
        <Copy>
          All {HOSPITAL_TIERS.BASIC} covers must provide at least Minimum Benefits Payable (MBP) for Hospital Rehabilitation, Hospital Psychiatric Services and Palliative Care as a minimum
          requirement.
        </Copy>
        <Copy>
          MBP means if you're attending a private hospital, there will be significant out of pocket costs for the treatment. If the treatment is limited to MBP and is important to you, we recommend
          you consider a higher level of cover. MBP is the minimum amount of benefits that we are required to pay under the Private Health Insurance Act, to or on behalf of a member for hospital
          treatment under a hospital cover.
        </Copy>
      </Stack>
    </React.Fragment>
  );
};

const BasicPlusExplanation: React.FC<ExplanationProps> = ({titleComponent}) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <React.Fragment>
      <Stack space={2}>
        <Heading size={4} component={titleComponent}>
          {HOSPITAL_TIERS.BASIC_PLUS}
        </Heading>
        <Copy>
          All &lsquo;{HOSPITAL_TIERS.BASIC_PLUS}&rsquo; covers include additional hospital services on top of the minimum requirement. These extra services may be included or offered with{' '}
          <Link onClick={() => setExpanded(!expanded)} component="button">
            restricted benefits.
          </Link>
        </Copy>
        <Expander expanded={expanded}>
          <Bold>Restrictions</Bold>
          <Copy>
            For procedures listed as restricted on your policy, we will only pay a benefit called a Public Hospital Benefit. This means you will be covered in a shared ward of a public hospital, but
            it won't go anywhere near covering you for the cost of staying in a private room in public hospital (generally covers around 50% of the cost) or in a private hospital (generally covers
            between 5% and 30% of the cost). We will only pay for part of the restricted procedure, and you'll have to pay the difference. You may need a higher level of hospital cover if you think
            you may need to have a restricted procedure done in the future. For example, if hip or knee replacements are restricted on your health cover and you go to private hospital for one of these
            procedures, your health cover will only pay a small part of your hospital costs. You'll have to pay considerable out of pocket expenses towards your treatment.
          </Copy>
        </Expander>
      </Stack>
    </React.Fragment>
  );
};

const bronzeExplanation = (
  <React.Fragment>
    <Margin bottom={2}>
      <Heading size={4}>{HOSPITAL_TIERS.BRONZE}</Heading>
    </Margin>
    <Copy>{HOSPITAL_TIERS.BRONZE} covers include 18 further hospital services, such as brain and nervous system, joint reconstructions and ear, nose and throat.</Copy>
  </React.Fragment>
);

const bronzePlusExplanation = (
  <React.Fragment>
    <Margin bottom={2}>
      <Heading size={4}>{HOSPITAL_TIERS.BRONZE_PLUS}</Heading>
    </Margin>
    <Copy>All &lsquo;{HOSPITAL_TIERS.BRONZE_PLUS}&rsquo; covers include additional hospital services on top of the minimum requirement.</Copy>
  </React.Fragment>
);

const silverExplanation = (
  <React.Fragment>
    <Margin bottom={2}>
      <Heading size={4}>{HOSPITAL_TIERS.SILVER}</Heading>
    </Margin>
    <Copy>{HOSPITAL_TIERS.SILVER} covers include an additional 8 groupings of hospital services, such as heart and vascular system, dental surgery and lung and chest.</Copy>
  </React.Fragment>
);

const silverPlusExplanation = (
  <React.Fragment>
    <Margin bottom={2}>
      <Heading size={4}>{HOSPITAL_TIERS.SILVER_PLUS}</Heading>
    </Margin>
    <Copy>All &lsquo;{HOSPITAL_TIERS.SILVER_PLUS}&rsquo; covers include additional hospital services on top of the minimum requirement.</Copy>
  </React.Fragment>
);

const goldExplanation = (
  <React.Fragment>
    <Margin bottom={2}>
      <Heading size={4}>{HOSPITAL_TIERS.GOLD}</Heading>
    </Margin>
    <Copy>All {HOSPITAL_TIERS.GOLD} covers must not exclude or restrict any Medicare recognised hospital services, making Gold our most comprehensive cover.</Copy>
  </React.Fragment>
);

export const TiersButton: React.FC<TiersButtonProps> = ({onClick, as, className, children}) => {
  return (
    <ProductTierButton onClick={onClick} className={className}>
      <Margin right={1}>
        <Heading size={5} component={as}>
          {children}
        </Heading>
      </Margin>
      <InfoIcon size="xs" fill="currentColor" />
    </ProductTierButton>
  );
};

export const TiersModal: React.FC<TiersModalProps> = ({visible, titleComponent, onClose, tier}) => {
  return (
    <Modal title="New categories to help you choose your level of cover" visible={visible} onClose={onClose}>
      <Margin bottom={6}>
        <Margin bottom={4}>
          <Copy>
            All of our Hospital covers are now categorised into one of four tiers - Basic, Bronze, Silver and Gold. To be categorised in a certain tier, each hospital product must cover a minimum set
            of services as defined by the Government.
          </Copy>
        </Margin>
        <Margin bottom={4}>
          <Copy>These changes will make it easier for you to identify, compare and choose a health cover that&apos;s right for you.</Copy>
        </Margin>
        <Copy>
          This cover is <strong>{tiers[tier]}</strong>.
        </Copy>
      </Margin>

      <Margin bottom={4}>
        <Heading size={3} component={titleComponent}>
          Hospital tiers explained
        </Heading>
      </Margin>

      <TierList>
        <Tier activeTier={tier} tier="basic" explanation={<BasicExplanation />} />
        {tier === 'basic-plus' && <Tier activeTier={tier} tier="basic-plus" explanation={<BasicPlusExplanation />} />}

        <Tier activeTier={tier} tier="bronze" explanation={bronzeExplanation} />
        {tier === 'bronze-plus' && <Tier activeTier={tier} tier="bronze-plus" explanation={bronzePlusExplanation} />}

        <Tier activeTier={tier} tier="silver" explanation={silverExplanation} />
        {tier === 'silver-plus' && <Tier activeTier={tier} tier="silver-plus" explanation={silverPlusExplanation} />}

        <Tier activeTier={tier} tier="gold" explanation={goldExplanation} />
      </TierList>

      <SecondaryButton onClick={onClose}>Close</SecondaryButton>
    </Modal>
  );
};

const HospitalTiersBadgeModal: React.FC<HospitalTiersBadgeModalProps> & {
  Button: typeof TiersButton;
  Modal: typeof TiersModal;
} = ({tier, as}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <React.Fragment>
      <TiersButton onClick={() => setIsVisible(true)} as={as}>
        {tiers[tier]}
      </TiersButton>
      <TiersModal visible={isVisible} onClose={() => setIsVisible(false)} tier={tier} />
    </React.Fragment>
  );
};

HospitalTiersBadgeModal.Button = TiersButton;
HospitalTiersBadgeModal.Modal = TiersModal;

export default HospitalTiersBadgeModal;

import React from 'react';
import styled from 'styled-components';
import {nib} from '@nib-components/theme';
import {SizeMap, LogoComponent} from '../../types';

const sizes: SizeMap = {
  small: {
    width: '64px',
    height: '21.33px'
  },
  header: {
    width: '128px',
    height: '42.67px'
  },
  medium: {
    width: '148px',
    height: '49.33px'
  },
  large: {
    width: '192px',
    height: '64px'
  }
};

const wordmarkSizes: SizeMap = {
  small: {
    width: '40px',
    height: '21.33px'
  },
  header: {
    width: '80px',
    height: '42.67px'
  },
  medium: {
    width: '92px',
    height: '49.33px'
  },
  large: {
    width: '119px',
    height: '64px'
  }
};

export const StyledPath = styled.path<{invert?: boolean}>`
  fill: ${props => (props.invert ? nib.colors.brand.light : nib.colors.brand.base)};
`;

const NibLogo: LogoComponent = props => {
  const {invert, size = 'small', ...otherProps} = props;
  return (
    <svg x="0" y="0" width={sizes[size].width} height={sizes[size].height} viewBox="0 0 105.5 35.1" aria-label="NIB" role="img" {...otherProps}>
      <g>
        <StyledPath
          invert={invert}
          d="M62.2 11.2C61.3 10.3 60.3 9.6 59 9.1 57.8 8.6 56.4 8.3 54.9 8.3 53.1 8.3 51.5 8.6 50.2 9.3 48.9 10 48 10.7 47.3 11.4V8.7H40.4V34.7H47.4V20.5C47.4 18.7 47.9 17.3 49 16.2 50 15.2 51.4 14.7 53.2 14.7 54.7 14.7 55.9 15.2 56.7 16.1 57.5 17.1 57.9 18.3 57.9 19.9V34.7H64.9V18.7C64.9 17.1 64.7 15.7 64.2 14.4 63.8 13.2 63.1 12.1 62.2 11.2ZM74.8 8.7H67.8V34.7H74.8V8.7ZM74.8 0H67.8V6.5H74.8V0ZM104.4 16.6C103.7 15 102.7 13.5 101.6 12.3 100.4 11.1 99 10.2 97.4 9.5 95.8 8.8 94.1 8.5 92.4 8.5 90.9 8.5 89.5 8.7 88.2 9.1 86.9 9.5 85.7 10.1 84.8 10.8V0H77.8V34.7H84.8V32.8C85.7 33.5 86.8 34.1 88.2 34.5 89.5 34.9 90.9 35.1 92.4 35.1 94.2 35.1 95.8 34.8 97.4 34.1 99 33.4 100.4 32.5 101.6 31.3 102.8 30.1 103.7 28.7 104.4 27.1 105.1 25.5 105.5 23.7 105.5 21.9 105.5 20 105.1 18.2 104.4 16.6ZM98.1 24.6C97.7 25.4 97.3 26.2 96.7 26.8 96.1 27.4 95.4 27.9 94.5 28.3 93.7 28.7 92.8 28.8 91.8 28.8 90.8 28.8 89.8 28.6 89 28.3 88.1 27.9 87.4 27.4 86.7 26.8 86.1 26.2 85.6 25.4 85.2 24.6 84.8 23.8 84.7 22.8 84.7 21.9 84.7 21 84.9 20 85.3 19.2 85.7 18.3 86.2 17.6 86.8 17 87.4 16.4 88.2 15.9 89.1 15.5 90 15.1 90.9 14.9 91.9 14.9 92.8 14.9 93.7 15.1 94.6 15.5 95.4 15.9 96.2 16.4 96.8 17 97.4 17.6 97.9 18.4 98.2 19.2 98.6 20.1 98.7 21 98.7 21.9 98.6 22.8 98.4 23.7 98.1 24.6ZM0 14.1V20.6H9.6L2.8 27.4 7.4 31.9 14.1 25.1V34.7H20.6V14.1H0ZM12.6 12.6V8L7.4 2.8 2.8 7.4 8 12.6H12.6ZM22.1 22.1V26.7L27.4 31.9 31.9 27.4 26.7 22.1H22.1ZM31.9 7.4 27.4 2.8 22.1 8V12.6H26.7L31.9 7.4ZM27.4 14.1 24.1 17.4 27.4 20.6H34.7V14.1H27.4ZM14.1 7.4 17.4 10.6 20.6 7.4V0H14.1V7.4Z"
        />
      </g>
    </svg>
  );
};

NibLogo.displayName = 'NibLogo';

const NibLogomark: LogoComponent = props => {
  const {invert, size = 'small', ...otherProps} = props;
  return (
    <svg x="0" y="0" width={sizes[size].height} height={sizes[size].height} viewBox="0 0 34.7 34.7" aria-label="NIB" role="img" {...otherProps}>
      <g>
        <StyledPath
          invert={invert}
          d="M0 14.1V20.6H9.6L2.8 27.4 7.4 31.9 14.1 25.1V34.7H20.6V14.1H14.1 0ZM12.6 12.6V8L7.4 2.8 2.8 7.4 8 12.6H12.6ZM22.1 22.1V26.7L27.4 31.9 31.9 27.4 26.7 22.1H22.1ZM31.9 7.4 27.4 2.8 22.1 8V12.6H26.7L31.9 7.4ZM27.4 14.1 24.1 17.4 27.4 20.6H34.7V14.1H27.4ZM14.1 7.4 17.4 10.6 20.6 7.4V0H14.1V7.4Z"
        />
      </g>
    </svg>
  );
};

NibLogomark.displayName = 'NibLogomark';

const NibWordmark: LogoComponent = props => {
  const {invert, size = 'small', ...otherProps} = props;
  return (
    <svg x="0" y="0" width={wordmarkSizes[size].width} height={wordmarkSizes[size].height} viewBox="0 0 65.1 35.1" aria-label="NIB" role="img" {...otherProps}>
      <g>
        <StyledPath
          invert={invert}
          d="M21.8 11.2C20.9 10.3 19.9 9.6 18.6 9.1 17.4 8.6 16 8.3 14.5 8.3 12.7 8.3 11.1 8.6 9.8 9.3 8.5 10 7.6 10.7 6.9 11.4V8.7H0V34.7H7V20.5C7 18.7 7.5 17.3 8.6 16.2 9.6 15.2 11 14.7 12.8 14.7 14.3 14.7 15.5 15.2 16.3 16.1 17.1 17.1 17.5 18.3 17.5 19.9V34.7H24.5V18.7C24.5 17.1 24.3 15.7 23.8 14.4 23.4 13.2 22.7 12.1 21.8 11.2ZM34.4 8.7H27.4V34.7H34.4V8.7ZM34.4 0H27.4V6.5H34.4V0ZM64 16.6C63.3 15 62.3 13.5 61.2 12.3 60 11.1 58.6 10.2 57 9.5 55.4 8.8 53.7 8.5 52 8.5 50.5 8.5 49.1 8.7 47.8 9.1 46.5 9.5 45.3 10.1 44.4 10.8V0H37.4V34.7H44.4V32.8C45.3 33.5 46.4 34.1 47.8 34.5 49.1 34.9 50.5 35.1 52 35.1 53.8 35.1 55.4 34.8 57 34.1 58.6 33.4 60 32.5 61.2 31.3 62.4 30.1 63.3 28.7 64 27.1 64.7 25.5 65.1 23.7 65.1 21.9 65.1 20 64.7 18.2 64 16.6ZM57.7 24.6C57.3 25.4 56.9 26.2 56.3 26.8 55.7 27.4 55 27.9 54.1 28.3 53.3 28.7 52.4 28.8 51.4 28.8 50.4 28.8 49.4 28.6 48.6 28.3 47.7 27.9 47 27.4 46.3 26.8 45.7 26.2 45.2 25.4 44.8 24.6 44.4 23.8 44.3 22.8 44.3 21.9 44.3 21 44.5 20 44.9 19.2 45.3 18.3 45.8 17.6 46.4 17 47 16.4 47.8 15.9 48.7 15.5 49.6 15.1 50.5 14.9 51.5 14.9 52.4 14.9 53.3 15.1 54.2 15.5 55 15.9 55.8 16.4 56.4 17 57 17.6 57.5 18.4 57.8 19.2 58.2 20.1 58.3 21 58.3 21.9 58.2 22.8 58 23.7 57.7 24.6Z"
        />
      </g>
    </svg>
  );
};

NibWordmark.displayName = 'NibWordmark';

const NibArrow: LogoComponent = props => {
  const {invert, size = 'small', ...otherProps} = props;
  return (
    <svg x="0" y="0" width={sizes[size].height} height={sizes[size].height} viewBox="0 0 20.6 20.6" role="img" {...otherProps}>
      <g>
        <StyledPath invert={invert} d="M0 0V6.5H9.6L2.8 13.3L7.4 17.8L14.1 11V20.6H20.6V0H14.1H0Z" />
      </g>
    </svg>
  );
};

NibArrow.displayName = 'NibArrow';

export {NibLogomark, NibWordmark, NibArrow};
export default NibLogo;

import React from 'react';
import {Tab as ReactTab} from 'react-tabs';
import styled, {css} from 'styled-components';
import {
  copyFontSize,
  copyFontSizeSmall,
  copyFontFamily,
  copyColor,
  standardFocusStyleDeclarations,
  focusOutlineOffset,
  colorLight,
  colorWhite,
  borderRadiusStandard,
  formSelectedToggleColor
} from '@nib-components/theme';
import {breakpoint, px, p, pb, ml} from '@nib/layout';

import {SELECTED_TAB_CLASS_NAME, TAB_PADDING_BOTTOM, HORIZONTAL_PADDING, TAB_HOVER_MARGIN} from './config';

export interface TabProps {
  selected?: boolean;
  subtitle?: string;
  truncate?: boolean;
  children?: React.ReactNode;
  [key: string]: unknown;
}

export interface TabWrapperProps {
  selected?: boolean;
  fill?: boolean;
  [key: string]: unknown;
}

export interface TitleProps {
  selected?: boolean;
  subtitle?: string;
  [key: string]: unknown;
}

const truncatedTextStyles = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const baseStyles = css<TabWrapperProps>`
  display: inline-block;
  flex-shrink: 0;
  border-radius: ${borderRadiusStandard} ${borderRadiusStandard} 0 0;
  color: var(--themeColorFg, ${copyColor});
  font-family: ${copyFontFamily};
  cursor: pointer;
  white-space: nowrap;

  &:focus {
    ${standardFocusStyleDeclarations};
    outline-offset: -${focusOutlineOffset};
  }

  ${({fill}: {fill?: boolean}) => fill && `flex-grow: 1;`};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TabWrapper = styled(({fill, showBorder, truncate, selectedTabClassName, ...rest}) => <ReactTab {...rest} />)<TabWrapperProps>`
  ${baseStyles};
  display: block;
  position: relative;
  ${p(4)};
  ${px(HORIZONTAL_PADDING)};
  background-color: var(--themeColorBgSurface, hsl(0deg 0% 100% / 65%));

  ${({showBorder}: {showBorder?: boolean}) =>
    showBorder &&
    css`
      border: 1px solid var(--themeColorBorder, ${colorLight});
      border-bottom: 0;
    `};

  &:not(:first-child) {
    ${ml(2)};
  }

  ${({truncate, fill}) =>
    truncate &&
    !fill &&
    css`
      max-width: 50vw;

      ${breakpoint('md')`
        max-width: 16em;
      `}

      ${breakpoint('xl')`
        max-width: 24em;
      `}
    `};

  ${({selected, showBorder}) =>
    selected
      ? css`
          z-index: 3;
          color: var(--themeColorFgBrand, ${formSelectedToggleColor});
          background-color: var(--themeColorBgSurfaceProminent, ${colorWhite});
          ${showBorder ? 'border-bottom: 0;' : ''};
        `
      : css`
          z-index: 1;
          ${pb(TAB_PADDING_BOTTOM)};
          transition: 0.25s ease;

          &:hover {
            transform: translateY(-${TAB_HOVER_MARGIN});
            background-color: var(--themeColorBgSurfaceProminent, hsl(0deg 0% 100% / 85%));
          }
        `};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Title = styled(({selected, truncate, subtitle, ...rest}) => <div {...rest} />)<TitleProps>`
  font-size: ${copyFontSize};
  text-align: center;
  font-weight: 700;
  ${({truncate}) => truncate && truncatedTextStyles};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Subtitle = styled(({truncate, ...rest}) => <div {...rest} />)`
  font-size: ${copyFontSizeSmall};
  text-align: center;
  margin: auto;
  ${({truncate}) =>
    truncate &&
    css`
      ${truncatedTextStyles};
      max-width: calc(50vw * 0.6);

      ${breakpoint('md')`
      max-width: calc(16em * .6);
      `}

      ${breakpoint('xl')`
      max-width: calc(24em * .6);
      `}
    `}
`;

const Tab: React.FC<TabProps> & {tabsRole: string} = ({selected, truncate, subtitle, showBorder, children, ...otherProps}) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const scrollIntoView = (e: any) => {
    e.currentTarget.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'nearest'});
  };

  return (
    <TabWrapper selected={selected} truncate={truncate} {...otherProps} showBorder={showBorder} onFocus={scrollIntoView} onClick={scrollIntoView} selectedTabClassName={SELECTED_TAB_CLASS_NAME}>
      <Title selected={selected} subtitle={subtitle} truncate={truncate}>
        {children}
      </Title>
      {subtitle && <Subtitle truncate={truncate}>{subtitle}</Subtitle>}
    </TabWrapper>
  );
};

Tab.tabsRole = 'Tab';
Tab.displayName = 'Tab';

export default Tab;

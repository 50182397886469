/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled, {css} from 'styled-components';
import {m, p} from '../spacing';
import {isList, ResponsiveSpaceProp} from '../utils';

import {margins} from './lib/margin';
import {paddings} from './lib/padding';
import {display, DisplayType} from './lib/display';
import {width, maxWidth, height, maxHeight, DimensionsType, minWidth, minHeight} from './lib/dimensions';
import {alignItems, justifyContent, flexDirection, flexWrap, flexGrow, flexShrink, AlignItemsType, JustifyContentType, flexDirectionValues, FlexWrapType, FlexGrowShrinkType} from './lib/flex';
import {textAlign, TextAlignType} from './lib/textAlign';
import {background, color, responsiveFgColorValues, responsiveBgColorValues, responsiveBorderColorValues} from './lib/colors';
import {borderRadius, borderRadiusValues} from './lib/border-radius';
import {boxShadow, boxShadowValues} from './lib/box-shadow';
import {gap} from './lib/gap';
import {LineHeightType, lineHeight} from './lib/line-height';
import {position, PositionType, ElementPositionType} from './lib/position';
import {overflow, OverflowType} from './lib/overflow';
import {border, BorderStyleType, BorderWidthType} from './lib/border';
import {zIndex, ZIndexType} from './lib/z-index';

export interface BoxProps {
  padding?: ResponsiveSpaceProp;
  paddingVertical?: ResponsiveSpaceProp;
  paddingHorizontal?: ResponsiveSpaceProp;
  paddingTop?: ResponsiveSpaceProp;
  paddingBottom?: ResponsiveSpaceProp;
  paddingLeft?: ResponsiveSpaceProp;
  paddingRight?: ResponsiveSpaceProp;
  paddingBlockStart?: ResponsiveSpaceProp;
  paddingInlineEnd?: ResponsiveSpaceProp;
  paddingBlockEnd?: ResponsiveSpaceProp;
  paddingInlineStart?: ResponsiveSpaceProp;
  paddingBlock?: ResponsiveSpaceProp;
  paddingInline?: ResponsiveSpaceProp;
  margin?: ResponsiveSpaceProp;
  marginVertical?: ResponsiveSpaceProp;
  marginHorizontal?: ResponsiveSpaceProp;
  marginTop?: ResponsiveSpaceProp;
  marginBottom?: ResponsiveSpaceProp;
  marginLeft?: ResponsiveSpaceProp;
  marginRight?: ResponsiveSpaceProp;
  marginBlockStart?: ResponsiveSpaceProp;
  marginInlineEnd?: ResponsiveSpaceProp;
  marginBlockEnd?: ResponsiveSpaceProp;
  marginInlineStart?: ResponsiveSpaceProp;
  marginBlock?: ResponsiveSpaceProp;
  marginInline?: ResponsiveSpaceProp;
  display?: DisplayType;
  width?: DimensionsType;
  minWidth?: DimensionsType;
  maxWidth?: DimensionsType;
  height?: DimensionsType;
  minHeight?: DimensionsType;
  maxHeight?: DimensionsType;
  alignItems?: AlignItemsType;
  justifyContent?: JustifyContentType;
  flexDirection?: flexDirectionValues;
  flexWrap?: FlexWrapType;
  flexGrow?: FlexGrowShrinkType;
  flexShrink?: FlexGrowShrinkType;
  textAlign?: TextAlignType;
  background?: responsiveBgColorValues;
  color?: responsiveFgColorValues;
  borderRadius?: borderRadiusValues;
  boxShadow?: boxShadowValues;
  gap?: ResponsiveSpaceProp;
  lineHeight?: LineHeightType;
  position?: PositionType;
  overflow?: OverflowType;
  borderColor?: responsiveBorderColorValues;
  borderWidth?: DimensionsType | BorderWidthType;
  borderStyle?: BorderStyleType;
  zIndex?: ZIndexType;
  isolate?: boolean;
  top?: ElementPositionType;
  right?: ElementPositionType;
  bottom?: ElementPositionType;
  left?: ElementPositionType;
  inset?: ElementPositionType;
  as?: string;
  children?: React.ReactNode;
  [key: string]: unknown;
}

export const Box = styled(
  ({
    padding,
    paddingVertical,
    paddingHorizontal,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingBlockStart,
    paddingInlineEnd,
    paddingBlockEnd,
    paddingInlineStart,
    paddingBlock,
    paddingInline,
    margin,
    marginVertical,
    marginHorizontal,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    marginBlockStart,
    marginInlineEnd,
    marginBlockEnd,
    marginInlineStart,
    marginBlock,
    marginInline,
    display,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    alignItems,
    justifyContent,
    flexDirection,
    flexWrap,
    flexGrow,
    flexShrink,
    textAlign,
    background,
    color,
    borderRadius,
    boxShadow,
    gap,
    lineHeight,
    position,
    overflow,
    zIndex,
    isolate,
    borderStyle,
    borderColor,
    borderWidth,
    as,
    ...rest
  }) => <div data-mesh-component="BOX" {...rest} />
)<BoxProps>`
  box-sizing: border-box;

  /* If box is set to be a ul or ol we need to reset the default styling */
  ${({as}) =>
    as &&
    isList(as) &&
    css`
      ${m(0)};
      ${p(0)};
      list-style-type: none;
    `}

  ${({isolate}) =>
    isolate &&
    css`
      isolation: isolate;
    `}

  ${props => margins()};
  ${props => paddings()};

  ${props => display()};

  ${props => width()};
  ${props => minWidth()};
  ${props => maxWidth()};
  ${props => height()};
  ${props => minHeight()};
  ${props => maxHeight()};

  ${props => alignItems()};
  ${props => justifyContent()};
  ${props => flexDirection()};
  ${props => flexWrap()};
  ${props => flexGrow()};
  ${props => flexShrink()};

  ${props => textAlign()};

  ${props => background()};
  ${props => color()};
  ${props => borderRadius()};
  ${props => boxShadow()};

  ${props => gap()};
  ${props => lineHeight()};
  ${props => position()};
  ${props => overflow()};
  ${props => border()};
  ${props => zIndex()};
`;

Box.displayName = 'Box';

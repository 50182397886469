import React from 'react';
import styled from 'styled-components';
import {standardFocusStyleDeclarations} from '@nib-components/theme';
import {paddings, p, ResponsiveSpaceProp} from '@nib/layout';

export interface UnstyledButtonProps extends React.HTMLProps<HTMLButtonElement> {
  padding?: ResponsiveSpaceProp;
  paddingVertical?: ResponsiveSpaceProp;
  paddingHorizontal?: ResponsiveSpaceProp;
  paddingTop?: ResponsiveSpaceProp;
  paddingBottom?: ResponsiveSpaceProp;
  paddingLeft?: ResponsiveSpaceProp;
  paddingRight?: ResponsiveSpaceProp;
}

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
export const UnstyledButton = styled(({padding, paddingVertical, paddingHorizontal, paddingTop, paddingBottom, paddingLeft, paddingRight, ...rest}) => <button {...rest} />)<UnstyledButtonProps>`
  appearance: none;
  background: none;
  border: none;
  ${p(0)};
  cursor: pointer;
  line-height: 1;
  color: currentColor;

  &:focus {
    ${standardFocusStyleDeclarations};
  }
  ${paddings()};
`;

export default UnstyledButton;
UnstyledButton.displayName = 'UnstyledButton';

import {css} from 'styled-components';
import {breakpoints as BreakpointMap} from '@nib-components/theme';
import type {validBreakpointValues} from '@nib-components/theme';
import type {Nullable, ResponsiveOrStaticProp} from '../utils';

export type StyledComponentsInterpolation = ((executionContext: object) => StyledComponentsInterpolation) | string | number | StyledComponentsInterpolation[];
export type StyledComponentsTemplateLiteral = (strings: string[], ...interpolations: StyledComponentsInterpolation[]) => StyledComponentsInterpolation[];
export type Breakpoint = (typeof validBreakpointValues)[number];
export type BreakpointMapValueToCSSFunction<T> = (value?: T) => string | ReturnType<typeof css>; //StyledComponentsInterpolation;

type ComponentProps = {
  theme: {
    breakpoints: typeof BreakpointMap;
  };
};

export const breakpoint = (gte: Breakpoint, lt?: Breakpoint) => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return function (strings: any, ...interpolations: any[]) {
    return function ({theme}: ComponentProps) {
      return _breakpoint(theme.breakpoints || BreakpointMap, gte, lt)(strings, ...interpolations);
    };
  };
};

export function _breakpoint(breakpoints: typeof BreakpointMap, gte: Breakpoint, lt?: Breakpoint) {
  if (typeof lt === 'undefined') {
    return _gte(breakpoints, gte);
  } else {
    return _between(breakpoints, gte, lt);
  }
}

export function _gte(breakpoints: typeof BreakpointMap, name: Breakpoint) {
  return withSingleCriteria(breakpoints, name, 'min-width');
}

export function _between(breakpoints: typeof BreakpointMap, gte: Breakpoint, lt: Breakpoint) {
  const gteValue = getValueFromName(breakpoints, gte);
  const ltValue = getValueFromName(breakpoints, lt);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return function (strings: any, ...interpolations: any[]) {
    return css`
      @media (min-width: ${convertPxToEm(gteValue)}em) and (max-width: ${convertPxToEm(ltValue - 1)}em) {
        ${css(strings, ...interpolations)}
      }
    `;
  };
}

function withSingleCriteria(breakpoints: typeof BreakpointMap, name: Breakpoint, operator: 'min-width' | 'max-width', offset = 0) {
  const value = getValueFromName(breakpoints, name);

  // special case for 0 to avoid wrapping styles in an unnecessary @media block
  if (operator === 'min-width' && value === 0) {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return function (strings: any, ...interpolations: any[]) {
      return css(strings, ...interpolations);
    };
  }

  return function (strings: any, ...interpolations: StyledComponentsInterpolation[]) {
    return css`
      @media (${operator}: ${convertPxToEm(value + offset)}em) {
        ${css(strings, ...interpolations)}
      }
    `;
  };
}

function getValueFromName(breakpoints: typeof BreakpointMap, name: Breakpoint): number {
  if (!(name in breakpoints)) {
    console.error(`breakpoint: Breakpoint "${name}" was not found.`);
    return 0;
  }
  return breakpoints[name];
}

function convertPxToEm(pixels: number): number {
  // @media is always calculated off 16px regardless of whether the root font size is the default or not
  return pixels / 16;
}

export function map(value: any, mapValueToCSS: any) {
  return function ({theme}: ComponentProps) {
    return _map(theme.breakpoints || BreakpointMap, value, mapValueToCSS);
  };
}

export function _map<T>(breakpoints: typeof BreakpointMap, value: ResponsiveOrStaticProp<Nullable<T>>, mapValueToCSS: BreakpointMapValueToCSSFunction<Nullable<T>>): any {
  const values = value;
  if (values === null || typeof values !== 'object') {
    return mapValueToCSS(values);
  }

  return [
    mapValueToCSS(undefined), // set the default value
    ...Object.keys(values).map(name => {
      const tag = _gte(breakpoints, name as Breakpoint);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const val: T = values[name];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const styles = tag([], [].concat(mapValueToCSS(val)));
      return styles;
    })
  ];
}

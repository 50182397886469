import React, {useId, useState} from 'react';
import styled, {css} from 'styled-components';
import {colorBrandBase, colorLightest, colorWhite, colorDarkest, focusOutlineOffset, standardFocusStyleDeclarations, borderRadiusStandard} from '@nib-components/theme';
import {PhoneSystemIcon, ChevronDownSystemIcon, IconType} from '@nib/icons';
import {Inline, Hidden, Container, breakpoint, map, p, px, py, m, mr} from '@nib/layout';
import Link from '@nib-components/link';
import {ChildLinkType} from '@nib/drawer';

export const validVariationValues = ['arhi', 'shareholders', 'nz', 'foundation', 'thrive', 'providers', 'international', 'internationalStudents', 'internationalWorkers', 'gu'] as const;
export type variationValues = (typeof validVariationValues)[number];

export const HeaderWrapper = styled.header.attrs({'data-mesh-component': 'HEADER'})`
  display: none;
  background-color: var(--smallHeaderColorBg, ${colorWhite});

  ${breakpoint('xl')`
    display: flex;
    flex-direction: column;
  `}
`;

export const HeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  ${py(4)};
`;

export const LogoLink = styled(Link).attrs({
  underline: false,
  'data-testid': 'logo-link'
})`
  &&& {
    display: flex;
    align-items: center;
    ${p(4)};

    ${breakpoint('xl', 'xxl')`
      ${px(3)};
    `}
  }

  &:focus {
    ${standardFocusStyleDeclarations};
    outline-offset: -${focusOutlineOffset};
  }
`;

LogoLink.displayName = 'LogoLink';

export const HeaderContact = styled.div`
  display: flex;
  align-items: center;
`;

export const PhoneNumber = styled.a`
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--smallHeaderColorFgIcons, ${colorBrandBase});

  &:focus {
    ${standardFocusStyleDeclarations};
  }
`;

export const PhoneIcon = styled(PhoneSystemIcon)`
  color: var(--smallHeaderColorFgIcons, ${colorBrandBase});
`;

export const HeaderLinks = styled.div`
  display: flex;
  align-items: center;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SearchLink = styled(({hiddenLink, children, ...rest}) => (
  <a {...rest} aria-label="Search">
    {children}
  </a>
))`
  display: ${({hiddenLink}) => (hiddenLink ? `none` : `block`)};
  ${mr(2)};
  ${p(3)};
  line-height: 0;
  border: none;
  background: transparent;
  color: var(--smallHeaderColorFgIcons, ${colorBrandBase});

  &:hover {
    cursor: pointer;
  }

  &:focus {
    ${standardFocusStyleDeclarations};
  }
`;

export const HeaderNav = styled.nav`
  color: var(--headerColorFgNav, ${colorDarkest});
  background-color: var(--headerColorBgNav, ${colorLightest});
`;

export const NavMenu = styled.ul<{navAlignment?: string}>`
  display: flex;
  ${m(0)};
  ${px(0)};
  list-style: none;

  ${({navAlignment}) => navAlignment && `justify-content: center`};
`;

export const NavItem = styled.li.attrs({'data-testid': 'nav-item'})<{positionRight?: boolean}>`
  display: flex;
  position: relative;

  ${({positionRight}) => positionRight && `margin-inline-start: auto`};
`;
NavItem.displayName = 'NavItem';

export const baseNavLinkStyles = css`
  /* Button reset styles */
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  ${p(4)};
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: ${(
    {color}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) => color || 'inherit'};

  ${NavItem}:focus > &&,
  ${NavItem}:hover > && {
    color: var(--headerColorFgNavHover, ${colorBrandBase});
  }

  ${NavItem}:focus-within > && {
    color: var(--headerColorFgNavHover, ${colorBrandBase});
  }

  &:focus {
    ${standardFocusStyleDeclarations};
    outline-offset: -${focusOutlineOffset};
    z-index: 1;
  }
`;

export const NavLink = styled(({children, ...props}) => <a {...props}>{children}</a>)`
  ${baseNavLinkStyles};
`;

NavLink.displayName = 'NavLink';

export const DropdownContainer = styled.ul`
  background-color: var(--themeColorBgSurfaceProminent, ${colorWhite});
  border-radius: ${borderRadiusStandard};

  /* Same as hero panel */
  box-shadow: 0 4px 8px 0 hsl(0deg 0% 0% / 15%);
  ${m(0)};
  ${p(0)};
  ${py(4)};
  list-style: none;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    border-block-start: 4px solid var(--themeColorBorderSelected, ${colorBrandBase});
    position: absolute;
    inset-block-start: 0;
  }
`;

export const NavDropdown = styled.div<{positionRight?: boolean}>`
  position: absolute;
  top: calc(100% - 4px);
  ${({positionRight}) => (positionRight ? `right: 0` : `left: 0`)};
  inset-inline-start: 0;

  /* default for IE11 */
  width: 17rem;

  @supports (width: max-content) {
    width: max-content;
    max-inline-size: 30rem;
  }

  z-index: 1;
  height: 0;
  overflow: hidden;

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border: 8px solid transparent;
    border-block-end: 8px solid var(--themeColorBorderSelected, ${colorBrandBase});
    inset-block-start: 0;
    transform: translateY(-100%);
    ${({positionRight}) => (positionRight ? `right: 2rem` : `left: 2rem`)};
    inset-inline-start: 2rem;
  }

  ${NavItem}:focus > &&,
  ${NavItem}:hover > && {
    height: auto;
    overflow: visible;
  }

  ${NavItem}:focus-within > && {
    height: auto;
    overflow: visible;
  }
`;

export const NavDropdownLink = styled(Link).attrs({underline: false, 'data-testid': 'nav-dropdown-link'})`
  &&& {
    display: block;
    ${px(5)};
    ${py(3)};
    color: var(--themeColorFg, inherit);

    ${breakpoint('xl', 'xxl')`
      ${px(3)};
    `}
  }

  &:focus,
  &:hover,
  &:active {
    color: var(--themeColorFgBrand, ${colorBrandBase});
    box-shadow: inset 4px 0 var(--themeColorBorderSelected, ${colorBrandBase});
  }

  ${(
    {current}: any // eslint-disable-line  @typescript-eslint/no-explicit-any
  ) =>
    current &&
    css`
      color: var(--themeColorFgBrand, ${colorBrandBase});
      box-shadow: inset 4px 0 var(--themeColorBorderSelected, ${colorBrandBase});
    `}

  &:focus {
    ${standardFocusStyleDeclarations};
    outline-offset: -${focusOutlineOffset};
  }
`;

export const NavDropdownSubLink = styled(NavDropdownLink).attrs({'data-testid': 'nav-dropdown-sub-link'})`
  padding-left: 2.5rem;
`;

NavDropdownSubLink.displayName = 'NavDropdownSubLink';

export const NavDropdownHeading = styled.div`
  font-weight: bold;
  ${px(5)};
  ${py(3)};

  ${breakpoint('xl', 'xxl')`
    ${px(3)};
  `}
`;

export const DropdownIcon = styled(ChevronDownSystemIcon)`
  vertical-align: middle;
  ${({breakpoints}) =>
    map(
      breakpoints,
      (val: number | string) =>
        css`
          width: ${val}px;
          height: ${val}px;
        `
    )}
  transform: translate(0deg);

  ${NavItem}:focus &&,
  ${NavItem}:hover && {
    transform: rotate(180deg);
  }

  /* FIXME: && here should fix things */
  ${NavItem}:focus-within && {
    transform: rotate(180deg);
  }
`;

const LineHeightOne = styled.span`
  line-height: 1;
`;

const NoWrapInline = styled(Inline)`
  flex-wrap: nowrap;
  line-height: 0;
`;

export interface NavLinkType extends ChildLinkType {
  slug?: string;
  positionRight?: boolean;
  icon?: IconType;
}

export interface NavLinkItemProps {
  activePage?: string;
  dropdownIconSize?: number | string;
  navLink: React.ReactNode;
  link: NavLinkType;
  icon?: IconType;
  [key: string]: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
}

export const NavLinkItem: React.FC<NavLinkItemProps> = props => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {link, activePage, dropdownIconSize = 24, navLink, ...otherProps} = props;
  const {id, title, url, targetBlank, slug, ...otherLinkProps} = link;
  const NavLink = navLink as React.ElementType;
  const Icon = link.icon as React.ElementType;
  const expanderId = useId();
  return link.children ? (
    <NavItem
      positionRight={link.positionRight}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      onFocus={() => setIsExpanded(true)}
      onBlur={() => setIsExpanded(false)}
    >
      <NavLink
        id={`${id}-desktop`}
        title={title}
        href={url}
        target={targetBlank && '_blank'}
        rel={targetBlank && 'noopener noreferrer'}
        aria-expanded={isExpanded}
        aria-controls={expanderId}
        aria-current={activePage === slug ? 'page' : null}
        isActivePage={activePage === slug}
        {...otherLinkProps}
        {...otherProps}
      >
        <NoWrapInline space={2} verticalAlign="center">
          {Icon ? <Icon size="md" /> : ''}
          <LineHeightOne>{link.title}</LineHeightOne>
          <DropdownIcon breakpoints={{xl: 16, xxl: dropdownIconSize}} />
        </NoWrapInline>
      </NavLink>
      <NavDropdown id={expanderId} aria-label={link.title} positionRight={link.positionRight}>
        <DropdownContainer>
          {link.children &&
            link.children.map(child => {
              const {id, title, url, targetBlank, ...otherProps} = child;
              return (
                <li key={`${child.id}-desktop`}>
                  <NavDropdownLink id={`${id}-desktop`} title={title} href={url} target={targetBlank && '_blank'} rel={targetBlank && 'noopener noreferrer'} {...otherProps}>
                    {title}
                  </NavDropdownLink>

                  {child.children && (
                    <NavMenu style={{flexDirection: 'column'}}>
                      {child.children &&
                        child.children.map(subChild => {
                          const {id, title, url, targetBlank, ...otherProps} = subChild;
                          return (
                            <li key={`${subChild.id}-desktop`}>
                              <NavDropdownSubLink id={`${id}-desktop`} title={title} href={url} target={targetBlank && '_blank'} rel={targetBlank && 'noopener noreferrer'} {...otherProps}>
                                <Inline verticalAlign="center" space={2}>
                                  <Hidden screenReader>
                                    <span>&mdash;</span>
                                  </Hidden>
                                  <span>{subChild.title}</span>
                                </Inline>
                              </NavDropdownSubLink>
                            </li>
                          );
                        })}
                    </NavMenu>
                  )}
                </li>
              );
            })}
        </DropdownContainer>
      </NavDropdown>
    </NavItem>
  ) : (
    <NavItem positionRight={link.positionRight}>
      <NavLink
        id={`${link.id}-desktop`}
        title={link.title}
        href={link.url}
        target={link.targetBlank && '_blank'}
        rel={link.targetBlank && 'noopener noreferrer'}
        key={`${link.id}-desktop`}
        isActivePage={activePage === link.slug}
        aria-current={activePage === link.slug ? 'page' : null}
        {...otherProps}
      >
        <NoWrapInline space={2} verticalAlign="center">
          {Icon ? <Icon size="md" /> : ''}
          <LineHeightOne>{link.title}</LineHeightOne>
        </NoWrapInline>
      </NavLink>
    </NavItem>
  );
};

NavLinkItem.displayName = 'NavLinkItem';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, jsx-a11y/anchor-has-content
export const HeaderLink = styled(({component: Component, hiddenLink, icon, ...rest}) => (Component ? <Component data-testid="header-link" {...rest} /> : <a data-testid="header-link" {...rest} />))`
  display: ${({hiddenLink}) => (hiddenLink ? `none` : `flex`)};
  flex-direction: row;
  align-items: center;
  ${p(4)};
  border: none;
  border-left: 1px solid var(--smallHeaderColorBorder, ${colorLightest});
  background: transparent;
  color: var(--themeColorFgBrand, ${colorBrandBase});

  &:hover {
    cursor: pointer;
  }

  &:focus {
    ${standardFocusStyleDeclarations};
    outline-offset: -${focusOutlineOffset};
    z-index: 1;
  }
`;

export const SEARCH_URL = '/search';
HeaderLink.displayName = 'HeaderLink';

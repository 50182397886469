import {ProfileSystemIcon} from '@nib/icons';
import {getBaseUrl, getLogoUrl} from './utils';
import {HeaderConfigType, GetConfigParams} from './types';

const LOGO_URL = '/shareholders/home';

const PHONE_NUMBER = '1300 664 316';
const CORPORATE_PHONE_NUMBER = null;
const ABSOLUTE_BASEURL = 'https://www.nib.com.au';

// My Account variables:
const MY_ACCOUNT_ID = 'ga-nav-shareholders-my-account';
const MY_ACCOUNT_TITLE = 'My Account';
const MY_ACCOUNT_URL = 'https://www-au.computershare.com/Investor/?landing=y&issuerid=scaunhf&showinvestorcontact=n';

const getShareholdersConfig = (config: GetConfigParams): HeaderConfigType => {
  const baseUrl = getBaseUrl(config, ABSOLUTE_BASEURL);
  const logoUrl = getLogoUrl(config, baseUrl, LOGO_URL);
  return {
    id: 'header-shareholders',
    phoneNumber: PHONE_NUMBER,
    corporatePhoneNumber: CORPORATE_PHONE_NUMBER,
    logoUrl: logoUrl,
    ribbon: [
      {
        id: 'ga-secnav-shareholders-nib-retail',
        title: 'nib health funds',
        url: `${baseUrl}/`
      },
      {
        id: 'ga-secnav-shareholders-nib-foundation',
        title: 'nib foundation',
        url: 'https://www.nibfoundation.com.au/'
      },
      {
        id: 'ga-secnav-shareholders-contact-nib',
        title: 'Contact us',
        url: `${baseUrl}/shareholders/contact-us`
      }
    ],
    callToAction: {
      id: MY_ACCOUNT_ID,
      title: MY_ACCOUNT_TITLE,
      url: MY_ACCOUNT_URL
    },
    navLinks: [
      {
        id: 'ga-nav-shareholders-company-profile',
        title: 'Company Profile',
        url: `${baseUrl}/shareholders/company-profile/about-us`,
        children: [
          {
            id: 'ga-nav-shareholders-about-us',
            title: 'About Us',
            url: `${baseUrl}/shareholders/company-profile/about-us`
          },
          {
            id: 'ga-nav-shareholders-company-history',
            title: 'Company History',
            url: `${baseUrl}/shareholders/company-profile/company-history`
          },
          {
            id: 'ga-nav-shareholders-board-of-directors',
            title: 'Board of Directors',
            url: `${baseUrl}/shareholders/company-profile/management`
          },
          {
            id: 'ga-nav-shareholders-senior-management',
            title: 'Senior Management',
            url: `${baseUrl}/shareholders/company-profile/management#senior-management`
          },
          {
            id: 'ga-nav-shareholders-corporate-governance',
            title: 'Corporate Governance',
            url: `${baseUrl}/shareholders/company-profile/corporate-governance`
          },
          {
            id: 'ga-nav-shareholders-sustainability',
            title: 'Sustainability',
            url: `${baseUrl}/shareholders/company-profile/sustainability`
          }
        ]
      },
      {
        id: 'ga-nav-shareholders-manage-your-shareholding',
        title: 'Manage Your Shareholding',
        url: `${baseUrl}/shareholders/manage-your-shareholding/update-your-shareholding`,
        children: [
          {
            id: 'ga-nav-shareholders-update-your-shareholding',
            title: 'Update Your Shareholding',
            url: `${baseUrl}/shareholders/manage-your-shareholding/update-your-shareholding`
          },
          {
            id: 'ga-nav-shareholders-dividend-information',
            title: 'Dividend Information',
            url: `${baseUrl}/shareholders/manage-your-shareholding/dividend-information`
          },
          {
            id: 'ga-nav-shareholders-agm',
            title: 'Annual General Meeting',
            url: `${baseUrl}/shareholders/agm`
          },
          {
            id: 'ga-nav-shareholders-buying-selling',
            title: 'Buying and Selling nib Shares',
            url: `${baseUrl}/shareholders/manage-your-shareholding/buying-selling-nib-shares`
          },
          {
            id: 'ga-nav-shareholders-calendar',
            title: 'Shareholder Calendar',
            url: `${baseUrl}/shareholders/calendar`
          },
          {
            id: 'ga-nav-shareholders-mys-contact-us',
            title: 'Contact Us',
            url: `${baseUrl}/shareholders/contact-us`
          }
        ]
      },
      {
        id: 'ga-nav-shareholders-financial-information',
        title: 'Financial Information',
        url: `${baseUrl}/shareholders/financial-information/share-price`,
        children: [
          {
            id: 'ga-nav-shareholders-share-price',
            title: 'Share Price',
            url: `${baseUrl}/shareholders/financial-information/share-price`
          },
          {
            id: 'ga-nav-shareholders-Financial Results',
            title: 'Financial Results',
            url: `${baseUrl}/shareholders/financial-results`
          },
          {
            id: 'ga-nav-shareholders-annual-reports',
            title: 'Annual Reports',
            url: `${baseUrl}/shareholders/annual-reports`
          },
          {
            id: 'ga-nav-shareholders-listing-documents',
            title: 'Listing Documents',
            url: `${baseUrl}/shareholders/financial-information/listing-documents`
          }
        ]
      },
      {
        id: 'ga-nav-shareholders-announcements',
        title: 'Announcements',
        url: `${baseUrl}/shareholders/announcements`,
        children: [
          {
            id: 'ga-nav-shareholders-asx-announcements',
            title: 'ASX Announcements',
            url: `${baseUrl}/shareholders/announcements/asx`
          },
          {
            id: 'ga-nav-shareholders-announcements-presentations',
            title: 'Presentations',
            url: `${baseUrl}/shareholders/announcements/presentations`
          },
          {
            id: 'ga-nav-shareholders-media-releases',
            title: 'Media Releases',
            url: `${baseUrl}/nib-news/media`
          }
        ]
      },
      {
        id: 'ga-nav-shareholders-help',
        title: 'Help',
        url: `${baseUrl}/shareholders/help/faqs`,
        children: [
          {
            id: 'ga-nav-shareholders-faqs',
            title: 'Frequently Asked Questions',
            url: `${baseUrl}/shareholders/help/faqs`
          },
          {
            id: 'ga-nav-shareholders-help-contact-us',
            title: 'Contact Us',
            url: `${baseUrl}/shareholders/contact-us`
          }
        ]
      }
    ],
    hideQuoteButton: true,
    smallHeaderConfig: {
      id: 'small-header-shareholders',
      logoUrl: logoUrl,
      phoneNumber: PHONE_NUMBER,
      corporatePhoneNumber: CORPORATE_PHONE_NUMBER,
      navLinks: [
        {
          id: MY_ACCOUNT_ID,
          label: MY_ACCOUNT_TITLE,
          title: MY_ACCOUNT_TITLE,
          icon: ProfileSystemIcon,
          url: MY_ACCOUNT_URL
        }
      ]
    }
  };
};

export default getShareholdersConfig;

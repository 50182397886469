import React from 'react';
import styled, {css} from 'styled-components';
import {buttonSmallStyles, buttonMediumStyles, buttonLargeStyles, token} from '@nib-components/theme';
import {IconProps} from '@nib/icons';
import {breakpoint, Column, Columns, map, py, px} from '@nib/layout';
import Loader from '@nib/loader';

const validBreakpointValues = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'] as const;
type breakpointValues = (typeof validBreakpointValues)[number];

export const validSizes = ['small', 'medium', 'large'] as const;
type sizeValues = (typeof validSizes)[number];

export const validIconPlacement = ['left', 'right', 'start', 'end'] as const;
type iconPlacementValues = (typeof validIconPlacement)[number];

type Nullable<T> = T | null;

type PartialRecord<K extends keyof never, T> = {
  [P in K]?: T;
};
type ResponsiveProp<T> = PartialRecord<breakpointValues, T>;
type ResponsiveOrStaticProp<T> = T | ResponsiveProp<T>;

export interface BaseButtonProps {
  // 'any' due to our use of GatsbyJS's Link component which doesn't fall under any defined PropType.
  component?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
  icon?: React.FC<IconProps>;
  iconPlacement?: iconPlacementValues;
  onClick?: () => void;
  ref?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
  selected?: boolean;
  disabled?: boolean;
  size?: ResponsiveOrStaticProp<Nullable<sizeValues>>;
  small?: boolean;
  large?: boolean;
  fullWidth?: ResponsiveOrStaticProp<Nullable<boolean>>;
  isLoading?: boolean;
  href?: string;
  id?: string;
  className?: string;
  isCompact?: boolean;
  children: React.ReactNode;
  [key: string]: unknown; // other props
}

interface FullWidthStylingProps {
  readonly fullWidth?: ResponsiveOrStaticProp<Nullable<boolean>>;
}

const smallButtonStyles = css`
  font-family: ${token('button.typography.small.fontFamily')};
  font-size: ${token('button.typography.small.fontSize')};
  font-weight: ${token('button.typography.small.fontWeight')};
  line-height: ${token('button.typography.small.lineHeight')};
  letter-spacing: ${token('button.typography.small.letterSpacing')};
  text-transform: ${token('button.typography.small.textTransform')};
`;

const mediumButtonStyles = css`
  font-family: ${token('button.typography.fontFamily')};
  font-size: ${token('button.typography.fontSize')};
  font-weight: ${token('button.typography.fontWeight')};
  line-height: ${token('button.typography.lineHeight')};
  letter-spacing: ${token('button.typography.letterSpacing')};
  text-transform: ${token('button.typography.textTransform')};
`;

const largeButtonStyles = css`
  font-family: ${token('button.typography.large.fontFamily')};
  font-size: ${token('button.typography.large.fontSize')};
  font-weight: ${token('button.typography.large.fontWeight')};
  line-height: ${token('button.typography.large.lineHeight')};
  letter-spacing: ${token('button.typography.large.letterSpacing')};
  text-transform: ${token('button.typography.large.textTransform')};
`;

export const fullWidthStyling = css<FullWidthStylingProps>`
  ${props =>
    props.fullWidth !== undefined
      ? map(props.fullWidth, (val: boolean) => {
          if (val) {
            return `
          width: 100%;
        `;
          } else {
            return `width: auto;`;
          }
        })
      : ''}
`;

interface SizeStylingProps {
  readonly size?: ResponsiveOrStaticProp<Nullable<sizeValues>>;
  readonly small?: boolean;
  readonly large?: boolean;
}

export const sizeStyling = css<SizeStylingProps>`
  /* Reduce padding below 240px screen */
  ${breakpoint('xs', 'mini')`
    ${py(2)};
    ${px(3)};
  `}

  ${props =>
    props.small &&
    css`
      ${buttonSmallStyles(props)};
      ${smallButtonStyles};
    `}

  ${props =>
    props.large &&
    css`
      ${buttonLargeStyles(props)};
      ${largeButtonStyles};
    `}

  ${props =>
    props.size &&
    !props.small &&
    !props.large &&
    map(props.size, (val: sizeValues) => {
      if (val === 'small') {
        return css`
          ${buttonSmallStyles(props)};
          ${smallButtonStyles};
        `;
      } else if (val === 'large') {
        return css`
          ${buttonLargeStyles(props)};

          ${largeButtonStyles};
        `;
      } else {
        return css`
          ${buttonMediumStyles(props)};

          ${mediumButtonStyles};
        `;
      }
    })}
`;

export const StyledSpan = styled.span`
  word-break: break-word;
`;

const StyledLineHeight = styled.div`
  line-height: 0;
`;

const ShrinkColumn = styled(Column)`
  flex-shrink: 1;
`;

const BaseButton: React.FC<BaseButtonProps> = React.forwardRef<HTMLButtonElement, BaseButtonProps>((props, ref) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    component = 'button',
    icon: Icon,
    iconPlacement = 'end' as const,
    size = 'medium' as const,
    small = false,
    large = false,
    fullWidth = false,
    isCompact,
    isLoading = false,
    children,
    ...otherProps
  } = props;

  const Component: React.FC<React.PropsWithChildren> = props.href ? 'a' : component;

  return (
    <Component ref={ref} {...otherProps}>
      <Columns space={2} verticalAlign="center" align="center" wrap>
        {Icon && !isLoading && (iconPlacement === 'left' || iconPlacement === 'start') && (
          <Column width="content">
            <StyledLineHeight>
              <Icon size="xs" fill="currentColor" />
            </StyledLineHeight>
          </Column>
        )}
        {isLoading && (iconPlacement === 'left' || iconPlacement === 'start') && (
          <Column width="content">
            <StyledLineHeight>
              <Loader size="xs" fill="currentColor" />
            </StyledLineHeight>
          </Column>
        )}
        <ShrinkColumn width="content">
          <StyledSpan>{children}</StyledSpan>
        </ShrinkColumn>
        {Icon && !isLoading && (iconPlacement === 'right' || iconPlacement === 'end') && (
          <Column width="content">
            <StyledLineHeight>
              <Icon size="xs" fill="currentColor" />
            </StyledLineHeight>
          </Column>
        )}
        {isLoading && (iconPlacement === 'right' || iconPlacement === 'end') && (
          <Column width="content">
            <StyledLineHeight>
              <Loader size="xs" fill="currentColor" />
            </StyledLineHeight>
          </Column>
        )}
      </Columns>
    </Component>
  );
});

export const buttonDefaultProps = {
  component: 'button',
  iconPlacement: 'right' as const,
  selected: false,
  disabled: false,
  fullWidth: false,
  isLoading: false,
  size: 'medium' as const,
  small: false,
  large: false
};

export default BaseButton;

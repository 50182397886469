import {css} from 'styled-components';
import {map} from '../../breakpoint';
import {ResponsiveOrStaticProp} from './../../utils';

export type OverflowType = ResponsiveOrStaticProp<string>;

export const overflow = () => css`
  ${({overflow}: {overflow: OverflowType}) =>
    map(
      overflow,
      (val: string) =>
        val !== undefined &&
        css`
          overflow: ${val};
        `
    )};
`;
